import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import api from '../../../services'
import { useFetch, useBreadcrumb } from '../../../hooks'
import { changeRequestSchema } from './crForm/schema'

const useCR = () => {
  const { cr_id } = useParams()
  const { setCR } = useBreadcrumb()
  const crId = cr_id === 'new' ? null : cr_id

  const { addToast } = useToasts()
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [rawCR, setRawCR] = React.useState()

  const url = crId ? `/change-request/${cr_id}` : null
  const { data, isFetching } = useFetch(url, 'Unable to fetch change logs')

  React.useEffect(() => {
    if (data) {
      setRawCR(data)
      setCR(data.cr_no)
    }
  }, [data, setCR])

  function handleValidationErrors(errors) {
    try {
      Object.values(errors).forEach(stringArray => {
        stringArray.forEach(string => addToast(string, { appearance: 'error' }))
      })
    } catch (error) {
      console.error(error)
    }
  }

  const submitUnpublished = async (values, type, projectId, orgId) => {
    setIsSubmitting(true)
    const formattedValues = {
      project_id: projectId,
      organisation_id: orgId,
      status: type === 'save' ? 'draft' : 'pending',

      // section 1
      cost: values.cost_constituent,
      files: values.files,
      change: values.change,
      reason: values.reason,
      info: values.additional_comments,

      // section 2
      short_description: values.short_description,
      implications: values.implications ? 1 : 0,
      extension: values.extension,
      matter: values.matter,
      comments: values.comments,

      // section 3
      change_origin: values.change_origin,
      cost_breakdown: values.cost_breakdown
        ? values.cost_breakdown.map(c => ({
            id: c.id,
            category: c.cost_category,
            description: c.description,
            quantity: c.quantity,
            unit: c.unit,
            rate: c.rate,
            total: c.quantity * c.rate,
          }))
        : null,
    }
    if (values.costs_approved) {
      formattedValues.costs_approved = 1
    }

    const res = await api.saveCR(formattedValues, crId)
    setIsSubmitting(false)

    if (res.errors) handleValidationErrors(res.errors)
    else {
      if (!crId) {
        return navigate(`/projects/${projectId}/changes`)
      }
      setRawCR(res.data.data)
      if (type === 'save') {
        if (crId) addToast('Updated CR')
        else addToast('Created CR')
      } else {
        addToast('CR sent to client')
      }
    }
  }

  const deleteFile = async id => {
    const res = await api.deleteFile(id, 'Unable to delete file')
    if (res.error) res.errors.forEach(e => addToast(e, { appearance: 'error' }))
    else setRawCR(cr => ({ ...cr, files: cr.files.filter(f => f.id !== id) }))
  }

  const createNote = async (title, description) => {
    const res = await api.crNoteCreate(crId, title, description)
    if (res.error) {
      res.errors.forEach(e => addToast(e, { appearance: 'error' }))
      return false
    } else setRawCR(cr => ({ ...cr, events: [res.data, ...cr.events] }))
    return true
  }

  const saveCosts = async costs => {
    setIsSubmitting(true)
    const updated_costs = costs.map(c => {
      const cost = {
        category: c.cost_category,
        description: c.description,
        quantity: c.quantity,
        unit: c.unit,
        rate: c.rate,
        total: c.quantity * c.rate,
      }
      if (c.id) cost.id = c.id
      return cost
    })
    const res = await api.updateCosts(crId, updated_costs)
    setIsSubmitting(false)

    if (res.error) {
      res.errors.forEach(e => addToast(e, { appearance: 'error' }))
    } else {
      setRawCR(res.data)
    }
  }

  const updatePublishedCR = async values => {
    setIsSubmitting(true)

    // update cr
    const res = await api.updatePublishedCR(crId, values)
    setIsSubmitting(false)
    if (res.errors) {
      if (res.errors) handleValidationErrors(res.errors)
      return false
    } else {
      setRawCR(res.data)
      return true
    }
  }

  const deleteCR = async () => {
    setIsSubmitting(true)
    const res = await api.deleteCR(rawCR.id)
    setIsSubmitting(false)

    if (res.error) {
      res.errors.forEach(e => addToast(e, { appearance: 'error' }))
      return false
    } else {
      navigate(`/projects/${rawCR.project_id}/changes`)
      addToast('Successfully deleted')
    }
  }

  const approveRejectCR = async ({ approve, ...values }) => {
    const type = approve ? 'approve' : 'reject'
    const res = await api.clientCR(values, cr.id, type)
    if (res.error)
      res.errors.forEach(e =>
        addToast(e, { appearance: 'error', autoDismiss: true }),
      )
    else {
      setRawCR(res.data)
      if (approve) {
        addToast(`Change request approved`)
      } else {
        addToast(`Change request rejected`)
      }
      document.body.classList.remove('scroll-lock')
    }
  }

  const resendCrToClient = async id => {
    const res = await api.resendCrToClient(id)
    if (res.error)
      res.errors.forEach(e =>
        addToast(e, { appearance: 'error', autoDismiss: true }),
      )
    else addToast(`Resent for client approval`)
  }

  const formattedCR = rawCR
    ? {
        id: rawCR.id,
        status: rawCR.status,
        date_raised: rawCR.date_raised,
        cr_no: rawCR.cr_no,
        currency: rawCR.currency,
        costs_approved: !!rawCR.costs_approved,
        // section 1
        cost_constituent: rawCR.cost_constituent,
        short_description: rawCR.short_description,
        uploadedFiles: rawCR.files
          ? rawCR.files.map(f => ({ ...f, name: f.filename }))
          : [],
        files: [],
        change: rawCR.proposed_change,
        reason: rawCR.reason_for_change,
        additional_comments: rawCR.additional_information || '',

        // section 2
        implications: rawCR.no_programme_implications,
        extension: rawCR.anticipated_extension || '',
        matter: rawCR.anticipated_relevant_matter_loss_expense || '',
        comments: rawCR.additional_comments || '',
        client_decision_date: rawCR.client_decision_date,
        gc_claimed_cost: rawCR.gc_claimed_cost || '',
        date_gc_claimed_cost: rawCR.date_gc_claimed_cost || '',
        budget_cost: rawCR.budget_cost || '',
        assessed_cost: rawCR.assessed_cost || '',
        reported_cost_values: rawCR.reported_cost_option,

        // section 3
        change_origin: rawCR.change_origin,
        cost_breakdown: rawCR.costs
          ? rawCR.costs.map(c => ({
              id: c.id,
              cost_category: c.cost_category,
              description: c.description,
              quantity: c.quantity,
              unit: c.unit,
              rate: c.rate,
              total: c.total,
            }))
          : [],
        assessed_costs: rawCR.assessed_costs
          ? rawCR.assessed_costs.map(c => ({
              id: c.id,
              cost_category: c.cost_category,
              description: c.description,
              quantity: c.quantity,
              unit: c.unit,
              rate: c.rate,
              total: c.total,
            }))
          : [],
        events: rawCR.events ? rawCR.events : [],
        rfi: rawCR.rfi || '',
        instruction_number: rawCR.instruction_number || '',
        instruction_date: rawCR.instruction_date || '',
        last_updated_by: rawCR.last_updated_by,
        originator_ref: rawCR.originator_ref || '',
      }
    : null

  const cr = crId
    ? formattedCR
    : { status: 'draft', ...changeRequestSchema.default() }

  return {
    cr,
    isFetching,
    submitUnpublished,
    isSubmitting,
    deleteFile,
    createNote,
    saveCosts,
    updatePublishedCR,
    deleteCR,
    approveRejectCR,
    resendCrToClient,
  }
}

export default useCR
