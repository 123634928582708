import React from 'react'
import PropTypes from 'prop-types'

import { ViewHeader } from '../../../ui'
import CRForm from './form'
import {
  TIME_OPTIONS,
  COST_CAT_OPTIONS,
  COST_CONSTITUENT_OPTIONS,
  CONFIRM_OPTIONS,
  CHANGE_ORIGIN_OPTIONS,
} from '../../../../constants'

// Form to create new CRs or to edit draft CRs

const CreateEditCR = ({
  cr,
  submit,
  isSubmitting,
  deleteFile,
  uploadedFiles,
  currency,
  deleteCR,
}) => {
  const options = {
    timeOptions: TIME_OPTIONS(),
    costCategories: COST_CAT_OPTIONS,
    costConstituents: COST_CONSTITUENT_OPTIONS,
    lossExpense: CONFIRM_OPTIONS,
    changeOrigins: CHANGE_ORIGIN_OPTIONS,
  }

  return (
    <>
      <ViewHeader>
        <h1>{cr.id ? 'Update Change Request' : 'New Change Request'}</h1>
      </ViewHeader>
      <CRForm
        values={cr}
        submit={submit}
        uploadedFiles={uploadedFiles}
        deleteFile={deleteFile}
        {...options}
        isSubmitting={isSubmitting}
        currency={currency}
        deleteCR={deleteCR}
      />
    </>
  )
}

CreateEditCR.defaultProps = {
  cr: null,
  uploadedFiles: [],
}

CreateEditCR.propTypes = {
  submit: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  deleteCR: PropTypes.func.isRequired,
  cr: PropTypes.any,
  isSubmitting: PropTypes.bool.isRequired,
  uploadedFiles: PropTypes.array,
  currency: PropTypes.string.isRequired,
}

export default CreateEditCR
