import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Label, Error, InputWrapper } from '../'

export const Input = styled.input`
  font-size: 14px;
  height: 32px;
  width: 100%;
  padding: 0;
  border: none;
  border-bottom: 1px solid ${p => p.theme.color.border};
  appearance: none;
`

export const InputWithLabel = ({
  className,
  label,
  name,
  capitalise,
  errorText,
  ...props
}) => {
  const [value, setValue] = useState()

  const onInput = e => {
    const { value } = e.target
    e.target.value = capitalise ? value.toUpperCase() : value
  }

  return (
    <div className={className}>
      <InputWrapper
        hasError={!!errorText}
        className={`${
          value || props.value || props.value === 0 || props.type === 'date'
            ? 'isFilled'
            : ''
        } ${props.readonly ? 'isReadonly' : ''}`}
      >
        <Label htmlFor={name}>{label}</Label>
        <Input
          spellCheck
          value={value}
          onChange={e => setValue(e.target.value)}
          onInput={onInput}
          {...props}
          id={name}
          disabled={props.readonly}
        />
      </InputWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

InputWithLabel.defaultProps = {
  type: 'text',
  errorText: null,
  readonly: false,
  capitalise: false,
  className: '',
  value: '',
}

InputWithLabel.propTypes = {
  type: PropTypes.oneOf(['text', 'date', 'number', 'password', 'email', 'tel']),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  readonly: PropTypes.bool,
  capitalise: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.any,
}
