import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

import { Card, Button, Modal } from '../../../ui'
import { useAuth } from '../../../../hooks'
import { Form, FormikText } from '../../../form'
import { getShortDate } from '../../../../utilities/dates'

const CategoryBlock = styled.p`
  display: block;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  background-color: ${p =>
    p.color === 'construction'
      ? '#0086CB'
      : p.color === 'fees'
      ? '#13800C'
      : '#FF073A'};
`

const Flex = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  p {
    margin: 0 0.5rem;
  }
`

const SCard = styled(Card)`
  padding: 1rem;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    &:nth-child(2) {
      margin-left: 1rem;
    }
  }
`
const buttonStyle = {
  margin: '1rem',
  flex: 1,
}
const AcceptReject = ({ cr, approveRejectCR }) => {
  const { user } = useAuth()
  const [isOpen, setIsOpen] = React.useState(false)

  const canAcceptReject =
    user.user_group === 'client' && cr.status === 'pending'

  if (!canAcceptReject) return null
  return (
    <SCard>
      <Formik
        validationSchema={Yup.object().shape({
          message: Yup.string(),
        })}
        initialValues={{
          approve: true,
          message: '',
        }}
        onSubmit={approveRejectCR}
      >
        {({ values, submitForm, setFieldValue }) => (
          <>
            <Modal
              isActive={isOpen}
              onClose={() => setIsOpen(false)}
              title={`Change request #${cr.id}`}
            >
              <Flex>
                <p>Date received: </p>
                {cr?.date_raised ? <p>{getShortDate(cr.date_raised)}</p> : null}
              </Flex>
              <Flex>
                <p>Estimated cost: </p>
                {cr?.budget_cost ? (
                  <p>
                    {cr.currency === 'eur' ? '€' : '£'}
                    {cr.budget_cost}
                  </p>
                ) : null}
              </Flex>
              <Flex>
                <p>Cost category: </p>
                {cr?.cost_constituent ? (
                  <>
                    <CategoryBlock color={cr.cost_constituent} />
                    <span>{cr.cost_constituent}</span>
                  </>
                ) : null}
              </Flex>
              <Flex>
                <p>Proposed change: </p>
                <p>{cr.change}</p>
              </Flex>
              <Flex>
                <p>Reason for change: </p>
                <p>{cr.reason}</p>
              </Flex>
              <Flex>
                <p>Anticipated delay: </p>
                <p>{cr.extension}</p>
              </Flex>
              <Flex>
                <p>Message: </p>
                <p>{values.message}</p>
              </Flex>

              <div
                style={{
                  border: '1px solid black',
                  borderRadius: '2px',
                  padding: '1rem 1rem 0 1rem',
                }}
              >
                <h3>
                  Please confirm that you want to{' '}
                  {values.approve ? 'approve' : 'reject'} this request
                </h3>
                <Flex
                  style={{
                    justifyContent: 'space-between',
                    margin: '0 -1rem',
                    flexWrap: 'wrap',
                  }}
                >
                  <Button
                    type="button"
                    onClick={() => {
                      setFieldValue('approve', null)
                      setIsOpen(false)
                    }}
                    style={{
                      ...buttonStyle,
                      border: '1px solid black',
                      background: 'white',
                      color: 'black',
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="button"
                    onClick={() => {
                      submitForm()
                    }}
                    style={buttonStyle}
                  >
                    {values.approve ? 'Approve' : 'Reject'}
                  </Button>
                </Flex>
              </div>
            </Modal>

            <Form>
              <FormikText
                type="text"
                name="message"
                label="Your comments"
                rows={4}
              />
              <ButtonWrapper>
                <Button
                  type="button"
                  small
                  onClick={() => {
                    setFieldValue('approve', false)
                    setIsOpen(true)
                  }}
                >
                  Reject
                </Button>
                <Button
                  type="button"
                  small
                  onClick={() => {
                    setFieldValue('approve', true)
                    setIsOpen(true)
                  }}
                >
                  Approve
                </Button>
              </ButtonWrapper>
            </Form>
          </>
        )}
      </Formik>

      {/* <Button small onClick={() => setIsOpen(true)} style={{ margin: 'auto' }}>
        Respond
      </Button> */}
    </SCard>
  )
}

AcceptReject.defaultProps = {
  cr: null,
}
AcceptReject.propTypes = {
  cr: PropTypes.object,
  approveRejectCR: PropTypes.func.isRequired,
}
export default AcceptReject
