export default name => {
  try {
    const names = name.toUpperCase().split(' ')
    if (names.length < 2) throw Error()
    const firstName = names[0]
    const lastName = names[names.length - 1]
    return firstName[0] + lastName[0]
  } catch (e) {
    return 'XX'
  }
}
