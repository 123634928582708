import React from 'react'
// import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useParams, useNavigate } from 'react-router-dom'

import { useAuth } from '../../hooks'
import { Button } from '../ui'
import { Form, FormikInput } from '../form'
import { Container, BoxWithLogo, Text } from './login-ui'

const SignupSchema = Yup.object().shape({
  company: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  name: Yup.string()
    .min(5, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Must be an email').required('Required'),
  password: Yup.string()
    .min(7, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  location: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  phone: Yup.string().min(9, 'Too Short!').max(18, 'Too Long!'),
})

const Signup = () => {
  const { signup } = useAuth()
  const { hash } = useParams()
  const navigate = useNavigate()

  const submit = async (values, actions) => {
    const success = await signup({ invitation_token: hash, ...values })
    if (success) navigate('/')
    actions.setSubmitting(false)
  }

  return (
    <Container>
      <BoxWithLogo>
        <Text>Account Setup</Text>
        <Formik
          initialValues={{
            company: '',
            name: '',
            email: '',
            password: '',
            confirm_password: '',
            location: '',
            phone: '',
          }}
          validationSchema={SignupSchema}
          onSubmit={submit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormikInput
                type="text"
                name="company"
                label="Company"
                placeholder="Enter company name"
              />
              <FormikInput
                type="text"
                name="name"
                label="Full Name"
                placeholder="Enter full name"
              />
              <FormikInput
                type="email"
                name="email"
                label="Email Address"
                placeholder="Enter email address"
              />
              <FormikInput
                type="password"
                name="password"
                label="Password"
                placeholder="Enter new password"
              />
              <FormikInput
                type="password"
                name="confirm_password"
                label="Confirm Password"
                placeholder="Enter new password"
              />
              <FormikInput
                type="text"
                name="location"
                label="Office location"
                placeholder="Enter office location"
              />
              <FormikInput
                type="tel"
                name="phone"
                label="Phone Number (Direct if possible)"
                placeholder="Enter phone number"
              />

              <Button fullwidth tall type="submit" disabled={isSubmitting}>
                Create Account
              </Button>
            </Form>
          )}
        </Formik>
      </BoxWithLogo>
    </Container>
  )
}

Signup.defaultProps = {}
Signup.propTypes = {}

export default Signup
