import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { LoadTransition } from '../../ui'
import useFetch from '../../../hooks/useFetch'
import ChangesByValue from '../../analytics/changesByValue'

export const ChangesByValueLoader = ({ id, currency, save }) => {
  const changesByValue = useFetch(
    `/project/${id}/changesByValue`,
    'Unable to fetch changes by value data',
  )
  const changesByValueData = changesByValue?.data?.data

  useEffect(() => {
    if (changesByValueData && currency)
      save({
        data: changesByValueData.map(d => ({
          id: d.id,
          cr: d.cr_no,
          desc: d.short_description,
          value: d.cost,
        })),
        currency,
      })
  }, [save, changesByValueData, currency])

  return (
    <LoadTransition isFetching={changesByValue.isFetching}>
      {changesByValue?.data?.data && changesByValue?.data?.data.length ? (
        <ChangesByValue
          currency={currency}
          data={changesByValue?.data?.data.map(d => ({
            id: d.id,
            cr: d.cr_no,
            desc: d.short_description,
            value: d.cost,
          }))}
        />
      ) : (
        <div>No data to display</div>
      )}
    </LoadTransition>
  )
}
ChangesByValueLoader.propTypes = {
  id: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
}
