import styled from 'styled-components'

const Button = styled.button.attrs(props => ({
  type: props.type ? props.type : 'button',
  width: props.fullwidth ? '100%' : 'fit-content',
  fontsize: props.small ? '12px' : '13px',
  lineheight: props.small ? '14px' : '16px',
  padding: props.icon
    ? '0 0.5rem'
    : props.small
    ? props.theme.layout.web
      ? '0.5rem 1.25rem'
      : '0.5rem 1rem'
    : props.theme.layout.web
    ? '0.75rem 1.5rem'
    : '0.75rem 1rem',
}))`
  align-items: center;
  appearance: none;
  background: ${p => (p.icon ? 'none' : p.theme.color.primary)};
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: ${p => p.fontsize};
  font-weight: 700;
  justify-content: center;
  line-height: 1rem;
  min-height: ${p => (p.small ? '36px' : '42px')};
  padding: ${p => p.padding};
  text-transform: uppercase;
  transition: background 200ms;
  width: ${p => p.width};

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: ${p => (p.icon ? '#eee' : '#222')};
  }

  &:disabled {
    filter: contrast(0.4);
    cursor: not-allowed;
  }
`

export { Button }
