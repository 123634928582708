import React from 'react'
import { usePaginatedFetch } from '../../../hooks'
import api from '../../../services'

const useNotifications = () => {
  const {
    data: allNots,
    refetch: refetchAll,
    fetchMore: fetchMoreAll,
  } = usePaginatedFetch('/notifications', 'Unable to fetch notifications')

  const {
    data: unreadNots,
    refetch: refetchUnread,
    fetchMore: fetchMoreUnread,
  } = usePaginatedFetch(
    '/notifications/unread',
    'Unable to fetch unread notifications',
  )

  React.useEffect(() => {
    const checkNotifications = setInterval(() => {
      refetchAll()
      refetchUnread()
    }, 60000)
    return () => clearInterval(checkNotifications)
  }, [refetchAll, refetchUnread])

  const markAllRead = () => {
    api.markNotificationsRead().then(() => {
      refetchAll()
      refetchUnread()
    })
  }

  const allNotifications = allNots ? allNots : []
  const unreadNotifications = unreadNots ? unreadNots : []

  return {
    allNotifications,
    fetchMoreAll,
    unreadNotifications,
    fetchMoreUnread,
    markAllRead,
  }
}

export default useNotifications
