import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'

import { Button } from '../ui'
import { Form, FormikInput } from '../form'
import { Container, BoxWithLogo, Text } from './login-ui'
import { useAuth } from '../../hooks'

const updatePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(7, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
})

const UpdatePassword = () => {
  const { updatePassword, checkResetToken } = useAuth()
  const { hash } = useParams()

  React.useEffect(() => {
    checkResetToken(hash)
  }, [hash, checkResetToken])

  const submitPassword = async ({ password, confirm_password }) => {
    updatePassword(hash, password, confirm_password)
  }

  return (
    <Container>
      <BoxWithLogo>
        <Text>Reset Password</Text>
        <Formik
          initialValues={{
            password: '',
            confirm_password: '',
          }}
          validationSchema={updatePasswordSchema}
          onSubmit={submitPassword}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormikInput
                type="password"
                name="password"
                label="New Password"
                placeholder="Enter new password"
              ></FormikInput>

              <FormikInput
                type="password"
                name="confirm_password"
                label="Re-enter New Password"
                placeholder="Confirm new password"
              />

              <Button fullwidth tall type="submit" disabled={isSubmitting}>
                Confirm
              </Button>
            </Form>
          )}
        </Formik>
      </BoxWithLogo>
    </Container>
  )
}

UpdatePassword.defaultProps = {
  hash: '',
}
UpdatePassword.propTypes = {
  hash: PropTypes.string,
}

export default UpdatePassword
