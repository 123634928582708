import styled from 'styled-components'

import { ReactComponent as editIcon } from '../../../../../assets/icons/edit.svg'

export const Cell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const EditIcon = styled(editIcon)`
  height: 19px;
  width: 19px;
`

export const File = styled.a`
  display: inline-block;
  color: black;
  text-decoration: underline;
  margin: 0.5rem 0;
  width: 100%;
`

export const Input = styled.input`
  margin-right: 1rem;
  width: 100%;
  padding: 0 0.5rem;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  height: 36px;
`
