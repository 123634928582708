import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useNavigate } from 'react-router-dom'

import Menu from './menu'
import Notifications from './notifications'
import { ReactComponent as logo } from '../../assets/logos/stature-mobile.svg'
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg'
import { ReactComponent as BellIcon } from '../../assets/icons/bell.svg'
import { useAuth, useBreadcrumb } from '../../hooks'
import { getInitials } from '../../utilities'

//#region styles
const Header = styled.header`
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
  padding: 0 1rem;

  ${p => p.theme.layout.web} {
    padding: 0 2rem;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const Bell = styled(BellIcon)`
  height: 25px;
  #notification-mask circle {
    fill: ${p => (p.$showIndicator ? 'black' : 'white')};
  }
  #notification-indicator {
    fill: ${p => (p.$showIndicator ? p.theme.color.accent : 'transparent')};
  }
`

const Logo = styled(logo)`
  height: 50px;
  margin-right: 1rem;
  ${p => p.theme.layout.web} {
    display: none;
  }
`

const Path = styled.h4`
  display: none;
  ${p => p.theme.layout.web} {
    display: block;
    color: #000000;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.5px;
    margin-top: 0.25rem;
    text-transform: uppercase;

    a {
      color: inherit;
      text-decoration: none;

      &.is-active {
        font-weight: 600;
      }
    }
  }
`

const AccountInfo = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const Arrow = styled(ArrowIcon)`
  width: calc(10px + 1rem);
  height: 100%;
  left: 100%;
  padding: 0 0.5rem;
  transform: rotate(${p => (p.$isActive ? '180deg' : '0')});
  transition: transform 300ms ease-in-out;
`

const Hover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background 150ms ease-in-out;
  height: 38px;
  width: 38px;
`

const Button = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: 1rem;
  cursor: pointer;
  &:focus-within,
  &:hover {
    ${Hover} {
      background: lightgrey;
    }
  }
`

const Initials = styled.span`
  color: #000000;
  font-size: 14px;
  letter-spacing: 0.5px;
`

const MenuAction = styled.button`
  color: #000000;
  font-size: 15px;
  letter-spacing: 0;
  padding: 0.5rem 1rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: left;
  border: none;
  cursor: pointer;
  background: none;
`

const NotificationMenu = styled(Menu)`
  padding: 0;
  max-width: 320px;
  width: 100%;
  right: 0;
  overflow: hidden;

  ${p => p.theme.layout.web} {
    top: 0;
    max-width: 380px;
    min-height: 100vh;
    border-top: 0;
  }
`
//#endregion

const HeaderContainer = props => {
  const { logout, user } = useAuth()
  const { path, loc } = useBreadcrumb([])
  const navigate = useNavigate()

  const [notificationMenuIsOpen, setNotificationMenuIsOpen] = useState(false)
  const [accountMenuIsOpen, setAccountMenuIsOpen] = useState(false)

  React.useEffect(() => {
    setNotificationMenuIsOpen(false)
    setAccountMenuIsOpen(false)
  }, [loc])

  const handleAccountMenu = () => {
    setAccountMenuIsOpen(state => !state)
  }

  return (
    <Header {...props}>
      <Flex>
        <Link to="/">
          <Logo />
        </Link>
        <Path>
          {path?.map((p, idx) => (
            <Link
              key={p.text}
              to={p.to}
              className={idx === path.length - 1 ? 'is-active' : ''}
            >
              {p.text}
              {idx !== path.length - 1 ? ' > ' : ''}
            </Link>
          ))}
        </Path>
      </Flex>
      <AccountInfo>
        <Notifications>
          {({ hasNotifications, notifications }) => (
            <NotificationMenu
              isActive={notificationMenuIsOpen}
              onClose={() => setNotificationMenuIsOpen(false)}
              button={
                <Button onClick={() => setNotificationMenuIsOpen(cur => !cur)}>
                  <Hover>
                    <Bell $showIndicator={hasNotifications} />
                  </Hover>
                </Button>
              }
            >
              {notifications}
            </NotificationMenu>
          )}
        </Notifications>
        <Menu
          isActive={accountMenuIsOpen}
          onClose={() => setAccountMenuIsOpen(false)}
          button={
            <Button onClick={handleAccountMenu} className="account-menu">
              <Hover>
                <Initials>{getInitials(user.name)}</Initials>
              </Hover>
              <Arrow $isActive={accountMenuIsOpen} />
            </Button>
          }
        >
          <MenuAction onClick={() => navigate('/account')}>
            Edit Account
          </MenuAction>
          <MenuAction onClick={logout}>Logout</MenuAction>
        </Menu>
      </AccountInfo>
    </Header>
  )
}

export default HeaderContainer
