import axios from 'axios'

import { formatError } from './utils'

export const fetch = async (url, genericError, source) => {
  try {
    const res = await axios({
      method: 'get',
      url,
      cancelToken: source?.token,
    })

    if (res.status === 200) return { error: false, data: res.data }
    else throw Error
  } catch (e) {
    if (axios.isCancel(e)) return { cancelled: true }
    else return formatError(e, genericError)
  }
}

export const deleteFile = async (id, genericError) => {
  try {
    const res = await axios({
      method: 'post',
      url: `file/${id}/delete`,
    })

    if (res.status === 200) return { error: false, data: res.data }
    else throw Error
  } catch (e) {
    if (axios.isCancel(e)) return { cancelled: true }
    else return formatError(e, genericError)
  }
}
