import React from 'react'
import styled from 'styled-components'
import { Address } from '../../form/address'
import { FormikInput } from '../../form'

const Header = styled.h2`
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 2rem;
`

const Grid = styled.div`
  align-items: end;
  display: grid;
  grid-gap: 0 2rem;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-template-rows: repeat(auto-fit);
  margin: 1rem 0;
`

export const ClientDetails = () => {
  return (
    <>
      <Header>2A - Client Details</Header>

      <Grid>
        <FormikInput
          type="text"
          name="client.details.name"
          label="Client Name"
        />
      </Grid>

      <Address title="Client Address" namespace="client" />
    </>
  )
}
