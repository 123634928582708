import React from 'react'
import PropTypes from 'prop-types'

import useCR from './useCR'
import CrForm from './crForm'
import CrView from './crView'
import { Loader } from '../../ui'

const Change = ({ projectId, orgId, currency }) => {
  const {
    cr,
    isFetching,
    submitUnpublished,
    isSubmitting,
    deleteFile,
    createNote,
    saveCosts,
    updatePublishedCR,
    deleteCR,
    approveRejectCR,
    resendCrToClient,
  } = useCR()

  const handleSubmit = type => values => {
    submitUnpublished(values, type, projectId, orgId)
  }

  return (
    <Loader isFetching={isFetching}>
      {cr &&
        (cr.status === 'draft' ? (
          <CrForm
            submit={handleSubmit}
            cr={cr}
            isSubmitting={isSubmitting}
            deleteFile={deleteFile}
            uploadedFiles={cr.uploadedFiles}
            currency={currency}
            deleteCR={deleteCR}
          />
        ) : ['formalised', 'pending', 'approved', 'rejected'].includes(
            cr.status,
          ) ? (
          <CrView
            cr={cr}
            createNote={createNote}
            saveCosts={saveCosts}
            isSubmitting={isSubmitting}
            uploadedFiles={cr.uploadedFiles}
            updatePublishedCR={updatePublishedCR}
            deleteCR={deleteCR}
            approveRejectCR={approveRejectCR}
            resendCrToClient={resendCrToClient}
            currency={currency}
          />
        ) : null)}
    </Loader>
  )
}

Change.propTypes = {
  projectId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
}
export default Change
