import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Button } from '../ui'
import { Form, FormikInput } from '../form'
import { Container, BoxWithLogo, Text, StyledLink } from './login-ui'
import { useAuth } from '../../hooks'

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Must be an email').required('Required'),
})

const ForgotPassword = () => {
  const { sendResetEmail } = useAuth()
  const submit = async ({ email }, actions) => {
    sendResetEmail(email)
    actions.setSubmitting(false)
  }

  return (
    <Container>
      <BoxWithLogo>
        <Text>Reset Your Password</Text>

        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={forgotPasswordSchema}
          onSubmit={submit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormikInput
                type="email"
                name="email"
                label="Email Address"
                placeholder="Enter your email address"
              />

              <Button fullwidth tall type="submit" disabled={isSubmitting}>
                Send Password Reset
              </Button>
            </Form>
          )}
        </Formik>
        <StyledLink to="/">Cancel</StyledLink>
      </BoxWithLogo>
    </Container>
  )
}

export default ForgotPassword
