import axios from 'axios'

import { formatError } from './utils'

export const markNotificationsRead = async () => {
  try {
    const res = await axios.post('notifications/read')
    if (res.status === 200) return { error: false, data: res.data }
    else throw Error
  } catch (e) {
    if (axios.isCancel(e)) return { cancelled: true }
    else return formatError(e, 'Change request could not be saved')
  }
}
