import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import { Table, Td, Button, Modal } from '../../ui'
import { ReactComponent as Close } from '../../../assets/icons/cross.svg'
import { Form, FormikCombo, FormikInput, Search } from '../../form'
import useCompanies from '../../../hooks/useCompanies'

const Header = styled.h2`
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 2rem;
`

const Info = styled.h5`
  margin-bottom: 0.5rem;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 0 2rem;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-template-rows: repeat(auto-fit);

  h3 {
    grid-column: 1 / -1;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  }
`

const ScrollableContainer = styled.div`
  overflow: scroll;
`

const ButtonRemove = styled(Button)`
  background: #f0f0f0;
  border-radius: 100%;
  flex: unset !important;
  width: 36px !important;
  margin-left: auto;
`

const CLIENT_HEADERS = ['Name', 'Company', 'Role Title', 'Email', 'Phone', '']
const ERROR_TOAST = { appearance: 'error' }

const formSchema = Yup.object().shape({
  name: Yup.string().default('').required('Required'),
  company: Yup.string().default('').required('Required'),
  email: Yup.string()
    .default('')
    .email('Please enter a valid email address')
    .required('Required'),
  role_title: Yup.string().default(''),
  phone: Yup.string().default(''),
})

export const ClientContacts = ({
  newClients,
  existingClients,
  setField,
  projectId,
}) => {
  const [showModal, setShowModal] = React.useState(false)
  const { companies, addCompany } = useCompanies()
  const { addToast } = useToasts()

  const addClient = data => {
    setField('clients', [...newClients, data])
    setShowModal(false)
  }

  const createNewClient = async data => {
    const isNewEmailDuplicate = newClients.find(
      member => member.email.trim() === data.email.trim(),
    )
    if (isNewEmailDuplicate)
      return addToast(
        'You have already created a new client with that email address',
        ERROR_TOAST,
      )

    const res = await axios.get('user/search-email', {
      params: { email: data.email },
    })
    if (res.status !== 200)
      return addToast('There was an error adding user', ERROR_TOAST)

    const emailExists = res.data?.email_exists
    if (emailExists)
      return addToast(
        'A user with that email address already exists',
        ERROR_TOAST,
      )

    const { company, ...user } = data
    // company.value is either an id or user input depending on company.__isNew__
    let company_id
    const isNewCompany = company.__isNew__
    if (isNewCompany) {
      const res = await addCompany({ name: company.value })
      if (res.error) return false
      company_id = res.data.id
    } else company_id = company.value

    addClient({ ...user, company_id, company: company.label })
  }

  const removeRow = async (id, existing) => {
    if (!existing) {
      // just remove from formik data
      const idx = id
      setField('clients', [
        ...newClients.slice(0, idx),
        ...newClients.slice(idx + 1),
      ])
    } else {
      const url = `/project/${projectId}/clients/${id}`
      const res = await axios.delete(url)
      if (res.status === 200)
        setField(
          'existingClients',
          existingClients.filter(user => user.id !== id),
        )
    }
    addToast('User removed from project')
  }

  return (
    <>
      <Header>2B - Client Contacts</Header>
      <Info>Remember to save the project after adding new users</Info>

      <Grid style={{ gridGap: '0.5rem 2rem', marginBottom: '1.5rem' }}>
        <Search placeholder="Search Contacts" selectedResult={addClient} />

        <Button small type="button" onClick={() => setShowModal(true)}>
          Add New Contact
        </Button>
      </Grid>

      <Modal
        isActive={showModal}
        onClose={() => setShowModal(false)}
        title="New Client Contact"
      >
        <Formik
          validationSchema={formSchema}
          onSubmit={createNewClient}
          initialValues={formSchema.default()}
        >
          <Form>
            <Grid>
              <FormikInput name="name" label="Name" />
              <FormikCombo
                name="company"
                label="Company"
                options={companies
                  .map(company => ({ value: company.id, label: company.name }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
              />
              <FormikInput name="role_title" label="Role/Title" />
              <FormikInput name="email" label="Email" />
              <FormikInput name="phone" label="Phone" />
            </Grid>
            <Button small type="submit" style={{ marginLeft: 'auto' }}>
              Add Contact
            </Button>
          </Form>
        </Formik>
      </Modal>
      <ScrollableContainer>
        <Table headers={CLIENT_HEADERS}>
          {newClients.map((client, i) => (
            <tr key={i}>
              <Td data-header={CLIENT_HEADERS[0]}>{client.name}</Td>
              <Td data-header={CLIENT_HEADERS[1]}>{client.company}</Td>
              <Td data-header={CLIENT_HEADERS[2]}>{client.role_title || ''}</Td>
              <Td $email data-header={CLIENT_HEADERS[3]}>
                {client.email}
              </Td>
              <Td data-header={CLIENT_HEADERS[4]}>{client.phone || ''}</Td>
              <Td data-header={CLIENT_HEADERS[5]}>
                <ButtonRemove small icon onClick={() => removeRow(i)}>
                  <Close height="10px" />
                </ButtonRemove>
              </Td>
            </tr>
          ))}
          {existingClients.map(client => (
            <tr key={client.id}>
              <Td data-header={CLIENT_HEADERS[0]}>{client.name}</Td>
              <Td data-header={CLIENT_HEADERS[1]}>{client.company}</Td>
              <Td data-header={CLIENT_HEADERS[2]}>{client.role_title || ''}</Td>
              <Td $email data-header={CLIENT_HEADERS[3]}>
                {client.email}
              </Td>
              <Td data-header={CLIENT_HEADERS[4]}>{client.phone || ''}</Td>
              <Td data-header={CLIENT_HEADERS[5]}>
                <ButtonRemove
                  small
                  icon
                  onClick={() => removeRow(client.id, true)}
                >
                  <Close height="10px" />
                </ButtonRemove>
              </Td>
            </tr>
          ))}
        </Table>
      </ScrollableContainer>
    </>
  )
}

ClientContacts.defaultProps = {
  newClients: [],
  existingClients: [],
  projectId: null,
}

ClientContacts.propTypes = {
  newClients: PropTypes.array,
  existingClients: PropTypes.array,
  setField: PropTypes.func.isRequired,
  projectId: PropTypes.any,
}
