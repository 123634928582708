import React from 'react'
import PropTypes from 'prop-types'

import { LoadTransition } from '../../ui'
import useFetch from '../../../hooks/useFetch'
import CostIncreases from '../../analytics/costIncreases'

export const CostIncreasesLoader = ({ id, save }) => {
  const costIncreases = useFetch(
    `/project/${id}/changesByCostConstituent`,
    'Unable to fetch changes by cost constituent data',
  )

  return (
    <LoadTransition isFetching={costIncreases.isFetching}>
      {costIncreases.data && costIncreases.data.length ? (
        <CostIncreases
          onChartIsReady={save('costIncreases')}
          data={costIncreases.data.map(d => ({
            label: d.cost_constituent,
            change: d.percentage * 100,
          }))}
        />
      ) : (
        <div>No data to display</div>
      )}
    </LoadTransition>
  )
}
CostIncreasesLoader.propTypes = {
  id: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
}
