import { useFetch } from '../../../hooks'

const formatValues = value => {
  if (parseInt(value)) {
    return parseInt(value).toLocaleString('en-UK', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
  return value
}

const useGetProjects = id => {
  const { data, isFetching } = useFetch(
    `/project/${id}`,
    'Unable to fetch projects',
  )

  const initialValues = data?.title
    ? {
        id,
        start_date: data.start_date,
        end_date: data.end_date,
        anticipated_delays: data.anticipated_delays,

        area: {
          gross_internal: data.area.gross_internal || '',
          net_internal: data.area.net_internal || '',
          gross_external: data.area.gross_external || '',
          perimeters: data.area.perimeters || '',
          typical_floor: data.area.typical_floor || '',
        },
        budget: {
          total: data.client_approved_budget || '',
          rate: data.client_approved_budget_rate || '',
        },
        change_request: {
          budget: {
            time: data.change_request?.budget?.time || '',
          },
          assessment: {
            time: data.change_request?.assessment?.time || '',
          },
          claimed: {
            time: data.change_request?.claimed?.time || '',
          },
        },
        client: {
          address_1: data.client.address_1,
          address_2: data.client.address_2 || '',
          address_3: data.client.address_3 || '',
          address_towncity: data.client.address_towncity,
          address_postcode: data.client.address_postcode,
          address_region: data.client.address_region,
          address_country: data.client.address_country,
          details: {
            name: data.client.details?.name || '',
          },
        },
        contract: {
          type: data.contract_type || '',
        },
        cost: Object.entries(data.cost).reduce((costs, [field, value]) => {
          if (field === 'starting') return costs
          return {
            ...costs,
            [field]: {
              total: formatValues(value.total) || '',
            },
          }
        }, {}),
        other_costs: {
          construction: data?.other_costs?.construction || [],
          project: data?.other_costs?.project || [],
          other: data?.other_costs?.other || [],
        },
        currency: {
          selected: data.currency || '',
          exchange_rate: data.exchange_rate || '',
        },
        dashboard: {
          title: data.title,
        },
        measurement: {
          unit: data.measurement.unit,
        },
        risk: {
          allowance: {
            rate: data.risk.allowance?.rate || '',
            total: data.risk.allowance.total || '',
          },
        },
        site: {
          address_1: data.site.address_1,
          address_2: data.site.address_2 || '',
          address_3: data.site.address_3 || '',
          address_towncity: data.site.address_towncity,
          address_postcode: data.site.address_postcode,
          address_region: data.site.address_region,
          address_country: data.site.address_country,
        },
        existingClients: data.project_clients || [],
        existingTeam: data.project_team || [],
      }
    : null

  return {
    data: initialValues,
    isFetching,
  }
}

export default useGetProjects
