import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
`

const ContentOverlay = styled(Overlay)`
  top: ${p => p.theme.layout.header_height};
  bottom: ${p => p.theme.layout.nav_height_mobile};
  background: rgba(250, 250, 250, 0.8);
  overflow: hidden;

  ${p => p.theme.layout.web} {
    padding: 0.5rem 1rem;
    bottom: 0;
    left: ${p => p.theme.layout.nav_width_web};
  }
`

const Menu = styled.div`
  position: fixed;
  top: 70px;
  right: 1rem;
  min-width: 200px;
  background: white;
  border-top: 6px solid black;
  padding: 0rem;
  box-shadow: -5px 5px 30px 0 rgba(0, 0, 0, 0.14);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 2rem);
  max-height: calc(100% - 1rem);
  overflow: scroll;
  ${p => p.theme.layout.web} {
    padding: 0.5rem 1rem;
  }
`

const BtnWrapper = styled.div`
  position: relative;
  &:after {
    content: '';
    opacity: ${p => (p.isActive ? 1 : 0)};
    transition: opacity 100ms ease-in-out;
    position: absolute;
    width: 0;
    height: 0;
    border-left: transparent solid 6px;
    border-right: transparent solid 6px;
    border-bottom: black solid 6px;
    left: 50%;
    bottom: -16px;
  }
`

const MENU_ROOT = document.getElementById('menu-root')
const BODY = document.querySelector('body')

const MenuItem = ({ isActive, onClose, button, children, className }) => {
  React.useEffect(() => {
    if (isActive) {
      BODY.classList.add('scroll-lock')
    } else {
      BODY.classList.remove('scroll-lock')
    }
  }, [isActive])

  return (
    <>
      <BtnWrapper isActive={isActive}>{button}</BtnWrapper>
      {ReactDOM.createPortal(
        <CSSTransition
          in={isActive}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <>
            <ContentOverlay />
            <Overlay onClick={onClose}></Overlay>
            <Menu className={`${className} header-menu`}>{children}</Menu>
          </>
        </CSSTransition>,
        MENU_ROOT,
      )}
    </>
  )
}

MenuItem.defaultProps = {
  isActive: false,
  className: '',
}

MenuItem.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  button: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}

export default MenuItem
