//
import React from 'react'
import PropTypes from 'prop-types'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  Legend,
  Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import styled from 'styled-components'

ChartJS.register(LinearScale, CategoryScale, BarElement, Legend, Tooltip)

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(100vw - 4rem);
  margin-bottom: 2rem;
  min-height: 300px;

  ${p => p.theme.layout.web} {
    max-width: calc(100% - 1px);
    min-height: 475px;
  }
`

const COLORS = [
  '255,192,0',
  '91,155,213',
  '68,114,196',
  '237,125,49',
  '255,153,255',
  '112,173,71',
  '255,0,0',
  '50,200,150',
  '180,50,180',
]
const ChangeOrigin = props => {
  const data = {
    labels: ['1'],
    datasets: [
      ...props.data.map((change, i) => ({
        type: 'bar',
        label: change.label,
        data: [change.value],
        backgroundColor: `rgba(${COLORS[i]},1)`,
      })),
    ],
  }

  return (
    <ChartWrapper>
      <Bar
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: 'y',
          animations: false,
          animation: {
            onComplete: ({ chart }) => props.onChartIsReady(chart),
          },
          scales: {
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              onClick: () => {},
            },
            tooltip: {
              intersect: false,
              mode: 'y',
              callbacks: {
                title: function () {
                  return ''
                },
                label: function (context) {
                  if (context.parsed.x === null) return null

                  return `${context.dataset.label}: ${new Intl.NumberFormat(
                    'en-GB',
                    {
                      style: 'currency',
                      currency: 'GBP',
                      maximumFractionDigits: 0,
                    },
                  ).format(context.parsed.x)}`
                },
              },
            },
          },
        }}
      />
    </ChartWrapper>
  )
}

ChangeOrigin.propTypes = {
  onChartIsReady: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default ChangeOrigin
