import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import { useToasts } from 'react-toast-notifications'
import { useQueryCache, useMutation, useQuery } from 'react-query'
import axios from 'axios'

import { LoadTransition } from '../../ui'
import useFetch from '../../../hooks/useFetch'
import ChangeOrigin from '../../analytics/changeOrigin'
import { AuthContext } from '../../../context/auth'

const PRIVACY_OPTIONS = [
  { value: true, label: 'Private' },
  { value: false, label: 'Public' },
]

export const ChangeOriginLoader = ({ id, save }) => {
  const { addToast } = useToasts()
  const { user } = React.useContext(AuthContext)
  const queryCache = useQueryCache()
  const { data, isFetching } = useQuery(
    ['project', id],
    async () => axios.get(`/project/${id}`),
    { enabled: !!id },
  )

  const [updateChangeOriginChartPrivacy] = useMutation(
    value => {
      return axios.post(`/project/${id}/setChangeOriginPrivacy`, {
        change_origin_privacy: value,
      })
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries(['project', id])
      },
      onError: ({ response }) => {
        addToast(`Unable to update Privacy`, { appearance: 'error' })
        if (Array.isArray(response.data.errors))
          response.data.errors.forEach(e =>
            addToast(e, { appearance: 'error' }),
          )
        else
          addToast('Unable to update privacy setting', { appearance: 'error' })
      },
    },
  )

  const changeOrigin = useFetch(
    `/project/${id}/changesByOrigin`,
    'Unable to fetch changes by origin data',
  )
  const project = data?.data

  return (
    <>
      {user.isProjectManager ? (
        <ReactSelect
          styles={{
            container: styles => ({ ...styles, maxWidth: '200px' }),
          }}
          options={PRIVACY_OPTIONS}
          onChange={({ value }) => {
            updateChangeOriginChartPrivacy(value)
          }}
          isSearchable={false}
          value={PRIVACY_OPTIONS.find(
            opt => opt.value === project.change_origin_privacy,
          )}
          isLoading={isFetching}
        />
      ) : null}
      <LoadTransition isFetching={changeOrigin.isFetching}>
        {changeOrigin.data && Object.values(changeOrigin.data).length ? (
          <ChangeOrigin
            onChartIsReady={save('changeOrigin')}
            data={Object.values(changeOrigin.data).map(d => ({
              label: d.change_origin,
              value: d.total,
              color: d.colour,
            }))}
          />
        ) : (
          <div>No data to display</div>
        )}
      </LoadTransition>
    </>
  )
}
ChangeOriginLoader.propTypes = {
  id: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
}
