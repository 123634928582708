export const formatError = (e, message = '') => ({
  error: true,
  errors: [e.response?.data?.error || e.response?.data?.message || message],
})

export const createFormData = raw => {
  const formData = new FormData()

  Object.entries(raw).forEach(([k, v]) => {
    return formData.append(k, v ?? '')
  })
  return formData
}
