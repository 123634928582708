import styled from 'styled-components'
import { Button } from '../../components/ui'

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  button {
    margin-right: 0.5rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
  ${p => p.theme.layout.web} {
    flex-wrap: nowrap;
  }
`

export const ArchiveButton = styled(Button)`
  &:disabled {
    opacity: 0.2;
  }
`
