import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { Button } from '../../ui'

const FormGroup = styled.div`
  background: #fff;
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: ${p => p.theme.shadow};

  ${p => p.theme.layout.web} {
    padding: 2rem;
  }
`

const Heading = styled.h2`
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 31px;
`

const Flex = styled.div`
  display: flex;
  flex-flow: row wrap;
`
const Text = styled.p`
  line-height: 26px;
  font-size: 14px;
  flex: 1;
`
const ArchiveBtn = styled(Button)`
  margin-left: 100px;
  height: 36px;
`

const Archive = ({ id }) => {
  const navigate = useNavigate()
  const { addToast } = useToasts()

  const archiveProject = () => {
    return axios
      .post(`/project/${id}/archive`)
      .then(res => {
        if (res.status === 200) {
          addToast('Project has been archived', { autoDismiss: true })

          navigate('/projects')
        }
      })
      .catch(() => {
        const msg =
          'An error occurred while archiving this project. Please try again later.'
        addToast(msg, { appearance: 'error', autoDismiss: true })
      })
  }

  return (
    <div>
      <FormGroup>
        <Heading>7 - Archive Project</Heading>

        <Flex>
          <Text>
            By archiving this project you will disable it from all Active areas
            of the Platform. You will however be able to reactivate at any time
            by visiting the Admin area, and selecting &apos;Activate&apos;,
            which will then return it to &apos;Your Projects&apos; page.
          </Text>
          <ArchiveBtn small type="button" onClick={archiveProject}>
            Archive Project
          </ArchiveBtn>
        </Flex>
      </FormGroup>
    </div>
  )
}

Archive.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Archive
