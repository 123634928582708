import React, { useState } from 'react'
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
} from '@react-pdf/renderer'
import styled, { css } from 'styled-components'

import { ReactComponent as DocumentIcon } from '../../assets/icons/document.svg'
import { chartToImage } from '../../utilities/chartToImage'
import { ChangesByValuePdfPage } from './changesByValuePdf'

const useAnalyticsPdfHook = projectName => {
  const [changesByValue, setChangesByValue] = useState()
  const [changesByMonth, setChangesByMonth] = useState()
  const [costIncreases, setCostIncreases] = useState()
  const [categoryCosts, setCategoryCosts] = useState()
  const [riskAllowance, setRiskAllowance] = useState()
  const [changeOrigin, setChangeOrigin] = useState()

  const isReady =
    !!changesByValue &&
    !!changesByMonth &&
    !!costIncreases &&
    !!categoryCosts &&
    !!riskAllowance &&
    !!changeOrigin

  const setContent = (chart, content) => {
    if (chart === 'changesByMonth') setChangesByMonth(chartToImage(content))
    if (chart === 'costIncreases') setCostIncreases(chartToImage(content))
    if (chart === 'categoryCosts') setCategoryCosts(chartToImage(content))
    if (chart === 'riskAllowance') setRiskAllowance(chartToImage(content))
    if (chart === 'changeOrigin') setChangeOrigin(chartToImage(content))
  }

  return {
    setChangesByValue,
    setContent,
    downloadButton: isReady ? (
      <StyledDownloadLink
        document={
          <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
              <Text style={styles.projectTitle}>{projectName}</Text>
              <View style={styles.row} wrap={false}>
                <View style={{ ...styles.chart, marginLeft: 0 }}>
                  <Text style={styles.chartTitle}>Main Changes by Value</Text>
                  <ChangesByValuePdfPage
                    data={changesByValue.data}
                    currency={changesByValue.currency}
                  />
                </View>
                <View style={styles.chart}>
                  <Text style={styles.chartTitle}>Changes by Month</Text>
                  <Image src={changesByMonth} />
                </View>
                <View style={styles.chart}>
                  <Text style={styles.chartTitle}>
                    % Cost Increases Per Cost Constituent
                  </Text>
                  <Image src={costIncreases} />
                </View>
              </View>
              <View style={styles.row} wrap={false}>
                <View style={{ ...styles.chart, marginLeft: 0 }}>
                  <Text style={styles.chartTitle}>
                    Elemental Change Analysis
                  </Text>
                  <Image src={categoryCosts} />
                </View>
                <View style={styles.chart}>
                  <Text style={styles.chartTitle}>
                    Risk Allowance vs Actual Change
                  </Text>
                  <Image src={riskAllowance} />
                </View>
                <View style={styles.chart}>
                  <Text style={styles.chartTitle}>Change Origin</Text>
                  <Image src={changeOrigin} />
                </View>
              </View>
            </Page>
          </Document>
        }
        fileName={`analytics.pdf`}
      >
        {({ loading }) => {
          // if (error) handle error
          return loading ? (
            'Generating...'
          ) : (
            <>
              <DocumentIcon />
              Download
            </>
          )
        }}
      </StyledDownloadLink>
    ) : (
      <GeneratePDFButton>Loading...</GeneratePDFButton>
    ),
  }
}

export default useAnalyticsPdfHook

const style = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  font-size: 15px;
  padding: 11px 8px;
  color: black;
  max-width: 90px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  min-height: 43px;
  &:hover,
  &:focus {
    background: #eee;
  }
  svg {
    margin-right: 5px;
  }
`

const GeneratePDFButton = styled.div`
  ${style}
`
const StyledDownloadLink = styled(PDFDownloadLink)`
  ${style}
  text-decoration: none;
  max-width: 110px;
  &:hover,
  &:focus {
    background: #eee;
  }
`

const styles = StyleSheet.create({
  page: {
    margin: 20,
    backgroundColor: '#f8f9f9',
  },
  projectTitle: {
    fontSize: 18,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
  },
  chart: {
    width: '30%',
    marginLeft: 15,
    backgroundColor: 'white',
    padding: 10,
  },
  chartTitle: {
    fontSize: 12,
    marginBottom: 10,
  },
})
