import React from 'react'
import PropTypes from 'prop-types'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  Legend,
  Tooltip,
} from 'chart.js'
import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import styled from 'styled-components'
import { COST_CATEGORIES } from '../../constants/options'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  LineElement,
  PointElement,
  LineController,
  Legend,
  Tooltip,
)

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(100vw - 4rem);
  margin-bottom: 2rem;
  min-height: 300px;

  ${p => p.theme.layout.web} {
    max-width: calc(100% - 1px);
    min-height: 475px;
  }
`
const categories = {
  facilitating: { name: 'Facilitating & External', color: '255,192,0' },
  architectural: {
    name: 'Architectural & Structural',
    color: '91,155,213',
  },
  mep: { name: 'MEP Services', color: '68,114,196' },
  contractor: { name: 'Main Contractor Items', color: '237,125,49' },
  construction: { name: 'Other Construction', color: '255,153,255' },
  fees: { name: 'Professional Fees & Surveys', color: '112,173,71' },
  other: { name: 'Other Project Costs', color: '255,0,0' },
}

const subCategories = {
  [COST_CATEGORIES.external_works]: categories.facilitating,
  [COST_CATEGORIES.facilitating_works]: categories.facilitating,
  //
  [COST_CATEGORIES.substructure]: categories.architectural,
  [COST_CATEGORIES.frame]: categories.architectural,
  [COST_CATEGORIES.upper_floors]: categories.architectural,
  [COST_CATEGORIES.roof]: categories.architectural,
  [COST_CATEGORIES.stairs_and_ramps]: categories.architectural,
  [COST_CATEGORIES.external_walls]: categories.architectural,
  [COST_CATEGORIES.windows_and_external_doors]: categories.architectural,
  [COST_CATEGORIES.internal_walls_and_partitions]: categories.architectural,
  [COST_CATEGORIES.internal_doors]: categories.architectural,
  [COST_CATEGORIES.wall_finishes]: categories.architectural,
  [COST_CATEGORIES.ceiling_finishes]: categories.architectural,
  [COST_CATEGORIES.floor_finishes]: categories.architectural,
  [COST_CATEGORIES.fittings_furnishings_and_equipment]:
    categories.architectural,
  //
  [COST_CATEGORIES.services_public_health]: categories.mep,
  [COST_CATEGORIES.services_electrical]: categories.mep,
  [COST_CATEGORIES.services_mechanical]: categories.mep,
  [COST_CATEGORIES.services_other]: categories.mep,
  //
  [COST_CATEGORIES.main_contractor_preliminaries]: categories.contractor,
  [COST_CATEGORIES.main_contractor_ohp]: categories.contractor,
  //
  [COST_CATEGORIES.construction_contract_contingency]: categories.construction,
  [COST_CATEGORIES.construction_other]: categories.construction,
  [COST_CATEGORIES.none]: categories.construction,
  //
  // project fees
  [COST_CATEGORIES.project_fees]: categories.project_fees,
  // other project costs
  [COST_CATEGORIES.other_project_costs]: categories.other,
}

const CategoryCosts = props => {
  const labels = props.data.map(d => d.label)

  const datasets = Object.values(categories).map(category => {
    let include = false
    return {
      type: 'bar',
      label: category.name,
      data: props.data.map(d => {
        const isInDataset = subCategories[d.label].name === category.name
        if (isInDataset) include = true
        return isInDataset ? Number(d.total).toFixed(2) : null
      }),
      backgroundColor: `rgba(${category.color},1)`,
      include,
    }
  })
  const data = {
    labels,
    datasets: datasets.filter(ds => ds.include),
  }

  return (
    <>
      <ChartWrapper>
        <Chart
          type="bar"
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            grouped: false,
            animations: false,
            animation: {
              onComplete: ({ chart }) => props.onChartIsReady(chart),
            },
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left',
              },
            },
            plugins: {
              legend: {
                onClick: () => {},
              },
              tooltip: {
                intersect: false,
                mode: 'x',
                callbacks: {
                  label: function (context) {
                    if (context.parsed.y === null) return null
                    return new Intl.NumberFormat('en-GB', {
                      style: 'currency',
                      currency: 'GBP',
                    }).format(context.parsed.y)
                  },
                },
              },
            },
          }}
        />
      </ChartWrapper>
    </>
  )
}

CategoryCosts.propTypes = {
  onChartIsReady: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired,
      cumulativePercent: PropTypes.number.isRequired,
    }),
  ).isRequired,
}

export default CategoryCosts
