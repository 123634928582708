import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import styled from 'styled-components'

export const Input = styled.input`
  font-size: 14px;
  height: 32px;
  width: 100%;
  padding: 0;
  border: none;
  border-bottom: 1px solid ${p => (p.$error ? 'red' : 'transparent')};
  appearance: none;
  outline: none;
  &:disabled {
    background: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const FormikTableInput = ({ readonly, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <Input
      {...field}
      $error={meta.touched && meta.error}
      {...props}
      disabled={readonly}
    />
  )
}

FormikTableInput.defaultProps = {
  type: 'text',
  readonly: false,
}

FormikTableInput.propTypes = {
  type: PropTypes.oneOf(['text', 'date', 'number', 'password', 'email', 'tel']),
  name: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
}
