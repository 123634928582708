import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'

import { Loader, CardWrapper, Table, Button, Status, CSVButton } from '../../ui'
import useGetChangeLog from './useGetChangeLog'
import { getShortDate, formatCurrency } from '../../../utilities'
import { Select } from '../../form'

const SelectWrapper = styled.div`
  width: 100%;
  margin-left: 1rem;
  min-width: 100px;
  z-index: 10;
  ${p => p.theme.layout.web} {
    max-width: 200px;
  }
`

const HEADERS = [
  'Change Request',
  'Description',
  'Cost Constituent',
  'Originator Ref',
  'Date Raised',
  'Latest Cost',
  'Status',
  'Instruction No.',
  '',
]
const STATUSES = {
  All: '',
  Approved: 'approved',
  Pending: 'pending',
  Draft: 'draft',
  Rejected: 'rejected',
  Construction: 'construction',
  Fees: 'fees',
  Other: 'other',
}
const STATUS_OPTIONS = Object.entries(STATUSES).map(([label, value]) => ({
  label,
  value,
}))

const ChangeLog = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [filterValue, setFilterValue] = React.useState('')
  const { changelogs, fetchMore, isFetching } = useGetChangeLog(
    params.id,
    filterValue,
  )

  const filterList = ({ value }) => setFilterValue(value)

  return (
    <Loader isFetching={isFetching}>
      <CardWrapper
        as={InfiniteScroll}
        dataLength={changelogs?.length || 0}
        next={fetchMore}
        hasMore={!!fetchMore}
        style={{ minHeight: '400px' }}
      >
        <Table
          heading="Change Log"
          headers={HEADERS}
          inCard
          csvData={changelogs}
          actions={[
            <CSVButton
              key="csv"
              data={changelogs.map(({ id, cor, ...cl }) => ({
                cr: cor,
                ...cl,
              }))}
            >
              Export as CSV
            </CSVButton>,
            <SelectWrapper key="filter" style={{ width: '150px' }}>
              <Select
                name="table-select"
                label="View"
                value={STATUS_OPTIONS.find(opt => opt.value === filterValue)}
                onChange={filterList}
                onBlur={() => {}}
                options={STATUS_OPTIONS}
              />
            </SelectWrapper>,
          ]}
        >
          {changelogs?.map(change => (
            <tr key={change.id}>
              <td data-header={HEADERS[0]}>{change.cor}</td>
              <td data-header={HEADERS[1]}>{change.description}</td>
              <td
                data-header={HEADERS[2]}
                style={{ textTransform: 'capitalize' }}
              >
                {change.constituent}
              </td>
              <td data-header={HEADERS[3]}>{change.ref}</td>
              <td data-header={HEADERS[4]}>{getShortDate(change.created)}</td>
              <td data-header={HEADERS[5]}>
                {change.cost ? formatCurrency(change.cost) : ''}
              </td>
              <td data-header={HEADERS[6]}>
                <Status status={change.status} />
              </td>
              <td data-header={HEADERS[7]}>{change.instruction}</td>
              <td data-header={HEADERS[8]}>
                <Button
                  style={{ marginLeft: 'auto' }}
                  small
                  fullwidth
                  onClick={() =>
                    navigate(`/projects/${params.id}/changes/${change.id}`)
                  }
                >
                  View
                </Button>
              </td>
            </tr>
          ))}
        </Table>
      </CardWrapper>
      <Button
        style={{ margin: '3rem 0 0 auto' }}
        onClick={() => navigate(`/projects/${params.id}/overview`)}
      >
        Back
      </Button>
    </Loader>
  )
}

export default ChangeLog
