import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import styled from 'styled-components'

import useCompanies from '../../../hooks/useCompanies'
import { Form, FormikInput, FormikSelect, FormikCombo } from '../../form'
import { Button, Modal } from '../../ui'

const Grid = styled.div`
  display: grid;
  grid-gap: 0 2rem;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-template-rows: repeat(auto-fit);

  h3 {
    grid-column: 1 / -1;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  }
`

const NEW_USER_TYPE_OPTIONS = [
  { label: 'Contract Manager', value: 'contract_manager' },
  { label: 'Cost Manager', value: 'cost_manager' },
  { label: 'Designer', value: 'designer' },
]

const USER_TYPE_OPTIONS = [
  ...NEW_USER_TYPE_OPTIONS,
  { label: 'Client', value: 'client_entity' },
]

const AddUserModal = ({ isActive, onSubmit, onClose, user }) => {
  const { companies } = useCompanies()
  const isEdit = !!user

  return (
    <Modal
      isActive={isActive}
      onClose={onClose}
      title={isEdit ? `Edit user details` : `Invite new user`}
    >
      <Formik
        initialValues={{
          user_type: user?.user_type || '',
          role_title: user?.role_title || '',
          company: user?.company_id ? { value: user.company_id } : '',
          name: user?.name || '',
          email: user?.email || '',
          phone: user?.phone || '',
          id: user?.id || '',
        }}
        validationSchema={Yup.object().shape({
          user_type: Yup.string().required('User type is required'),
          role_title: Yup.string(),
          company: Yup.string().required('Company is required'),
          name: Yup.string().required('Name is required'),
          email: Yup.string()
            .email('Please enter a valid email address')
            .required('Email is required'),
          phone: Yup.string().min(9, 'Too Short!').max(18, 'Too Long!'),
        })}
        onSubmit={onSubmit}
      >
        <Form>
          <Grid>
            {user?.user_type === 'admin' ? (
              <FormikInput
                name="user_type"
                label="User Type"
                value="Admin"
                readonly
              />
            ) : (
              <FormikSelect
                name="user_type"
                label="User Type"
                options={isEdit ? USER_TYPE_OPTIONS : NEW_USER_TYPE_OPTIONS}
              />
            )}
            <FormikInput name="role_title" label="Job Title" />
            <FormikCombo
              name="company"
              label="Company"
              options={companies
                .map(company => ({ value: company.id, label: company.name }))
                .sort((a, b) => a.label.localeCompare(b.label))}
            />
            <FormikInput name="name" label="Name" />
            <FormikInput name="email" label="Email" />
            <FormikInput name="phone" label="Phone" />
          </Grid>
          <Button small type="submit" style={{ marginLeft: 'auto' }}>
            {isEdit ? 'Save details' : 'Send email invite'}
          </Button>
        </Form>
      </Formik>
    </Modal>
  )
}

AddUserModal.defaultProps = {
  user: null,
}
AddUserModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    user_type: PropTypes.string,
    role_title: PropTypes.string,
    company_id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    id: PropTypes.number,
  }),
}

export default AddUserModal
