import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray, useField } from 'formik'
import styled from 'styled-components'

import { Table, Button } from '../../../ui'
import { formatCurrency } from '../../../../utilities'
import { FormikTableSelect, FormikTableInput } from '../../../form'
import { ReactComponent as Cross } from '../../../../assets/icons/cross.svg'

const STable = styled(Table)`
  th {
    text-align: start;
  }
`
const TotalText = styled.h4`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  margin: 0.5rem 0;
`

const TdNoHeading = styled.td`
  &::before {
    display: none !important;
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Delete = styled.button`
  cursor: pointer;
  appearance: none;
  background: #f0f0f0;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 10px;
    height: 10px;
  }
`

//#region  table

//#endregion

function calcTotal(quantity, rate) {
  const q = quantity ? parseFloat(quantity) : 0
  const r = rate ? parseFloat(rate) : 0

  const total = q * r
  if (typeof total === 'number' && !Number.isNaN(total)) return total.toFixed(2)
  return 'Error'
}

const CrCosts = ({ costCategories, canEdit, currency }) => {
  const [field, , form] = useField({ name: 'cost_breakdown' })
  const [total, setTotal] = React.useState('')

  const symbol = currency === 'eur' ? '€' : '£'
  const HEADERS = [
    '',
    'Cost Category',
    'Description',
    'Qty',
    'Unit',
    `Rate (${symbol})`,
    `Total (${symbol})`,
    '',
  ]

  React.useEffect(() => {
    if (field.value) {
      const getTotal = (acc, v) =>
        (acc += parseFloat(calcTotal(v.rate, v.quantity)))

      const newTotal = field.value.reduce(getTotal, 0)
      setTotal(newTotal)
    }
  }, [field.value])

  const addNewRow = () => {
    const newRow = {
      id: '',
      cost_category: null,
      description: '',
      quantity: 0,
      unit: '',
      rate: 0,
      total: 0,
    }
    form.setValue(field.value ? [...field.value, newRow] : [newRow])
  }

  return (
    <STable table headers={HEADERS}>
      <FieldArray name="cost_breakdown">
        {({ remove }) =>
          field.value
            ? field.value.map((v, i) => (
                <tr key={i}>
                  <td data-header={HEADERS[0]}>{i + 1}.</td>
                  <td data-header={HEADERS[1]}>
                    <FormikTableSelect
                      name={`cost_breakdown.${i}.cost_category`}
                      options={costCategories}
                      menuPlacement="top"
                      readonly={!canEdit}
                    />
                  </td>
                  <td data-header={HEADERS[2]} style={{ columnWidth: '600px' }}>
                    <FormikTableInput
                      name={`cost_breakdown.${i}.description`}
                      readonly={!canEdit}
                    />
                  </td>
                  <td data-header={HEADERS[3]}>
                    <FormikTableInput
                      type="number"
                      name={`cost_breakdown.${i}.quantity`}
                      readonly={!canEdit}
                    />
                  </td>
                  <td data-header={HEADERS[4]}>
                    <FormikTableInput
                      name={`cost_breakdown.${i}.unit`}
                      readonly={!canEdit}
                    />
                  </td>
                  <td data-header={HEADERS[5]}>
                    <FormikTableInput
                      type="number"
                      name={`cost_breakdown.${i}.rate`}
                      readonly={!canEdit}
                    />
                  </td>
                  <td data-header={HEADERS[6]}>
                    {calcTotal(v.rate, v.quantity)}
                  </td>
                  <td>
                    {canEdit ? (
                      <Delete type="button" onClick={() => remove(i)}>
                        <Cross />
                      </Delete>
                    ) : null}
                  </td>
                </tr>
              ))
            : null
        }
      </FieldArray>

      <tr>
        <TdNoHeading colSpan={8} style={{ ':before': { display: 'none' } }}>
          <Row>
            {canEdit ? (
              <Button type="button" small onClick={addNewRow}>
                Add New Row
              </Button>
            ) : (
              <div />
            )}
            <TotalText>
              Total: {symbol}
              {formatCurrency(total || 0)}
            </TotalText>
          </Row>
        </TdNoHeading>
      </tr>
    </STable>
  )
}

CrCosts.defaultProps = {
  canEdit: true,
}
CrCosts.propTypes = {
  costCategories: PropTypes.array.isRequired,
  canEdit: PropTypes.bool,
  currency: PropTypes.string.isRequired,
}

export default CrCosts
