import React from 'react'
import PropTypes from 'prop-types'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  Legend,
  Tooltip,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import styled from 'styled-components'

ChartJS.register(LinearScale, CategoryScale, BarElement, Legend, Tooltip)

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(100vw - 4rem);
  margin-bottom: 2rem;
  min-height: 300px;

  ${p => p.theme.layout.web} {
    max-width: calc(100% - 1px);
    min-height: 475px;
  }
`

const CostIncreases = props => {
  const data = {
    labels: props.data.map(d => d.label),
    datasets: [
      {
        backgroundColor: [
          'rgb(68, 114, 196)',
          'rgb(112, 173, 71)',
          'rgb(255, 0,0)',
        ],
        data: props.data.map(d => d.change),
      },
    ],
  }

  return (
    <ChartWrapper>
      <Doughnut
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          animations: false,
          spacing: 3,
          animation: {
            onComplete: ({ chart }) => props.onChartIsReady(chart),
          },
          plugins: {
            legend: {
              onClick: () => {},
            },
          },
        }}
      />
    </ChartWrapper>
  )
}

CostIncreases.propTypes = {
  onChartIsReady: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      change: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default CostIncreases
