import { useFetch } from '../../hooks'

const useGetDash = () => {
  const url = '/dashboard'
  const { data, isFetching } = useFetch(url, 'Unable to fetch dash data')

  const formattedData = data && {
    projects: data.project_total_costs.map(project => ({
      id: project.project_id,
      name: project.project_name,
      currency: project.currency === 'gbp' ? '£' : '€',
      area: project.area_m2,
      total_cost: project.total_cost,
      total_per_m2: project.total_m2,
      fee_percent: project.fees_percentage,
      construction_m2: project.construction_m2,

      // bar chart
      construction: project.chart_data.construction,
      fees: project.chart_data.fees,
      other: project.chart_data.other,
      risk: project.chart_data.risk,
    })),
    // pie charts
    changes_risk_remaining: data.changes_risk_remaining.map(project => ({
      id: project.project_id,
      name: project.project_name,
      changes: project.changes <= 0 ? 0 : project.changes,
      remaining_risk_allowance:
        project.remaining_risk_allowance <= 0
          ? 0
          : project.remaining_risk_allowance,
      remaining_budget:
        project.remaining_budget <= 0 ? 0 : project.remaining_budget,
      start_date: project.start_date,
      end_date: project.end_date,
      anticipated_end_date: project.anticipated_end_date,
    })),
  }

  return { data: formattedData, isFetching }
}

export default useGetDash
