import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Card, CardHeading } from '..'
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg'

const StyledTable = styled.table`
  text-align: left;
  border-collapse: collapse;
  margin: 1rem;
  width: calc(100% - 2rem);
  color: #000000;

  tr {
    width: 100%;
    border-bottom: 1px solid #c5c5c5;
    &:first-of-type {
      border-top: 1px solid #c5c5c5;
    }
  }
  th,
  td {
    height: 54px;
    width: 50%;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    padding: 0.5rem 0;
  }
  th {
    font-weight: bold;
  }

  ${p => p.theme.layout.web} {
    width: 100%;
    min-width: 350px;
    margin: 0;
  }
`
const VerticalTable = ({ inCard, heading, children, ...props }) => {
  const content = (
    <>
      {heading ? <CardHeading>{heading}</CardHeading> : null}
      <StyledTable {...props}>
        <tbody>{children}</tbody>
      </StyledTable>
    </>
  )

  return inCard ? <Card>{content}</Card> : content
}

VerticalTable.defaultProps = {
  heading: '',
  children: null,
  inCard: false,
}
VerticalTable.propTypes = {
  heading: PropTypes.any,
  children: PropTypes.node,
  inCard: PropTypes.bool,
}

export default VerticalTable

// Collapsible Rows
const TrHead = styled.tr`
  cursor: pointer;
  background: ${p => (p.$isOpen ? '#EEEEEE' : 'transparent')};
  transition: background 150ms ease-in-out;

  th {
    transform: translateX(${p => (p.$isOpen ? '1rem' : 0)});
    transition: transform 150ms ease-in-out;
  }
  td {
    text-align: end;
  }
`
const Tr = styled.tr`
  visibility: ${p => (p.$isOpen ? 'visible' : 'collapse')};
  transform: scaleX(${p => (p.$isOpen ? 1 : 0)});
  transform-origin: left;
  transition: transform 150ms ease-in-out;
  th {
    padding-left: 1rem;
  }
`

const Arrow = styled(ArrowIcon)`
  margin-right: 1rem;
  width: 11px;
  transform: rotateZ(${p => (p.$isOpen ? '180deg' : 0)});
  transition: transform 150ms ease-in-out;
`

const VTCollapsible = ({ heading, children }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <>
      <TrHead onClick={() => setIsOpen(v => !v)} $isOpen={isOpen}>
        <th>{heading}</th>
        <td>
          <Arrow $isOpen={isOpen} />
        </td>
      </TrHead>
      {React.Children.map(children, ({ props }) => (
        <Tr
          {...props}
          className={isOpen ? 'safariOpen' : 'safariClosed'}
          $isOpen={isOpen}
        />
      ))}
    </>
  )
}

VTCollapsible.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
}

export { VTCollapsible }
