import axios from 'axios'

import * as auth from './auth'
import * as general from './general'
import * as project from './project'
import * as cr from './changeRequest'
import * as notifications from './notifications'

axios.defaults.baseURL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API
    : process.env.REACT_APP_DEV_API

export default {
  ...general,
  ...project,
  ...auth,
  ...cr,
  ...notifications,
}
