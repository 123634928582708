import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'

import { Label, Error, Row } from '..'

export const DateInputWrapper = styled.div.attrs(props => ({
  className: [
    props.hasError ? 'has-error' : '',
    props.readonly ? 'isReadonly' : '',
  ],
}))`
  position: relative;
  display: flex;
  padding-top: 1.75rem;
  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    border: 1px solid transparent;
    border-bottom: 2px solid
      ${p => (p.hasError ? p.theme.color.error : 'transparent')};
    transition: border-color 150ms ease-in-out;
  }

  &.isReadonly {
    &:focus-within {
      &::after {
        border-bottom-color: transparent;
      }
    }
    input {
      user-select: none;
      border-bottom-color: transparent;
      background: transparent;
    }
  }

  input {
    font-size: 14px;
    height: 32px;
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    border-bottom: 1px solid ${p => p.theme.color.border};
    appearance: none;
  }

  &:focus-within {
    &:after {
      border-bottom: 2px solid ${p => p.theme.color.focus};
    }
  }

  input::placeholder {
    visibility: hidden;
    opacity: 0;
  }
  &.isFilled label {
    transform: translateY(-1.2rem);
    font-size: 13px;
    pointer-events: all;
  }
`

const Pre = styled.pre`
  white-space: pre-wrap;
`

export const DateInputWithLabel = ({
  className,
  label,
  name,
  errorText,
  onChange,
  value,
  readonly,
}) => {
  return (
    <div className={className}>
      <DateInputWrapper
        hasError={!!errorText}
        className={`${value ? 'isFilled' : ''} ${readonly ? 'isReadonly' : ''}`}
      >
        <Row>
          <Label htmlFor={name}>{label}</Label>
        </Row>
        {readonly ? (
          <Pre>{value}</Pre>
        ) : (
          <DatePicker
            selected={value ? new Date(value) : ''}
            onChange={date => {
              // const utc = new Date(
              //   Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
              // )
              onChange && onChange(date ? date.toISOString() : date)
            }}
            dateFormat="dd/MM/yyy"
          />
        )}
      </DateInputWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

DateInputWithLabel.defaultProps = {
  errorText: null,
  className: '',
  value: null,
  readonly: false,
  onChange: () => {},
}

DateInputWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
}
