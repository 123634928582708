import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

//#region styles
const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
`

const Label = styled.label`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  color: ${p => p.theme.color.text};
`

const Checkbox = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 19px;
  width: 19px;
  border: 1px solid ${p => p.theme.color.border};
  margin-right: 12px;
  background: white;
  outline: none;
  transition: background 200ms ease-in-out;
  &:hover,
  &:focus {
    border: 2px solid ${p => p.theme.color.focus};
  }
`

const Check = styled.svg`
  width: 14px;
  height: 11px;
  stroke: #000;
  stroke-width: 2;
  fill: none;
  stroke-linecap: 'square';
  opacity: ${p => (p.isChecked ? 1 : 0)};
  transition: opacity 200ms ease-in-out;
`
const HiddenCheckbox = styled.input`
  display: none;
`
//#endregion

export const CheckboxWithLabel = ({
  name,
  label,
  value,
  onChange,
  readonly,
  className,
}) => {
  const [isChecked, setIsChecked] = useState(value)
  const update = newValue => {
    onChange(newValue)
    setIsChecked(newValue)
  }

  return (
    <Wrapper className={className}>
      <Checkbox
        className="check"
        onClick={() => update(!isChecked)}
        type="button"
        disabled={readonly}
      >
        <Check
          width="14"
          height="11"
          xmlns="http://www.w3.org/2000/svg"
          isChecked={isChecked}
          role="checkbox"
          aria-checked={isChecked}
          aria-labelledby={name}
        >
          <path d="M12.25 1.75L5.0472 8.9528 2.25 6.1556" />
        </Check>
      </Checkbox>
      <HiddenCheckbox
        type="checkbox"
        id={name}
        checked={isChecked}
        onChange={() => update(!isChecked)}
        disabled={readonly}
      />
      <Label htmlFor={name}>{label}</Label>
    </Wrapper>
  )
}

CheckboxWithLabel.defaultProps = {
  value: false,
  readonly: false,
  className: '',
}

CheckboxWithLabel.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOf([true, false, 0, 1]),
  readonly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}
