import React from 'react'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'

import useNotifications from './useNotifications'
import Notification from './notification'

//#region styles
const Header = styled.header`
  padding: 0.5rem 0.5rem 0 0.5rem;
  ${p => p.theme.layout.web} {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
`

const Heading = styled.h1`
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 17px;
  text-transform: uppercase;
  margin-bottom: 24px;
`
const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  background: white;
`

const Tab = styled.button`
  min-height: 32px;
  display: flex;
  align-items: center;
  width: fit-content;
  text-transform: uppercase;
  margin: 0 1rem 0 0;
  padding-bottom: 1rem;
  outline-offset: 0.5rem;
  cursor: pointer;
  color: #626261;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16px;
  border: none;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 200ms, color 200ms;
  background: none;

  &[data-current='true'] {
    color: ${p => p.theme.color.primary};
    border-color: ${p => p.theme.color.primary};
  }
`

const Content = styled.div`
  overflow: scroll;
  margin-bottom: 50px;
  ${p => p.theme.layout.web} {
    margin-bottom: 0;
  }
`

const MarkReactBtn = styled.button`
  background: none;
  text-transform: uppercase;
  color: #575756;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: right;
  text-decoration: underline;
  border: none;
  height: fit-content;
  margin-left: auto;
`
//#endregion

const Notifications = ({ children }) => {
  const [tab, setTab] = React.useState('unread')
  const {
    allNotifications,
    fetchMoreAll,
    unreadNotifications,
    fetchMoreUnread,
    markAllRead,
  } = useNotifications()

  const handleSwitchTab = (e, tab) => {
    e.stopPropagation()
    setTab(tab)
  }

  const handleMarkAll = e => {
    e.stopPropagation()
    markAllRead()
  }

  const hasNotifications = unreadNotifications.length

  return children({
    hasNotifications,
    notifications: (
      <>
        <Header>
          <Heading>Notifications</Heading>
          <Nav>
            <Tab
              data-current={tab === 'unread'}
              onClick={e => handleSwitchTab(e, 'unread')}
              onMouseDown={e => e.preventDefault()}
              type="button"
            >
              Unread
            </Tab>
            <Tab
              data-current={tab === 'all'}
              onClick={e => handleSwitchTab(e, 'all')}
              onMouseDown={e => e.preventDefault()}
              type="button"
            >
              View All
            </Tab>
            <MarkReactBtn onClick={handleMarkAll}>
              Mark all as read
            </MarkReactBtn>
          </Nav>
        </Header>
        <Content>
          {tab === 'unread' ? (
            <InfiniteScroll
              dataLength={unreadNotifications?.length || 0}
              next={fetchMoreUnread}
              hasMore={!!fetchMoreUnread}
            >
              {unreadNotifications.map(n => (
                <Notification key={n.id} data={n} />
              ))}
            </InfiniteScroll>
          ) : null}

          {tab === 'all' ? (
            <InfiniteScroll
              dataLength={allNotifications?.length || 0}
              next={fetchMoreAll}
              hasMore={!!fetchMoreAll}
            >
              {allNotifications.map(n => (
                <Notification key={n.id} data={n} />
              ))}
            </InfiniteScroll>
          ) : null}
        </Content>
      </>
    ),
  })
}

export default Notifications
