import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'

import validationSchema from './schema'
import { Button, Modal } from '../../../../ui'
import { Form, FormikInput } from '../../../../form'
import { htmlFormat, getShortDate } from '../../../../../utilities'

//#region styles
const Layout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 0.5rem 2rem;
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
`

const Row = styled.div`
  grid-column: 1 / -1;
`

const Heading = styled.h2`
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  margin: 1rem 0 0 0;
  grid-column: 1 / -1;
`

const SecondaryBtn = styled(Button)`
  background: white;
  border: 1px solid black;
  color: black;
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: #eee;
  }
`

const DeleteBtn = styled(SecondaryBtn)`
  background: white;
  border: 1px solid ${p => p.theme.color.accent};
  color: ${p => p.theme.color.accent};
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: #eee;
  }
`
//#endregion

const UpdateForm = ({ cr, updatePublishedCR, deleteCR, isSubmitting }) => {
  const [showModal, setShowModal] = React.useState(false)
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)

  const submit = async values => {
    const success = await updatePublishedCR({
      rfi: values.rfi,
      instruction_number: values.instruction_number,
      instruction_date: getShortDate(values.instruction_date),
    })
    if (success) setShowModal(false)
  }

  const onDelete = () => {
    deleteCR(cr.id)
    setShowDeleteModal(false)
  }

  return (
    <>
      <SecondaryBtn small type="button" onClick={() => setShowModal(true)}>
        Edit
      </SecondaryBtn>

      <Modal
        isActive={showModal}
        onClose={() => setShowModal(false)}
        width="550px"
        title={`Edit Change Request #${cr.cr_no}`}
      >
        <Formik
          initialValues={{
            ...cr,
            instruction_date: htmlFormat(cr.instruction_date),
          }}
          validationSchema={validationSchema}
          onSubmit={submit}
        >
          <Form>
            <Layout>
              <FormikInput name="rfi" label="RFI number" />

              <Heading>Instruction details</Heading>
              <FormikInput
                name="instruction_number"
                label="Instruction Number"
              />
              <FormikInput
                name="instruction_date"
                label="Instruction Date"
                type="date"
              />

              <Row>
                <Flex>
                  <DeleteBtn
                    small
                    onClick={() => setShowDeleteModal(true)}
                    disabled={isSubmitting}
                  >
                    Delete
                  </DeleteBtn>
                  <Modal
                    isActive={showDeleteModal}
                    onClose={() => setShowDeleteModal(false)}
                    width="300px"
                    title="Confirm delete"
                  >
                    <Flex>
                      <DeleteBtn
                        small
                        disabled={isSubmitting}
                        onClick={onDelete}
                      >
                        Confirm
                      </DeleteBtn>
                      <SecondaryBtn
                        small
                        disabled={isSubmitting}
                        onClick={() => setShowDeleteModal(false)}
                      >
                        Cancel
                      </SecondaryBtn>
                    </Flex>
                  </Modal>
                  <SecondaryBtn
                    style={{ marginLeft: 'auto', marginRight: '1rem' }}
                    small
                    onClick={() => setShowModal(false)}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </SecondaryBtn>
                  <Button type="submit" small disabled={isSubmitting}>
                    Save
                  </Button>
                </Flex>
              </Row>
            </Layout>
          </Form>
        </Formik>
      </Modal>
    </>
  )
}

UpdateForm.propTypes = {
  cr: PropTypes.any.isRequired,
  updatePublishedCR: PropTypes.func.isRequired,
  deleteCR: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
}
export default UpdateForm
