import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CSVLink } from 'react-csv'

import { toCsv, pivot } from '../../utilities'

const CSVBtn = styled(CSVLink)`
  width: fit-content;
  font-size: 13px;
  padding: 0.5rem 1.25rem;
  align-items: center;
  appearance: none;
  background: none;
  border: 1px solid black;
  color: black;
  display: flex;
  font-weight: 500;
  justify-content: center;
  line-height: 13px;
  min-height: 39px;
  text-transform: uppercase;
  text-decoration: none;
  transition: background 200ms;

  &:hover,
  &:focus {
    background: ${props => (props.disabled ? 'none' : '#eee')};
  }

  filter: ${props => (props.disabled ? 'contrast(0.4)' : 'none')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`
const CSVWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 150px;
`

export const CSVButton = ({ data }) => {
  const noData = !data || data.length === undefined || data.length === 0
  return (
    <CSVWrapper>
      <CSVBtn
        data={toCsv(pivot(data))}
        disabled={noData}
        onClick={() => !noData}
      >
        Export as CSV
      </CSVBtn>
    </CSVWrapper>
  )
}

CSVButton.defaultProps = {
  data: [],
}
CSVButton.propTypes = {
  data: PropTypes.array,
}
