import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg'
import { Button } from '../../../ui'
import { EditIcon } from './details/ui'
import { CHANGE_ORIGIN_OPTIONS } from '../../../../constants'
import { Select } from '../../../form'
import styled from 'styled-components'

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 400px;
`
const StyledSelect = styled(Select)`
  flex: 1 1 200px;
`

const ChangeOrigin = ({ changeOrigin, update, isSubmitting, canEdit }) => {
  const inputRef = React.useRef()
  const [isEditingMode, setEditingMode] = React.useState(false)
  const [inputValue, setInputValue] = React.useState()

  const originOption = CHANGE_ORIGIN_OPTIONS.find(
    opt => opt.value === changeOrigin,
  )
  React.useEffect(() => {
    if (originOption) setInputValue(originOption)
  }, [originOption])

  React.useEffect(() => {
    if (isEditingMode && inputRef.current) inputRef.current.focus()
  }, [isEditingMode])

  const startEdit = () => setEditingMode(true)

  const cancelEdit = () => {
    setEditingMode(false)
    setInputValue(originOption || '')
  }

  const saveOrigin = async () => {
    const success = await update({ change_origin: inputValue?.value })
    if (success) setEditingMode(false)
  }

  if (!canEdit)
    return (
      <StyledSelect
        name="change_origin"
        label="Change Origin"
        value={inputValue}
        options={CHANGE_ORIGIN_OPTIONS}
        readonly={true}
        onChange={selection => setInputValue(selection)}
      />
    )

  if (isEditingMode)
    return (
      <FlexRow>
        <StyledSelect
          name="change_origin"
          label="Change Origin"
          value={inputValue}
          options={CHANGE_ORIGIN_OPTIONS}
          onChange={setInputValue}
        />
        <Button
          small
          onClick={saveOrigin}
          disabled={isSubmitting}
          style={{ height: 'fit-content', marginLeft: 8 }}
        >
          Save
        </Button>
        <Button
          small
          icon
          onClick={cancelEdit}
          style={{ height: 'fit-content' }}
        >
          <CloseIcon style={{ width: '24px' }} disabled={isSubmitting} />
        </Button>
      </FlexRow>
    )

  if (inputValue)
    return (
      <FlexRow>
        {inputValue?.label}
        {canEdit && (
          <Button small icon onClick={startEdit} disabled={isSubmitting}>
            <EditIcon />
          </Button>
        )}
      </FlexRow>
    )
  if (canEdit && !inputValue)
    return (
      <Button small onClick={startEdit} disabled={isSubmitting}>
        Set change origin
      </Button>
    )

  return null
}

ChangeOrigin.defaultProps = {
  canEdit: false,
  changeOrigin: '',
}

ChangeOrigin.propTypes = {
  changeOrigin: PropTypes.string,
  update: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool,
}

const Wrapper = styled.div`
  padding: 0 1rem;
  margin-bottom: 1rem;
  ${p => p.theme.layout.web} {
    padding: 0;
  }
`

export default function (props) {
  return (
    <Wrapper>
      <ChangeOrigin {...props} />
    </Wrapper>
  )
}
