import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

export const LoadingOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.05);
  z-index: 1000;
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  padding: 50px;
  background: #f8f9f9;
  svg {
    width: 100%;
    stroke-dasharray: 40;
    stroke-dashoffset: 0;
    animation: dash 1s ease-in-out infinite;
    @keyframes dash {
      to {
        stroke-dashoffset: 160;
      }
    }
  }
`

export const LoadingIndicator = () => (
  <svg viewBox="0 0 50 50">
    <rect
      x="5"
      y="5"
      width="40"
      height="40"
      fill="none"
      strokeWidth="2"
      stroke="black"
    />
  </svg>
)

export const Loader = ({ children, isFetching }) => (
  <>
    <CSSTransition
      in={isFetching}
      timeout={200}
      classNames="fade"
      unmountOnExit
    >
      <LoadingOverlay>
        <LoadingWrapper>
          <LoadingIndicator />
        </LoadingWrapper>
      </LoadingOverlay>
    </CSSTransition>
    <CSSTransition in={!!children} timeout={200} classNames="fade">
      <>{children}</>
    </CSSTransition>
  </>
)

Loader.defaultProps = {
  children: null,
  isFetching: false,
}
Loader.propTypes = {
  isFetching: PropTypes.bool,
  children: PropTypes.node,
}
