import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, Modal } from '../../ui'

const Text = styled.p`
  margin-bottom: 0.5rem;
`
const Heading = styled.span`
  display: inline-block;
  width: 80px;
`
const Warning = styled.p`
  text-transform: uppercase;
  color: darkred;
  margin: 1rem 0;
`

const DeleteUserModal = ({ isActive, onSubmit, onClose, user, hardDelete }) => {
  return (
    <Modal
      isActive={isActive}
      onClose={onClose}
      title={`${hardDelete ? 'Delete' : 'Archive'} user`}
    >
      <Text>
        Are you sure you want to {hardDelete ? 'delete' : 'archive'} this user?
      </Text>
      <p>
        <Heading>Name:</Heading> {user?.name}
      </p>
      <p>
        <Heading>Email:</Heading> {user?.email}
      </p>
      <p>
        <Heading>Company:</Heading> {user?.company}
      </p>
      {hardDelete && (
        <Warning>This is permanent and cannot be reversed.</Warning>
      )}
      <Button small onClick={onSubmit} style={{ marginTop: '2rem' }}>
        Confirm
      </Button>
    </Modal>
  )
}

DeleteUserModal.defaultProps = {
  hardDelete: false,
  user: null,
}
DeleteUserModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    user_type: PropTypes.string,
    role_title: PropTypes.string,
    company: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  hardDelete: PropTypes.bool,
}

export default DeleteUserModal
