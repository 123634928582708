import React from 'react'
import styled from 'styled-components'
import { Pie } from 'react-chartjs-2'
import PropTypes from 'prop-types'

import { differenceInDays } from 'date-fns'

export const PIE_COLORS = ['#FF073A', '#20CA14', '#13800C']
export const DONUT_COLORS = ['#0086CB', '#6EC2DA']

const PieWrapper = styled.div`
  position: relative;
  height: 100%;
  p {
    text-align: center;
  }
`

const PROGRESS_DATA = 'Project Progress'
const LABELS = ['C', 'RRA', 'RB']

export default function RiskPieChart({ project }) {
  let daysFromStart = calculateDaysFromStart(project.start_date)
  let daysToEnd = calculateDaysToEnd(project.anticipated_end_date)

  const data = {
    labels: LABELS,
    datasets: [
      {
        label: PROGRESS_DATA,
        data: [daysFromStart, daysToEnd],
        backgroundColor: DONUT_COLORS,
        weight: 1,
      },
      {
        label: 'Changes vs Risk/Budget',
        data: [
          Number(project.changes).toFixed(2),
          Number(project.remaining_risk_allowance).toFixed(2),
          Number(project.remaining_budget).toFixed(2),
        ],
        backgroundColor: PIE_COLORS,
        weight: 5,
      },
    ],
  }
  return (
    <PieWrapper name={project.name}>
      <Pie
        className="dash-pie"
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: function (context) {
                  if (context[0].dataset.label === PROGRESS_DATA)
                    return PROGRESS_DATA
                  return ''
                },
                label: function (context) {
                  if (context.dataset.label === PROGRESS_DATA) {
                    if (context.label === LABELS[0])
                      return ` ${context.raw} passed`
                    return ` ${context.raw} remaining`
                  }
                  let label = context.label || ''
                  if (context.parsed !== null) {
                    label = ` ${label}: ${new Intl.NumberFormat('en-GB', {
                      style: 'currency',
                      currency: 'GBP',
                      maximumFractionDigits: 0,
                    }).format(context.parsed)}`
                  }
                  return label
                },
              },
            },
          },
        }}
        data={data}
      />
      <p>{project.name}</p>
    </PieWrapper>
  )
}

RiskPieChart.propTypes = {
  project: PropTypes.shape({
    changes: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    remaining_budget: PropTypes.number.isRequired,
    remaining_risk_allowance: PropTypes.number.isRequired,
    anticipated_end_date: PropTypes.string,
    end_date: PropTypes.string,
    start_date: PropTypes.string,
  }).isRequired,
}

function calculateDaysFromStart(startDate) {
  if (!startDate) return 0
  else {
    const differenceInDaysFromStart = differenceInDays(
      new Date(),
      new Date(startDate),
    )
    const isNegative = differenceInDaysFromStart < 0
    return isNegative ? 0 : differenceInDaysFromStart
  }
}

function calculateDaysToEnd(endDate) {
  return endDate ? differenceInDays(new Date(endDate), new Date()) : 0
}
