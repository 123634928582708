import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Noti = styled(Link)`
  display: block;
  text-decoration: none;
  cursor: pointer;
  padding: 1rem 0.5rem;
  background: ${p => (p.$isUnread ? '#FAFAFA' : 'white')};
  border-left: 2px solid ${p => (p.$isUnread ? 'black' : 'transparent')};
  border-bottom: 1px solid #c5c5c5;

  &:nth-of-type(1) {
    border-top: 1px solid #c5c5c5;
  }

  h3 {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
  }

  p {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
  }

  .date {
    color: #626261;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
  }

  ${p => p.theme.layout.web} {
    padding: 1rem 1.5rem;
  }
`

const getDateTime = d => {
  const dateTime = new Date(d)
  const timeFormat = { hour: '2-digit', minute: '2-digit' }
  const date = dateTime.toLocaleDateString()
  const time = dateTime.toLocaleTimeString([], timeFormat)
  return `${date} ${time}`
}

// TODO most require CR number for display

const Notification = ({ data: { data, created_at, read_at } }) => {
  if (data.type === 'change_request')
    return (
      <Noti
        $isUnread={!read_at}
        to={`/projects/${data.project.id}/changes/${data.change_request}`}
      >
        <h3>CR #{data.change_request_no}</h3>
        <p>
          {data.project.name} updated by {data.author}
        </p>
        <p className="date">{getDateTime(created_at)}</p>
      </Noti>
    )
  if (data.type === 'added_to_project')
    return (
      <Noti $isUnread={!read_at} to={`/projects/${data.project.id}`}>
        <h3>Added to project #{data.project.id}</h3>
        <p>{data.project.name}</p>
        <p className="date">{getDateTime(created_at)}</p>
      </Noti>
    )
  if (data.type === 'change_request_approved')
    return (
      <Noti
        $isUnread={!read_at}
        to={`/projects/${data.project.id}/changes/${data.change_request}`}
      >
        <h3>Change Request approved #{data.change_request_no}</h3>
        <p>{data.project.name}</p>
        <p className="date">{getDateTime(created_at)}</p>
      </Noti>
    )
  if (data.type === 'change_request_rejected')
    return (
      <Noti
        $isUnread={!read_at}
        to={`/projects/${data.project.id}/changes/${data.change_request}`}
      >
        <h3>Change Request rejected #{data.change_request_no}</h3>
        <p>{data.project.name}</p>
        <p className="date">{getDateTime(created_at)}</p>
      </Noti>
    )
  if (data.type === 'change_request_pending')
    return (
      <Noti
        $isUnread={!read_at}
        to={`/projects/${data.project.id}/changes/${data.change_request}`}
      >
        <h3>Change Request pending #{data.change_request_no}</h3>
        <p>{data.project.name}</p>
        <p className="date">{getDateTime(created_at)}</p>
      </Noti>
    )
  if (data.type === 'costs_approval_required')
    return (
      <Noti
        $isUnread={!read_at}
        to={`/projects/${data.project.id}/changes/${data.change_request}`}
      >
        <h3>Costs approval required #{data.change_request_no}</h3>
        <p>{data.project.name}</p>
        <p className="date">{getDateTime(created_at)}</p>
      </Noti>
    )
  if (data.type === 'costs_approved')
    return (
      <Noti
        $isUnread={!read_at}
        to={`/projects/${data.project.id}/changes/${data.change_request}`}
      >
        <h3>Costs approved #{data.change_request_no}</h3>
        <p>{data.project.name}</p>
        <p className="date">{getDateTime(created_at)}</p>
      </Noti>
    )
  return null
}

Notification.propTypes = {
  data: PropTypes.any.isRequired,
}

export default Notification
