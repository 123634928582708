import styled from 'styled-components'

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: -1rem;
  ${p => p.theme.layout.web} {
    flex-direction: row;
    flex-wrap: ${p => (p.nowrap ? 'nowrap' : 'wrap')};
  }
`

export const Card = styled.div`
  background: white;
  box-shadow: ${p => p.theme.shadow};
  padding: 1.75rem 0;
  flex: 1;
  margin: 1rem 1rem 1rem 1rem;

  ${p => p.theme.layout.web} {
    padding: 2rem;
  }
`

export const CardHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem 1.75rem 1rem;

  color: #000000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;

  h1 {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
  }
  ${p => p.theme.layout.web} {
    margin: 0 0 1.75rem 0;
  }
`
