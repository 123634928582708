import React, { useState } from 'react'
import axios from 'axios'
import { useQuery, useQueryCache, useMutation } from 'react-query'
import { useToasts } from 'react-toast-notifications'

import { USER_TYPES } from '../../../constants'
import { ReactComponent as Close } from '../../../assets/icons/close.svg'
import { ReactComponent as Send } from '../../../assets/icons/envelope.svg'
import { CardWrapper, Table, Button } from '../../ui'
import AddEditUserModal from './addEditUserModal'
import DeleteUserModal from './deleteUserModal'
import ResendInviteModal from './resendInviteModal'
import useCompanies from '../../../hooks/useCompanies'
import { ButtonRow, ArchiveButton } from '../ui'

const HEADERS = ['Name', 'User Type', 'Company', 'Role/Title', 'Email', '']

const getAdminUsersData = () => axios.get('/dashboard/admin?type=users')
const APIdeleteUser = ({ user, hard = false }) => {
  const query = hard ? '?hard_delete=1' : ''
  return axios.post(`/user/delete/${user.id}${query}`)
}
const APIactivateUser = user => axios.post(`/user/${user.id}/restore`)
const APIresendInvite = ({ user }) => {
  return axios.post(`/user/resend-invite/${user.id}`)
}

const APIupdateUser = values => {
  const formData = new FormData()
  Object.entries(values).forEach(([k, v]) => formData.append(k, v ?? ''))
  return axios.post(`/user/update/${values.id}`, formData)
}
const APIcreateUser = values => {
  if (values.user_type.value) values.user_type = values.user_type.value

  const formData = new FormData()
  Object.entries(values).forEach(([k, v]) => formData.append(k, v ?? ''))
  return axios.post('/user/invite', formData)
}

const AdminUsers = () => {
  const { addToast } = useToasts()

  const [selectedUser, setSelectedUser] = useState(null)
  const [openModal, setOpenModal] = useState(null)
  const { addCompany } = useCompanies()

  const queryCache = useQueryCache()
  const queryKey = 'admin-users'
  const { data } = useQuery(queryKey, getAdminUsersData)

  const [deleteUser] = useMutation(APIdeleteUser, {
    onSuccess: (_, { hard }) => {
      queryCache.invalidateQueries(queryKey)
      const notification = hard ? 'User permanently deleted' : 'User archvied'
      addToast(notification)
      closeModal()
    },
    onError: res => addToast(res.error, { appearance: 'error' }),
  })

  const [activateUser] = useMutation(APIactivateUser, {
    onSuccess: () => {
      addToast(`User restored`)
      queryCache.invalidateQueries(queryKey)
    },
    onError: res => addToast(res.error, { appearance: 'error' }),
  })

  const [resendInvite] = useMutation(APIresendInvite, {
    onSuccess: () => {
      addToast(`Invite resent`)
      closeModal()
    },
    onError: res => addToast(res.error, { appearance: 'error' }),
  })

  const [updateUser] = useMutation(APIupdateUser, {
    onSuccess: () => {
      addToast(`User updated`)
      queryCache.invalidateQueries(queryKey)
      closeModal()
    },
    onError: e => {
      const errors = e?.response?.data?.errors || {
        generic: ['Unable to update user'],
      }
      Object.values(errors).forEach(errors =>
        errors.forEach(err => addToast(err, { appearance: 'error' })),
      )
    },
  })

  const [createUser] = useMutation(APIcreateUser, {
    onSuccess: () => {
      addToast(`User created`)
      queryCache.invalidateQueries(queryKey)
      closeModal()
    },
    onError: ({ response }) => {
      addToast(response.data.error, { appearance: 'error' })
    },
  })

  const closeModal = () => {
    setOpenModal(null)
    setTimeout(() => setSelectedUser(null), 200)
  }

  const handleUpdateUser = async values => {
    const { company, ...user } = values
    let companyId = company.value

    // check if company is new user input
    if (values.company.__isNew__) {
      const res = await addCompany({ name: values.company.label })
      if (!res) return
      companyId = res.data.id
    }
    updateUser({ ...user, company_id: companyId })
  }

  const handleCreateUser = async values => {
    const { company, ...user } = values
    let companyId = company.value

    // check if company is new user input
    if (values.company.__isNew__) {
      const res = await addCompany({ name: values.company.label })
      if (!res) return
      companyId = res.data.id
    }
    createUser({ ...user, company_id: companyId })
  }

  const active = (data?.data?.users || [])
    .map(user => ({
      id: user.id,
      name: user.name,
      email: user.email,
      phone: user.phone,
      user_type: USER_TYPES[user.user_group] || user.user_type,
      company: user.company,
      company_id: user.company_id,
      role_title: user.role_title,
      pending: user.pending,
    }))
    .sort((a, b) => a.name.localeCompare(b.name))

  const archived = (data?.data?.archived_users || [])
    .map(user => ({
      id: user.id,
      name: user.name,
      email: user.email,
      phone: user.phone,
      user_type: USER_TYPES[user.user_group] || user.user_type,
      company: user.company,
      company_id: user.company_id,
      role_title: user.role_title,
    }))
    .sort((a, b) => a.name.localeCompare(b.name))

  return (
    <CardWrapper>
      <AddEditUserModal
        isActive={openModal === 'add/create-user'}
        onSubmit={handleCreateUser}
        onClose={closeModal}
      />
      <AddEditUserModal
        isActive={openModal === 'add/edit-user'}
        user={selectedUser}
        onSubmit={handleUpdateUser}
        onClose={closeModal}
      />
      <DeleteUserModal
        isActive={openModal === 'archive-user'}
        user={selectedUser}
        onSubmit={async () => deleteUser({ user: selectedUser })}
        onClose={closeModal}
      />
      <ResendInviteModal
        isActive={openModal === 'resend-invite'}
        user={selectedUser}
        onSubmit={async () => resendInvite({ user: selectedUser })}
        onClose={closeModal}
      />

      <Table
        heading={'Users'}
        headers={HEADERS}
        buttonLabel={'Add new user'}
        buttonOnClick={() => setOpenModal('add/create-user')}
        inCard
      >
        {active.map(user => (
          <tr key={user.id}>
            <td data-header={HEADERS[0]}>{user.name}</td>
            <td data-header={HEADERS[1]}>
              {user.user_type === 'cost_manager'
                ? 'Cost Manager'
                : user.user_type === 'designer'
                ? 'Designer'
                : user.user_type === 'contract_manager'
                ? 'Contract Manager'
                : user.user_type === 'client_entity'
                ? 'Client'
                : user.user_type === 'admin'
                ? 'Admin'
                : 'Unknown'}
            </td>
            <td data-header={HEADERS[2]}>{user.company}</td>
            <td data-header={HEADERS[3]}>{user.role_title}</td>
            <td data-header={HEADERS[4]}>{user.email}</td>
            <td data-header={HEADERS[5]}>
              <ButtonRow>
                <Button
                  small
                  onClick={() => {
                    setSelectedUser(user)
                    setOpenModal('add/edit-user')
                  }}
                >
                  Edit
                </Button>
                <ArchiveButton
                  small
                  onClick={() => {
                    setSelectedUser(user)
                    setOpenModal('archive-user')
                  }}
                  disabled={user.user_type === 'admin'}
                  title={`Archive ${user.name}`}
                  icon
                >
                  <Close height="24px" aria-label={`Archive ${user.name}`} />
                </ArchiveButton>

                <ArchiveButton
                  small
                  onClick={() => {
                    setSelectedUser(user)
                    setOpenModal('resend-invite')
                  }}
                  disabled={!user.pending}
                  icon
                  title={`Resend Invite to ${user.name}`}
                >
                  <Send
                    height="24px"
                    color="black"
                    aria-label={`Resend invite to ${user.name}`}
                  />
                </ArchiveButton>
              </ButtonRow>
            </td>
          </tr>
        ))}
      </Table>

      <Table heading={'Archived Users'} headers={HEADERS} inCard>
        {archived.map(user => (
          <tr key={user.id}>
            <td data-header={HEADERS[0]}>{user.name}</td>
            <td data-header={HEADERS[1]}>
              {user.user_type === 'cost_manager'
                ? 'Cost Manager'
                : user.user_type === 'designer'
                ? 'Designer'
                : user.user_type === 'contract_manager'
                ? 'Contract Manager'
                : user.user_type === 'client_entity'
                ? 'Client'
                : user.user_type === 'admin'
                ? 'Admin'
                : 'Unknown'}
            </td>
            <td data-header={HEADERS[2]}>{user.company}</td>
            <td data-header={HEADERS[3]}>{user.role_title}</td>
            <td data-header={HEADERS[4]}>{user.email}</td>
            <td data-header={HEADERS[5]}>
              <ButtonRow>
                <Button small onClick={() => activateUser(user)}>
                  Activate
                </Button>
                {user.user_type !== 'admin' ? (
                  <Button
                    small
                    onClick={() => {
                      setSelectedUser(user)
                      setOpenModal('delete-user')
                    }}
                    style={{ background: 'darkred' }}
                  >
                    Delete
                  </Button>
                ) : null}
              </ButtonRow>
            </td>
          </tr>
        ))}
      </Table>
    </CardWrapper>
  )
}

export default AdminUsers
