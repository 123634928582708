import React from 'react'
import styled from 'styled-components'

import { FormikSelect } from '../../form'
import { TIMEFRAME, TIMEFRAME_10 } from '../../../constants'

const FormGroup = styled.div`
  background: #fff;
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: ${p => p.theme.shadow};

  h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 31px;
  }

  ${p => p.theme.layout.web} {
    padding: 2rem;
  }
`

const Grid = styled.div`
  display: grid;
  grid-gap: 0 49px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-template-rows: repeat(auto-fit);

  h3 {
    grid-column: 1 / -1;
  }
`

const CrTimeframe = () => {
  return (
    <div>
      <FormGroup>
        <h2>5 - Change Request Timeframe Targets</h2>

        <Grid>
          <FormikSelect
            options={TIMEFRAME_10}
            name="change_request.budget.time"
            label="Change Request Budgets by Cost Manager"
          />
          <FormikSelect
            options={TIMEFRAME}
            name="change_request.claimed.time"
            label="Claimed Costs by General Contractor"
          />
          <FormikSelect
            options={TIMEFRAME}
            name="change_request.assessment.time"
            label="Cost Assessments by Cost Manager"
          />
        </Grid>
      </FormGroup>
    </div>
  )
}

export default CrTimeframe
