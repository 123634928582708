import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Card } from '..'
import { Button } from '../button'
import PDFButton from '../PDFButton'

export const TableHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 1.5rem;

  ${p => p.theme.layout.web} {
    padding: 0;
  }
`

export const TableHeading = styled.h2`
  color: #000000;
  font-size: ${p => (p.large ? '21px' : '16px')};
  letter-spacing: 0.44px;
  line-height: 19px;
  text-transform: uppercase;
  margin-bottom: 1rem;
`

const CollapsingTable = styled.table`
  display: block;
  color: ${p => p.theme.color.text};
  width: 100%;
  border-collapse: collapse;

  thead {
    position: absolute;
    opacity: 0;
  }
  tbody,
  tr {
    display: block;
  }
  td {
    display: flex;
    align-items: center;
    &::before {
      content: attr(data-header);
      width: fit-content;
      min-width: 75px;
      display: inline-block;
      padding-right: 1em;
    }
  }
  ${p => p.theme.layout.web} {
    display: table;
    thead {
      position: static;
      opacity: 1;
    }
    tbody {
      display: table-row-group;
    }
    tr {
      display: table-row;
    }
    th,
    td {
      text-align: end;
      &:first-of-type {
        text-align: start;
      }
    }
    td {
      display: table-cell;
      &::before {
        display: none;
      }
    }
  }
`

const SimpleTable = styled(CollapsingTable)`
  color: ${p => p.theme.color.text};

  td {
    background: white;
    /* mobile */
    min-height: 54px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0;
    margin: 0 1rem;
    border-bottom: 1px solid #c5c5c5;
    &:first-of-type {
      background: #f5f5f5;
      padding: 0 1rem;
      margin: 0;
      border: none;
    }
    &:last-of-type {
      border: none;
    }
    button {
      width: 100%;
      flex: 1;
    }
  }
  ${p => p.theme.layout.web} {
    th {
      font-size: 13px;
      letter-spacing: 0;
      padding: 10px 1rem;
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
    tr {
      border-bottom: 1px solid #c5c5c5;
    }
    td {
      background: white;
      border-bottom: none;
      height: 54px;
      padding: 0 1rem;
      &:first-of-type {
        background: white;
        padding-left: 0;
      }
      &:last-of-type {
        background: white;
        padding-right: 0;
      }
      button {
        width: inherit;
        flex: inherit;
      }
    }
  }
`

const FormTable = styled(CollapsingTable)`
  margin: 0;
  border: 1px solid ${p => p.theme.color.text};
  letter-spacing: 0;

  thead {
    border-bottom: 1px solid ${p => p.theme.color.text};
    height: 60px;
  }

  th {
    height: 54px;
    color: ${p => p.theme.color.text};
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
    padding: 0 0.5rem;
    white-space: nowrap;
  }
  td {
    height: 54px;
    font-size: 14px;
    line-height: 14px;
    padding: 0.5rem;
    &:first-of-type {
      background: #f8f9f9;
    }

    &:not(:first-of-type) {
      border-bottom: 1px solid #c5c5c5;
    }

    &::before {
      display: flex;
      align-items: center;
      padding-left: 0.5rem;
      margin-right: 0.5rem;
    }
    &:focus-within {
      box-shadow: inset 0 0 0 2px ${p => p.theme.color.focus};
    }
  }
  ${p => p.theme.layout.web} {
    thead {
      background: #f8f9f9;
    }
    tr:last-of-type td {
      border-bottom: none;
    }
    td {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      &::before {
        display: none;
      }
      &:first-of-type {
        background: white;
        border-bottom: 1px solid #c5c5c5;
      }
      &:not(:last-of-type) {
        border-right: 1px solid #c5c5c5;
      }
    }
  }
`

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`

// optional component for dynamic styling
export const Td = styled.td`
  font-weight: ${p => (p.$isBold ? 'bold' : 'normal')};
  word-break: ${p => (p.$email ? 'break-word' : 'normal')};
  ${p => p.theme.layout.web} {
    min-width: ${p => (p.$email ? '150px' : 'unset')};
  }
`

export const Table = ({
  table,
  inCard,
  heading,
  headers,
  children,
  buttonLabel,
  buttonOnClick,
  actions,
  pdfData,
  pdfFile,
  project,
  ...props
}) => {
  const TableStyle = table ? FormTable : SimpleTable
  const content = (
    <>
      {buttonLabel ? (
        <TableHeadWrapper>
          <TableHeading>{heading}</TableHeading>
          <Button onClick={buttonOnClick}>{buttonLabel}</Button>
        </TableHeadWrapper>
      ) : actions ? (
        <TableHeadWrapper>
          <TableHeading>{heading}</TableHeading>
          <Actions>{actions}</Actions>
        </TableHeadWrapper>
      ) : pdfData ? (
        <TableHeadWrapper style={{ marginBottom: '1.5rem' }}>
          <TableHeading style={{ marginBottom: '0' }}>{heading}</TableHeading>
          <PDFButton pdfRaw={pdfData} pdfFile={pdfFile} project={project} />
        </TableHeadWrapper>
      ) : heading ? (
        <TableHeading>{heading}</TableHeading>
      ) : null}
      <TableStyle {...props}>
        <thead>
          <tr>
            {headers.map((head, i) => (
              <th key={i}>{head}</th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </TableStyle>
    </>
  )

  return inCard ? <Card>{content}</Card> : content
}

Table.defaultProps = {
  heading: '',
  children: null,
  inCard: false,
  table: false,
  buttonLabel: null,
  buttonOnClick: () => {},
  actions: null,
  pdfData: null,
  pdfFile: null,
  project: null,
}
Table.propTypes = {
  heading: PropTypes.string,
  headers: PropTypes.array.isRequired,
  children: PropTypes.node,
  inCard: PropTypes.bool,
  table: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonOnClick: PropTypes.func,
  actions: PropTypes.node,
  pdfData: PropTypes.array,
  pdfFile: PropTypes.string,
  project: PropTypes.object,
}
