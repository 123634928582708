import React from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, message: '' }
  }

  static getDerivedStateFromError(
    error, //errorInfo
  ) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, message: error.message }
  }

  // componentDidCatch(error, errorInfo) {
  // console.log({ error, errorInfo })

  // You can also log the error to an error reporting service
  // logErrorToMyService(error, errorInfo)
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          {this.props.fallback}
          {this.props.showMessage ? <div>{this.state.message}</div> : null}
        </>
      )
    }

    return this.props?.children
  }
}

ErrorBoundary.defaultProps = {
  showMessage: false,
  fallback: 'An error occurred.',
}
ErrorBoundary.propTypes = {
  showMessage: PropTypes.bool,
  fallback: PropTypes.node,
}
export default ErrorBoundary
