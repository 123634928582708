//
import React from 'react'
import PropTypes from 'prop-types'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'
import { Radar } from 'react-chartjs-2'
import styled from 'styled-components'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
)

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(100vw - 4rem);
  margin-bottom: 2rem;
  min-height: 300px;

  ${p => p.theme.layout.web} {
    max-width: calc(100% - 1px);
    min-height: 475px;
  }
`

const RiskAllowance = props => {
  const formattedData = props.data.map(d => {
    if (d.label.match(/design dev/i))
      return {
        ...d,
        label: 'Design',
      }
    if (d.label.match(/employer cr/i))
      return {
        ...d,
        label: 'Employer Change',
      }
    return d
  })
  const labels = formattedData.map(d => d.label)

  const data = {
    labels,
    datasets: [
      {
        label: 'Risk Allowance',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgb(255, 99, 132)',
        pointBackgroundColor: 'rgb(255, 99, 132)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)',
        data: formattedData.map(d => Number(d.risk).toFixed(2)),
      },
      {
        label: 'Actual Change',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgb(54, 162, 235)',
        pointBackgroundColor: 'rgb(54, 162, 235)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(54, 162, 235)',
        data: props.data.map(d => Number(d.change).toFixed(2)),
      },
    ],
  }

  return (
    <ChartWrapper>
      <Radar
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          animations: false,
          animation: {
            onComplete: ({ chart }) => props.onChartIsReady(chart),
          },
          plugins: {
            legend: {
              onClick: () => {},
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || ''
                  if (context.parsed.r !== null) {
                    return new Intl.NumberFormat('en-GB', {
                      style: 'currency',
                      currency: 'GBP',
                      maximumFractionDigits: 0,
                    }).format(context.parsed.r)
                  }
                  return label
                },
              },
            },
          },
        }}
      />
    </ChartWrapper>
  )
}

RiskAllowance.propTypes = {
  onChartIsReady: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      risk: PropTypes.string.isRequired,
      change: PropTypes.number.isRequired,
    }),
  ).isRequired,
}

export default RiskAllowance
