import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'

import api from '../services'

const TOAST_ERROR = { appearance: 'error' }

const INITIAL_STATE = {
  isFetching: false,
  data: null,
  next: null,
  total: null,
  errors: null,
  isFetchingMore: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'fetch start':
      return { ...state, isFetching: true }
    case 'fetch success':
      return { ...INITIAL_STATE, ...action.payload }
    case 'fetch more start':
      return { ...state, isFetchingMore: true }
    case 'fetch more success':
      return {
        ...INITIAL_STATE,
        next: action.payload.next,
        total: action.payload.total,
        data: [...state.data, ...action.payload.data],
      }
    case 'set errors':
      return { ...state, errors: action.payload, isFetching: false }
    default:
      return state
  }
}

const usePaginatedFetch = (url, defaultError) => {
  const { addToast } = useToasts()
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const { errors, next, ...rest } = state

  useEffect(() => {
    if (errors && errors.length) {
      errors.forEach(error => addToast(error, TOAST_ERROR))
      dispatch({ type: 'set errors', payload: null })
    }
  }, [errors, addToast])

  const fetch = React.useCallback(async () => {
    const source = axios.CancelToken.source()

    dispatch({ type: 'fetch start' })
    const res = await api.fetch(url, defaultError, source)

    if (res.error) dispatch({ type: 'set errors', payload: res.errors })
    else {
      const { data, links, meta } = res.data
      dispatch({
        type: 'fetch success',
        payload: {
          data,
          next: links.next,
          total: meta.total,
        },
      })
    }
    return () => source.cancel()
  }, [url, defaultError])

  useEffect(() => {
    fetch()
  }, [fetch])

  const fetchMore = async () => {
    dispatch({ type: 'fetch more start' })
    const res = await api.fetch(next, defaultError)

    if (res.error) dispatch({ type: 'set errors', payload: res.errors })
    else {
      const { data, links, meta } = res.data
      dispatch({
        type: 'fetch more success',
        payload: {
          data,
          next: links.next,
          total: meta.total,
        },
      })
    }
  }

  return {
    ...rest,
    fetchMore: next && fetchMore,
    refetch: fetch,
  }
}

usePaginatedFetch.propTypes = {
  url: PropTypes.string.isRequired,
}

export default usePaginatedFetch
