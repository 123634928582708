import React from 'react'
import { usePaginatedFetch } from '../../../hooks'

const useGetChangeLog = (id, filter) => {
  const [changeList, setChangeList] = React.useState([])
  const query = filter ? `?filter=${filter}` : ''
  const url = `/${id}/change-requests${query}`
  const { data, isFetching, fetchMore } = usePaginatedFetch(
    url,
    'Unable to fetch change logs',
  )

  React.useEffect(() => {
    setChangeList(data)
  }, [data])

  const formattedData =
    changeList &&
    changeList.map(change => ({
      id: change.id,
      description: change.short_description,
      constituent: change.cost_constituent,
      cor: change.cr_no,
      ref: change.originator_ref,
      created: change.date_raised,
      cost: change.cost,
      status: change.status,
      instruction: change.instruction_no,
    }))

  return { changelogs: formattedData || [], isFetching, fetchMore }
}

export default useGetChangeLog
