import axios from 'axios'

import { formatError } from './utils'

export const login = async ({ email, password }) => {
  try {
    const res = await axios.post('login', {
      email,
      password,
    })

    if (res.status === 200) {
      const { user, token } = res.data
      if (!user || !token) {
        return { error: true, errors: ['Error logging in'] }
      }
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      localStorage.setItem('auth', token)
      return { error: false, user }
    }
  } catch (e) {
    return formatError(e, 'Error logging in')
  }
}

export const reauth = async token => {
  try {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    const res = await axios.get('reauth')

    if (res.status === 200) {
      const { user, token } = res.data
      localStorage.setItem('auth', token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      return { error: false, user }
    } else {
      // clear the existing token
      axios.defaults.headers.common['Authorization'] = null
      localStorage.removeItem('auth')
      return { error: true, errors: ['Error authenticating'] }
    }
  } catch (e) {
    localStorage.removeItem('auth')
    return formatError(e, ['Please log in again'])
  }
}

export const logout = async () => {
  try {
    const res = await axios.get('logout')

    if (res.status === 200) {
      axios.defaults.headers.common['Authorization'] = null
      localStorage.removeItem('auth')
      return { error: false }
    } else throw new Error()
  } catch (e) {
    return { error: true, errors: ['Unable to logout'] }
  }
}

export const sendResetEmail = async email => {
  try {
    const res = await axios.post('send-password-reset', { email })

    if (res.status === 200) {
      return { error: false }
    } else throw new Error()
  } catch (e) {
    return formatError(e, ['Unable to send email'])
  }
}

export const updatePassword = async (
  reset_token,
  password,
  confirm_password,
) => {
  try {
    const res = await axios.post('change-password', {
      reset_token,
      password,
      confirm_password,
    })

    if (res.status === 200) {
      return { error: false }
    } else throw new Error()
  } catch (e) {
    return formatError(e, ['Unable to update password'])
  }
}

export const checkResetToken = async reset_token => {
  try {
    const res = await axios.get(`check-reset-token/${reset_token}`)
    if (res.status === 200) {
      return { error: false }
    } else throw new Error()
  } catch (e) {
    return formatError(e, ['Unable to update password'])
  }
}

export const signup = async data => {
  try {
    const res = await axios({
      method: 'post',
      url: 'user/signup',
      data: data,
    })
    if (res.status === 200 || res.status === 201)
      return { error: false, data: res.data }
    else throw Error
  } catch (e) {
    return formatError(e, 'Claimed could not be added')
  }
}
