import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import { Table, Td, Button, Modal } from '../../ui'
import { ReactComponent as Close } from '../../../assets/icons/cross.svg'
import {
  Form,
  FormikInput,
  FormikCombo,
  FormikSelect,
  Search,
} from '../../form'
import { USER_TYPE_OPTIONS } from '../../../constants'
import useCompanies from '../../../hooks/useCompanies'

const USER_TYPES = {
  project_manager: 'contract_manager',
  cost_manager: 'cost_manager',
  project_member: 'designer',
  admin: 'admin',
}
const USER_TYPES_FRIENDLY = {
  project_manager: 'Contract Manager',
  cost_manager: 'Cost Manager',
  project_member: 'Designer',
  admin: 'Admin',
  client: 'Client',
}

const FormGroup = styled.div`
  background: #fff;
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: ${p => p.theme.shadow};

  h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 31px;
  }

  ${p => p.theme.layout.web} {
    padding: 2rem;
  }
`

const ScrollableContainer = styled.div`
  overflow: scroll;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 0.5rem 2rem;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-template-rows: repeat(auto-fit);

  h3 {
    grid-column: 1 / -1;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  }
`

const Info = styled.h5`
  margin-bottom: 0.5rem;
`
const ButtonRemove = styled(Button)`
  background: #f0f0f0;
  border-radius: 100%;
  flex: unset !important;
  width: 36px !important;
  margin-left: auto;
`

const TEAM_HEADERS = [
  'Name',
  'User Type',
  'Company',
  'Role Title',
  'Email',
  'Phone',
  '',
]
const ERROR_TOAST = { appearance: 'error' }

const team_user_options = USER_TYPE_OPTIONS.filter(
  type => type.value !== 'client',
)

const formSchema = Yup.object().shape({
  user_type: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  company: Yup.string().required('Required'),
  email: Yup.string()
    .default('')
    .email('Please enter a valid email address')
    .required('Required'),
  role_title: Yup.string(),
  phone: Yup.string(),
})

const ProjectTeam = ({ newTeam, existingTeam, setField, projectId }) => {
  const [showModal, setShowModal] = React.useState(false)
  const { companies, addCompany } = useCompanies()
  const { addToast } = useToasts()

  const addMember = data => {
    const newMember = {
      ...data,
      user_type: USER_TYPES[data.user_type],
      user_type_friendly: USER_TYPES_FRIENDLY[data.user_type],
    }
    setField('team', [...newTeam, newMember])
    setShowModal(false)
  }

  const createNewUser = async data => {
    const isNewEmailDuplicate = newTeam.find(
      member => member.email.trim() === data.email.trim(),
    )
    if (isNewEmailDuplicate)
      return addToast(
        'You have already created a new member with that email address',
        ERROR_TOAST,
      )

    const res = await axios.get('user/search-email', {
      params: { email: data.email },
    })
    if (res.status !== 200)
      return addToast('There was an error adding user', ERROR_TOAST)

    const emailExists = res.data?.email_exists
    if (emailExists)
      return addToast(
        'A user with that email address already exists',
        ERROR_TOAST,
      )

    const { company, ...user } = data
    // company.value is either an id or user input depending on company.__isNew__
    let company_id
    const isNewCompany = company.__isNew__

    if (isNewCompany) {
      const res = await addCompany({ name: company.value })
      if (res.error) return false
      company_id = res.data.id
    } else company_id = company.value

    addMember({ ...user, company_id, company: company.label })
  }

  const removeRow = async (id, existing) => {
    if (!existing) {
      // just remove from formik data
      const idx = id
      setField('team', [...newTeam.slice(0, idx), ...newTeam.slice(idx + 1)])
    } else {
      const url = `/project/${projectId}/project_team/${id}`
      const res = await axios.delete(url)
      if (res.status === 200) {
        setField(
          'existingTeam',
          existingTeam.filter(user => user.id !== id),
        )
      }
    }
    addToast('User removed from project')
  }

  return (
    <FormGroup>
      <h2>6 - Project Team</h2>
      <Info>Remember to save the project after adding new users</Info>

      <Grid style={{ gridGap: '0.5rem 2rem', marginBottom: '1.5rem' }}>
        <Search
          placeholder="Search Users"
          selectedResult={data =>
            addMember({ ...data, user_type: data.user_group })
          }
        />

        <Button small type="button" onClick={() => setShowModal(true)}>
          Invite New User
        </Button>
      </Grid>

      <Modal
        isActive={showModal}
        onClose={() => setShowModal(false)}
        title="Invite New User"
      >
        <Formik
          validationSchema={formSchema}
          onSubmit={createNewUser}
          initialValues={formSchema.default()}
        >
          <Form>
            <Grid>
              <FormikSelect
                name="user_type"
                label="User Type"
                options={team_user_options}
              />
              <FormikInput name="role_title" label="Job Title" />
              <FormikCombo
                name="company"
                label="Company"
                options={companies
                  .map(company => ({ value: company.id, label: company.name }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
              />{' '}
              <FormikInput name="name" label="Name" />
              <FormikInput name="email" label="Email" />
              <FormikInput name="phone" label="Phone" />
            </Grid>
            <Button small type="submit" style={{ marginLeft: 'auto' }}>
              Add user
            </Button>
          </Form>
        </Formik>
      </Modal>

      <ScrollableContainer>
        <Table headers={TEAM_HEADERS}>
          {newTeam.map((member, idx) => (
            <tr key={idx}>
              <Td data-header={TEAM_HEADERS[0]}>{member.name}</Td>
              <Td data-header={TEAM_HEADERS[1]}>{member.user_type_friendly}</Td>
              <Td data-header={TEAM_HEADERS[2]}>{member.company}</Td>
              <Td data-header={TEAM_HEADERS[3]}>{member.role_title}</Td>
              <Td $email data-header={TEAM_HEADERS[4]}>
                {member.email}
              </Td>
              <Td data-header={TEAM_HEADERS[5]}>{member.phone || ''}</Td>
              <Td data-header={TEAM_HEADERS[6]}>
                <ButtonRemove small icon onClick={() => removeRow(idx)}>
                  <Close height="10px" />
                </ButtonRemove>
              </Td>
            </tr>
          ))}
          {existingTeam.map(member => (
            <tr key={member.id}>
              <Td data-header={TEAM_HEADERS[0]}>{member.name}</Td>
              <Td data-header={TEAM_HEADERS[1]}>{member.user_type}</Td>
              <Td data-header={TEAM_HEADERS[2]}>{member.company}</Td>
              <Td data-header={TEAM_HEADERS[3]}>{member.role_title}</Td>
              <Td $email data-header={TEAM_HEADERS[4]}>
                {member.email}
              </Td>
              <Td data-header={TEAM_HEADERS[5]}>{member.phone || ''}</Td>
              <Td data-header={TEAM_HEADERS[6]}>
                <ButtonRemove
                  small
                  icon
                  onClick={() => removeRow(member.id, true)}
                >
                  <Close height="10px" />
                </ButtonRemove>
              </Td>
            </tr>
          ))}
        </Table>
      </ScrollableContainer>
    </FormGroup>
  )
}

export default ProjectTeam

ProjectTeam.defaultProps = {
  newTeam: [],
  existingTeam: [],
  projectId: null,
}

ProjectTeam.propTypes = {
  newTeam: PropTypes.array,
  existingTeam: PropTypes.array,
  setField: PropTypes.func.isRequired,
  projectId: PropTypes.any,
}
