import React from 'react'
import PropTypes from 'prop-types'
import { useToasts } from 'react-toast-notifications'

import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg'
import { Button } from '../../../../ui'
import { Input, EditIcon, Cell } from './ui'
import { getShortDate, htmlFormat } from '../../../../../utilities'

const ClaimedCostDateCell = ({ date, update, canEdit, isSubmitting }) => {
  const inputRef = React.useRef()
  const { addToast } = useToasts()
  const [edit, setEdit] = React.useState(false)
  const [inputValue, setInputValue] = React.useState()

  React.useEffect(() => {
    if (date) setInputValue(htmlFormat(date))
    else setInputValue(htmlFormat(new Date()))
  }, [date])

  React.useEffect(() => {
    if (edit && inputRef.current) inputRef.current.focus()
  }, [edit])

  const startEdit = () => setEdit(true)

  const cancelEdit = () => setEdit(false)

  const saveClaimedCost = async () => {
    const date = getShortDate(inputValue)
    if (!date) {
      return addToast('Must be a valid date', { appearance: 'error' })
    }

    const success = await update({ date_gc_claimed_cost: date })
    if (success) setEdit(false)
  }

  return edit ? (
    <Cell>
      <Input
        ref={inputRef}
        type="date"
        placeholder="yyyy-mm-dd"
        value={inputValue}
        onChange={e => {
          const { value } = e.target
          setInputValue(value)
        }}
      />
      <Button small onClick={saveClaimedCost} disabled={isSubmitting}>
        Save
      </Button>
      <Button small icon onClick={cancelEdit} disabled={isSubmitting}>
        <CloseIcon style={{ width: '24px' }} />
      </Button>
    </Cell>
  ) : date ? (
    <Cell>
      {getShortDate(date)}
      {canEdit && (
        <Button small icon onClick={startEdit} disabled={isSubmitting}>
          <EditIcon />
        </Button>
      )}
    </Cell>
  ) : canEdit ? (
    <Button small onClick={startEdit} disabled={isSubmitting}>
      Add Claimed Cost Date
    </Button>
  ) : null
}

ClaimedCostDateCell.defaultProps = {
  date: '',
  canEdit: false,
}

ClaimedCostDateCell.propTypes = {
  date: PropTypes.string,
  update: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
}

export default ClaimedCostDateCell
