import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

import { DateInputWithLabel } from '.'

export const FormikDateInput = props => {
  const [{ onChange, ...field }, meta, form] = useField(props)
  return (
    <DateInputWithLabel
      {...field}
      {...props}
      onChange={form.setValue}
      errorText={meta.touched ? meta.error : ''}
    />
  )
}

FormikDateInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
