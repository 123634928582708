import React from 'react'
import PropTypes from 'prop-types'
import { Font, Text, View, StyleSheet } from '@react-pdf/renderer'

import { formatCurrency } from '../../utilities'

import AkkRgWoff from '../../assets/fonts/AkkRg_Pro_1.woff'
import AkkBdWoff from '../../assets/fonts/AkkBd_Pro_1.woff'
import AkkItWoff from '../../assets/fonts/AkkIt_Pro_1.woff'

Font.register({
  family: 'Akkurat',
  fonts: [
    { src: AkkBdWoff },
    { src: AkkRgWoff, fontWeight: 600 },
    { src: AkkItWoff, fontWeight: 700 },
  ],
})

const row = {
  width: '100%',
  padding: '4px 0',
  fontSize: 9,
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderStyle: 'solid',
  borderColor: '#c5c5c5',
  borderWidth: 1,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderTopWidth: 0,
}
// Create styles
const styles = StyleSheet.create({
  section: {
    width: '100%',
  },
  table: {
    root: {
      width: '100%',
      display: 'table',
      fontSize: 10,
    },
    head: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '0 0 5px',
      borderStyle: 'solid',
      borderColor: '#c5c5c5',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
    },
    row,
    rowBold: {
      ...row,
      fontFamily: 'Helvetica-Bold',
      fontWeight: 700,
    },
    tableCol1: {
      flex: 1,
    },
    tableCol2: {
      flex: 5,
    },
    tableCol3: {
      flex: 2,
    },
    green: {
      color: '#20CA14',
    },
    red: {
      color: '#FF073A',
    },
  },
})

const ChangesByValuePdfPage = ({ data, currency: projectCurrency }) => {
  const currency = projectCurrency === 'eur' ? '€(EUR)' : '£(GBP)'

  return (
    <View style={styles.section}>
      <View style={styles.table.root}>
        <View style={styles.table.head}>
          <Text style={styles.table.tableCol1}>CR</Text>
          <Text style={styles.table.tableCol2}>Description</Text>
          <Text style={styles.table.tableCol3}>Value {currency}</Text>
        </View>
        <View>
          {data
            ? data.map(d => {
                return (
                  <React.Fragment key={d.id}>
                    <View style={styles.table.row}>
                      <Text style={styles.table.tableCol1}>{d.cr}</Text>
                      <Text style={styles.table.tableCol2}>{d.desc}</Text>
                      <Text style={styles.table.tableCol3}>
                        <Text>
                          {formatCurrency(d.value, { showSign: false })}
                        </Text>
                      </Text>
                    </View>
                  </React.Fragment>
                )
              })
            : null}
        </View>
      </View>
    </View>
  )
}

ChangesByValuePdfPage.defaultProps = {}

ChangesByValuePdfPage.propTypes = {
  currency: PropTypes.any.isRequired,
  data: PropTypes.any.isRequired,
}

export { ChangesByValuePdfPage }
