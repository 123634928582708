import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import RSelect from 'react-select'
import styled from 'styled-components'

const Select = styled(RSelect)`
  width: 100%;
  border: none;
  column-width: 300px;

  .table-select {
    &__control {
      border: none;
      box-shadow: none;
      padding-right: 0.25rem;
    }
    &__placeholder {
      visibility: hidden;
    }
    &__value-container {
      height: 1.5rem;
    }
    &__single-value {
      font-size: 14px;
      color: #575756;
    }
    &__indicator-separator {
      display: none;
    }
    &__indicator svg {
      fill: black;
    }
    &__menu {
      margin-top: 5px;
      z-index: 3;
      width: fit-content;
      min-width: max(98%, 150px);
    }
    &__option {
      padding: 0.5rem 1rem;
      cursor: pointer;
      white-space: nowrap;
      text-align: start;
    }
  }
  &.table-select--is-disabled {
    border-bottom: none;
    .table-select__control {
      background: none;
    }
    .table-select__indicator {
      display: none;
    }
    .table-select__single-value {
    }
  }
`

export const FormikTableSelect = ({ options, readonly, ...props }) => {
  const [field, , form] = useField(props)

  return (
    <Select
      type="select"
      value={options.find(opt => opt.value === field.value) || ''}
      classNamePrefix="table-select"
      options={options}
      onChange={opt => form.setValue(opt.value)}
      onBlur={() => form.setTouched(true)}
      isDisabled={readonly}
      theme={selectTheme => ({
        ...selectTheme,
        spacing: {
          controlHeight: '32px',
        },
        colors: {
          ...selectTheme.colors,
          primary: 'black',
          primary25: 'white',
          primary50: 'lightgrey',
        },
      })}
      {...props}
    />
  )
}

FormikTableSelect.defaultProps = {
  readonly: false,
}
FormikTableSelect.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
}
