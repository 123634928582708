import React from 'react'

import { AuthContext } from '../context/auth'

const useAuth = () => {
  const { errors, ...auth } = React.useContext(AuthContext)

  return auth
}

export default useAuth
