import * as Yup from 'yup'

export const changeRequestSchema = Yup.object().shape({
  cost_constituent: Yup.string()
    .default('')
    .label('Cost constituent')
    .required(),
  files: Yup.array().default([]).label('Files'),
  change: Yup.string().default('').label('Change').required(),
  reason: Yup.string().default('').label('Reason for change').required(),
  additional_comments: Yup.string().default('').label('Additional comments'),

  short_description: Yup.string()
    .default('')
    .max(30)
    .label('Short description')
    .nullable(),
  implications: Yup.boolean().default(false).label('Implications'),
  extension: Yup.string()
    .default('')
    .when('implications', {
      is: false,
      then: Yup.string().default('').label('Anticipated delay'),
    }),
  matter: Yup.string()
    .default('')
    .when('implications', {
      is: false,
      then: Yup.string().default('').label('Anticipated matter'),
    }),
  comments: Yup.string().default('').label('Additional comments'),

  costs_approved: Yup.boolean().default(false),
  change_origin: Yup.string().default('').label('Change origin').nullable(),
  cost_breakdown: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      cost_category: Yup.string().default('').required(),
      description: Yup.string().default('').required(),
      unit: Yup.string().default('').required(),
      rate: Yup.number().default(0).required(),
      quantity: Yup.number().default(0).required(),
      total: Yup.number().default(0).required(),
    }),
  ),
})
