import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'
import { useMutation } from 'react-query'
import axios from 'axios'

import { Loader, View, ViewHeader, Card, Button } from '../ui'
import { Form, FormikInput, InputWithLabel } from '../form'
import { AuthContext } from '../../context/auth'

const FormCard = styled(Card)`
  margin: 0;
  margin-bottom: 1rem;
  padding: 1rem;
  ${p => p.theme.layout.web} {
    display: grid;
    column-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin-bottom: 2rem;
  }
`

const FormButtons = styled.div`
  display: flex;
  justify-content: space-between;
  ${p => p.theme.layout.web} {
    justify-content: flex-end;
  }
`

const Btn = styled(Button)`
  margin-right: ${p => (p.mr ? p.mr : 0)};
  width: 100%;
  ${p => p.theme.layout.web} {
    width: 150px;
  }
`

const PasswordInput = styled(FormikInput)`
  grid-column: 1;
`

const APIupdateUser = values => {
  const formData = new FormData()
  const { password, confirm_password, ...user } = values
  Object.entries(user).forEach(([k, v]) => formData.append(k, v ?? ''))
  if (password) formData.append('password', password)
  return axios.post('/user/update/', formData)
}

const Account = () => {
  const { updateAccount, user } = React.useContext(AuthContext)
  const { addToast } = useToasts()
  const [updateUser] = useMutation(APIupdateUser, {
    onSuccess: res => {
      updateAccount(res.data)
      addToast(`Account details updated`)
    },
    onError: ({ response }) => {
      response.data.errors.forEach(e => addToast(e, { appearance: 'error' }))
    },
  })

  return (
    <Loader isFetching={false}>
      <View>
        <ViewHeader>
          <h1>Edit Account</h1>
        </ViewHeader>
        <Formik
          initialValues={{
            name: user?.name || '',
            email: user?.email || '',
            location: user?.location || '',
            phone: user?.phone || '',
            password: '',
            confirm_password: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Name is required'),
            email: Yup.string()
              .email('Please enter a valid email address')
              .required('Email is required'),
            location: Yup.string(),
            phone: Yup.string().min(9, 'Too Short!').max(18, 'Too Long!'),
            password: Yup.string().min(7, 'Too Short!').max(50, 'Too Long!'),
            confirm_password: Yup.string().oneOf(
              [Yup.ref('password'), null],
              'Passwords must match',
            ),
          })}
          onSubmit={async (values, actions) => {
            const res = await updateUser(values)
            res && actions.resetForm()
          }}
        >
          <Form>
            <FormCard>
              <FormikInput name="name" label="Full name" />
              <FormikInput name="email" label="Email" />
              <FormikInput name="location" label="Location" />
              <FormikInput name="phone" label="Phone Number" />
              <InputWithLabel
                name="Company"
                label="Company"
                value={user?.company}
                readonly
              />
              <PasswordInput
                type="password"
                label="Password"
                name="password"
                autoComplete="new-password"
              />
              <FormikInput
                type="password"
                label="Confirm password"
                name="confirm_password"
                autoComplete="confirm-password"
              />
            </FormCard>

            <FormButtons>
              <Btn
                type="button"
                mr="1rem"
                onClick={() => window.history.back()}
              >
                Cancel
              </Btn>
              <Btn type="submit">Save</Btn>
            </FormButtons>
          </Form>
        </Formik>
      </View>
    </Loader>
  )
}

export default Account
