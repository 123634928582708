import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { ReactComponent as NoteIcon } from '../../../../assets/icons/note.svg'
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg'
import { Card, CardHeading, Button, Modal } from '../../../ui'
import { Form, FormikInput, FormikText } from '../../../form'
import { getShortDate, getInitials } from '../../../../utilities'

//#region styles
const TimelineWrapper = styled.div`
  position: relative;
  padding: 1rem;
  margin-left: 1rem;

  ${p => p.theme.layout.web} {
    margin-left: 2rem;
    padding: 1rem 0;
    min-width: 350px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background: lightgrey;
    ${p => p.theme.layout.web} {
      left: -2rem;
    }
  }
`

const TimelineItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 1.5rem 1.25rem;
  background: white;
  box-shadow: ${p => p.theme.shadow};
  &:not(:last-of-type) {
    margin-bottom: 1rem;
    ${p => p.theme.layout.web} {
      margin-bottom: 2rem;
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
    border: 2px solid black;
    left: -1rem;
    top: 50%;
    transform: translate(-50%, -50%);
    ${p => p.theme.layout.web} {
      left: -2rem;
    }
  }
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background 150ms ease-in-out;
  height: 38px;
  width: 38px;
  background: lightgrey;
  margin-right: 20px;
  svg {
    width: 16px;
  }
`

const Initials = styled.span`
  color: #000000;
  font-size: 14px;
  letter-spacing: 0.5px;
`

const Content = styled.div`
  flex: 1;
`

const Uppercase = styled.span`
  text-transform: capitalize;
`
const Details = styled.div`
  margin-top: 1rem;
  border-top: 1px solid #c5c5c5;
  ${p => p.theme.layout.web} {
    margin-top: 1.5rem;
    padding-top: 0.5rem;
  }
`

const Heading = styled.h5`
  color: ${p => p.theme.color.text};
  font-size: 13px;
  letter-spacing: 0;
  margin-top: 1rem;
`

const Text = styled.p`
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 0.5rem;
`
//#endregion

const MSG_TYPES = {
  note: 'Note',
  sent: 'Sent',
  assessed_cost_updated: 'Assessed Cost updated',
  approved: 'Approved',
  instruction: 'Instruction issued',
}

const Timeline = ({ events, createNote }) => {
  const [showNoteModal, setShowNoteModal] = React.useState(false)

  const submitNote = async values => {
    const success = await createNote(values.title, values.desc)
    if (success) setShowNoteModal(false)
  }

  return (
    <Card>
      <CardHeading>
        <h1>Event Timeline</h1>
        <Button small type="button" onClick={() => setShowNoteModal(true)}>
          New Note
        </Button>
      </CardHeading>

      <Modal
        isActive={showNoteModal}
        onClose={() => setShowNoteModal(false)}
        width="500px"
        title="New Note"
      >
        <Formik
          initialValues={{
            title: '',
            desc: '',
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required(),
            desc: Yup.string().required(),
          })}
          onSubmit={submitNote}
        >
          <Form>
            <FormikInput name="title" label="Title" />
            <FormikText name="desc" label="Description" rows={4} />
            <Button small type="submit">
              Save Note
            </Button>
          </Form>
        </Formik>
      </Modal>

      <TimelineWrapper>
        {events.map(change => (
          <TimelineItem key={change.id}>
            <Icon>
              {change.type === 'note' || change.type === 'created' ? (
                <NoteIcon />
              ) : change.type === 'approval' ? (
                <CheckIcon />
              ) : (
                <Initials>{getInitials(change.user) || ''}</Initials>
              )}
            </Icon>
            <Content>
              <h4>
                {MSG_TYPES[change.type] || change.type} by{' '}
                <Uppercase>{change.user}</Uppercase> on{' '}
                {getShortDate(change.date)}
              </h4>
              <Details>
                {change.note?.title ? (
                  <>
                    <Heading>Title</Heading>
                    <Text>{change.note.title}</Text>{' '}
                  </>
                ) : null}
                {change.note?.text ? (
                  <>
                    <Heading>Description</Heading>
                    <Text>{change.note.text}</Text>{' '}
                  </>
                ) : null}
              </Details>
            </Content>
          </TimelineItem>
        ))}
      </TimelineWrapper>
    </Card>
  )
}

Timeline.defaultProps = {
  events: [],
}

Timeline.propTypes = {
  createNote: PropTypes.func.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      cr_id: PropTypes.number,
      date: PropTypes.string,
      type: PropTypes.string,
      note: PropTypes.shape({
        title: PropTypes.string,
        text: PropTypes.string,
      }),
      user: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
}
export default Timeline
