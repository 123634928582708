import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormikInput } from '../../form'

const Header = styled.h2`
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 2rem;
`

const Grid = styled.div`
  display: grid;
  grid-gap: 0 49px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
`

export const ClientBudget = ({ budget, area }) => {
  const getRate = (cost, area) => {
    const rate = parseFloat(cost.replace(/,/g, '')) / area
    const formattedRate =
      Number.isNaN(rate) || Math.abs(rate) === Infinity ? '-' : rate.toFixed(2)
    return formattedRate
  }

  const rate = getRate(budget, area)

  return (
    <>
      <Header>3 - Client Budget</Header>

      <Grid>
        <FormikInput
          name="budget.total"
          label="Total Approved Budget"
          currency
        />
        <FormikInput name="rate" label="(m2)" value={rate} readonly />
      </Grid>

      {/* <Grid>
        <FormikInput
          name="budget.additional.total"
          label="Budget Additional Total"
          currency
        />
      </Grid> */}
    </>
  )
}

ClientBudget.propTypes = {
  budget: PropTypes.any.isRequired,
  area: PropTypes.any.isRequired,
}
