import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import styled from 'styled-components'

import { Form, FormikInput, FormikText } from '../../form'
import { Button, Modal } from '../../ui'

const Grid = styled.div`
  display: grid;
  grid-gap: 0 2rem;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-template-rows: repeat(auto-fit);

  h3 {
    grid-column: 1 / -1;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  }
`

const AddUserModal = ({ isActive, onSubmit, onClose, company }) => {
  const isEdit = !!company

  return (
    <Modal
      isActive={isActive}
      onClose={onClose}
      title={isEdit ? 'Edit company details' : 'Create Company'}
    >
      <Formik
        initialValues={{
          id: company?.id || '',
          name: company?.name || '',
          reference_prefix: company?.ref || '',
          address: company?.address || '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Name is required'),
          reference_prefix: Yup.string().max(3),
          address: Yup.string(),
        })}
        onSubmit={onSubmit}
      >
        <Form>
          <Grid>
            <FormikInput name="name" label="Company Name" />
            <FormikInput name="reference_prefix" label="Ref" />
            <FormikText name="address" label="Address" rows={4} />
          </Grid>
          <Button small type="submit" style={{ marginLeft: 'auto' }}>
            {isEdit ? 'Update company' : 'Create company'}
          </Button>
        </Form>
      </Formik>
    </Modal>
  )
}

AddUserModal.defaultProps = {
  company: null,
}
AddUserModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number,
    ref: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
  }),
}

export default AddUserModal
