import React from 'react'
import { useToasts } from 'react-toast-notifications'
import { useNavigate } from 'react-router'

import API from '../services'

export const AuthContext = React.createContext()

const INITAL_STATE = {
  isAuthenticating: false,
  isAuthenticated: null,
  errors: null,
  user: null,
}

const authReducer = (state, action) => {
  switch (action.type) {
    case 'login':
      return {
        ...state,
        isAuthenticating: true,
        isAuthenticated: null,
        errors: null,
        user: null,
      }
    case 'login success': {
      const user = action.payload.user_group
      const isAdmin = user === 'admin'
      const isProjectManager = user === 'project_manager' || isAdmin
      const isCostManager = user === 'cost_manager' || isAdmin
      const isProjectMember = user === 'project_member' || isAdmin
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        errors: null,
        user: {
          ...action.payload,
          isAdmin,
          isProjectManager,
          isCostManager,
          isProjectMember,
        },
      }
    }
    case 'login fail':
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        errors: action.payload,
        user: null,
      }
    case 'logout success':
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        errors: null,
        user: null,
      }
    case 'logout fail':
      return {
        ...state,
        errors: action.payload,
      }
    case 'clear errors':
      return {
        ...state,
        errors: null,
      }
    case 'send reset-email fail':
    case 'update password fail':
    case 'bad reset token':
      return {
        ...INITAL_STATE,
        errors: action.payload,
      }
    default:
      return state
  }
}

const AuthProvider = props => {
  const [state, dispatch] = React.useReducer(authReducer, INITAL_STATE)
  const { addToast } = useToasts()
  const navigate = useNavigate()

  // intial mount
  React.useEffect(() => {
    const reauth = async token => {
      dispatch({ type: 'login' })
      const res = await API.reauth(token)
      if (!res.error) dispatch({ type: 'login success', payload: res.user })
      else dispatch({ type: 'login fail', payload: res.errors })
    }
    const access_token = localStorage.getItem('auth')
    if (access_token) reauth(access_token)
  }, [])

  // Toast if there are errors
  React.useEffect(() => {
    if (state.errors) {
      state.errors.forEach(error => addToast(error, { appearance: 'error' }))
      dispatch({ type: 'clear errors' })
    }
  }, [state.errors, addToast])

  const login = async credentials => {
    dispatch({ type: 'login' })
    const res = await API.login(credentials)
    if (!res.error) dispatch({ type: 'login success', payload: res.user })
    else dispatch({ type: 'login fail', payload: res.errors })
  }

  const logout = async () => {
    const res = await API.logout()
    if (!res.error) dispatch({ type: 'logout success' })
    else dispatch({ type: 'logout fail', payload: res.errors })
  }

  const sendResetEmail = async email => {
    const res = await API.sendResetEmail(email)
    if (!res.error) addToast('Please check your emails')
    else dispatch({ type: 'send reset-email fail', payload: res.errors })
  }

  const updatePassword = async (hash, password, confirm) => {
    const res = await API.updatePassword(hash, password, confirm)
    if (!res.error) {
      addToast('Your password has been updated. Please log in.', {
        autoDismiss: true,
      })
      navigate('/')
    } else dispatch({ type: 'update password fail', payload: res.errors })
  }

  const checkResetToken = React.useMemo(
    () => async hash => {
      const res = await API.checkResetToken(hash)
      if (res.error) dispatch({ type: 'bad reset token', payload: res.errors })
    },
    [],
  )

  const updateAccount = async data => {
    dispatch({ type: 'login success', payload: data })
  }

  const signup = async data => {
    const res = await API.signup(data)
    if (res.error) {
      dispatch({ type: 'bad reset token', payload: res.errors })
      return false
    } else return true
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        sendResetEmail,
        updatePassword,
        checkResetToken,
        updateAccount,
        signup,
      }}
      {...props}
    />
  )
}

export default AuthProvider
