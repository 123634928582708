import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RSelect from 'react-select'

import { Label, Error, InputWrapper, Row } from '../'

export const SSelect = styled(RSelect)`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;

  .react-select {
    &__control {
      border: none;
      box-shadow: none;
      padding-right: 0.25rem;
    }
    &__placeholder {
      visibility: hidden;
    }
    &__value-container {
      height: 1.5rem;
    }
    &__single-value {
      font-size: 14px;
      transform: translateY(-25%);
    }
    &__indicator-separator {
      display: none;
    }
    &__indicator svg {
      fill: black;
    }
    &__menu {
      margin-top: 5px;
      z-index: 3;
    }
    &__option {
      padding: 0.5rem 1rem;
      cursor: pointer;
    }
  }
  &.react-select--is-disabled {
    border-bottom-color: transparent;
    .react-select__control {
      background: none;
    }
    .react-select__indicator svg {
      fill: #575756;
    }
  }
`
const Note = styled.span`
  text-transform: uppercase;
  font-style: italic;
  font-size: 13px;
  letter-spacing: 1.5px;
  color: ${p => p.theme.color.grey};
  line-height: 1.54;
`

export const Select = ({ label, name, errorText, note, ...props }) => {
  const [hasUserInput, setHasUserInput] = React.useState(false)

  const isFilled = props.value || hasUserInput

  return (
    <div className={props.className}>
      {note ? (
        <Row>
          <Note>{note}</Note>
        </Row>
      ) : null}
      <InputWrapper
        hasError={!!errorText}
        className={isFilled ? 'isFilled' : ''}
      >
        <Label htmlFor={name} style={{ maxWidth: 'calc(100% - 2rem)' }}>
          {label}
        </Label>
        <SSelect
          {...props}
          inputId={name}
          onInputChange={input => setHasUserInput(!!input)}
          classNamePrefix="react-select"
          isDisabled={props.readonly}
          theme={selectTheme => ({
            ...selectTheme,
            spacing: {
              controlHeight: '32px',
            },
            colors: {
              ...selectTheme.colors,
              primary: 'black',
              primary25: 'lightgrey',
              primary50: 'lightgrey',
            },
          })}
        />
      </InputWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

Select.defaultProps = {
  errorText: null,
  placeholder: 'Please select...',
  note: null,
  className: '',
  value: null,
  readonly: false,
  label: '',
}

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  note: PropTypes.string,
  errorText: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any,
  readonly: PropTypes.bool,
}
