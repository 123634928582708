import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Label, Error, Row } from '../'

const TextareaWrapper = styled.div.attrs(props => ({
  className: props.hasError ? 'has-error' : '',
}))`
  position: relative;
  display: flex;
  padding-top: 1.75rem;
  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    border: 1px solid transparent;
    border-bottom: 2px solid
      ${p => (p.hasError ? p.theme.color.error : 'transparent')};
    transition: border-color 150ms ease-in-out;
  }

  label {
    top: 0.5rem;
    height: 14px;
  }

  textarea {
    outline: none;
    &::placeholder {
      visibility: hidden;
      opacity: 0;
    }
  }

  &:focus-within {
    textarea {
      border: 1px solid ${p => p.theme.color.focus};
    }
  }
`

const Textarea = styled.textarea`
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid ${p => p.theme.color.border};
  border-radius: 0;
  color: ${p => p.theme.color.text};
  appearance: none;
  resize: none;
  outline: none;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;

  &:disabled {
    background: none;
    border-color: ${p => p.theme.color.text};
  }
`

const Pre = styled.pre`
  white-space: pre-wrap;
`

export const TextareaWithLabel = ({
  className,
  label,
  name,
  errorText,
  ...props
}) => {
  return (
    <div className={className}>
      <TextareaWrapper hasError={!!errorText}>
        <Row>
          <Label htmlFor={name}>{label}</Label>
        </Row>
        {props.readonly ? (
          <Pre>{props.value}</Pre>
        ) : (
          <Textarea {...props} id={name} disabled={props.readonly} spellCheck />
        )}
      </TextareaWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

TextareaWithLabel.defaultProps = {
  errorText: null,
  className: '',
  value: null,
  readonly: false,
}

TextareaWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  readonly: PropTypes.bool,
}
