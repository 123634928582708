import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, Modal } from '../../ui'

const Text = styled.p`
  margin-bottom: 0.5rem;
`
const Heading = styled.span`
  display: inline-block;
  width: 80px;
`
const Warning = styled.p`
  text-transform: uppercase;
  color: darkred;
  margin: 1rem 0;
`

const DeleteProjectModal = ({ isActive, onSubmit, onClose, project }) => {
  return (
    <Modal isActive={isActive} onClose={onClose} title="Delete project">
      <Text>Are you sure you want to delete this project?</Text>
      <p>
        <Heading>Name:</Heading> {project?.project_name}
      </p>
      <Warning>This is permanent and cannot be reversed.</Warning>
      <Button small onClick={onSubmit} style={{ marginTop: '2rem' }}>
        Confirm
      </Button>
    </Modal>
  )
}

DeleteProjectModal.defaultProps = {
  project: null,
}
DeleteProjectModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  project: PropTypes.shape({
    project_name: PropTypes.string,
  }),
}

export default DeleteProjectModal
