import React from 'react'
import PropTypes from 'prop-types'

import { LoadTransition } from '../../ui'
import useFetch from '../../../hooks/useFetch'
import CategoryCosts from '../../analytics/categoryCosts'

export const CategoryCostsLoader = ({ id, save }) => {
  const categoryCosts = useFetch(
    `/project/${id}/costsByCategory`,
    'Unable to fetch changes by category costs data',
  )

  return (
    <LoadTransition isFetching={categoryCosts.isFetching}>
      {categoryCosts.data && categoryCosts.data.length ? (
        <CategoryCosts
          onChartIsReady={save('categoryCosts')}
          data={categoryCosts.data.map(d => ({
            label: d.cost_category,
            total: Number(d.category_total),
            cumulativePercent: d.percentage * 100,
          }))}
        />
      ) : (
        <div>No data to display</div>
      )}
    </LoadTransition>
  )
}
CategoryCostsLoader.propTypes = {
  id: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
}
