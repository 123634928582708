import { format, parseISO } from 'date-fns'

const getShortMonth = (year, month) => {
  try {
    return format(new Date(year, month - 1), 'MMM')
  } catch (e) {
    return 'Invalid date'
  }
}

const getShortDate = date => {
  try {
    return format(parseISO(date), 'dd/MM/YYY')
  } catch (e) {
    return undefined
  }
}

const htmlFormat = date => {
  try {
    return format(parseISO(date), 'YYY-MM-dd')
  } catch (e) {
    return ''
  }
}

export { getShortMonth, getShortDate, htmlFormat }
