import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { AuthContext } from '../../context/auth'

import { ReactComponent as logo } from '../../assets/logos/stature-vertical.svg'
import { ReactComponent as DashIcon } from '../../assets/icons/dashboard.svg'
import { ReactComponent as ProjectsIcon } from '../../assets/icons/projects.svg'
// import { ReactComponent as FaqsIcon } from '../../assets/icons/faqs.svg'
import { ReactComponent as AdminIcon } from '../../assets/icons/admin.svg'

//#region styles
const Nav = styled.nav`
  --header-height: ${p => p.theme.layout.header_height};
  --nav-height-mobile: ${p => p.theme.layout.nav_height_mobile};
  --nav-width-web: ${p => p.theme.layout.nav_width_web};

  /* defaults */
  display: flex;
  background: white;
  z-index: 2;

  /* mobile */
  justify-content: space-between;

  /*  web */
  ${p => p.theme.layout.web} {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const StyledLink = styled(NavLink)`
  display: none;
  ${p => p.theme.layout.web} {
    display: block;
    margin: 1rem auto 90px 1rem;
  }
`

const Logo = styled(logo)`
  height: 280px;
  width: 28px;
  margin: 1rem;
`

const SLink = styled(NavLink)`
  /* defaults */
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
  transition: background 150ms;
  &:hover {
    background: #eee;
  }

  /* mobile */
  flex-direction: column;
  flex: 1;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  padding: 0 13px;
  justify-content: center;

  /* web */
  ${p => p.theme.layout.web} {
    flex-direction: row;
    flex: unset;
    font-size: 13px;
    line-height: 16px;
    border-top: none !important;
    justify-content: flex-start;
    min-height: 48px;
    padding: 0 2rem;
  }

  &.nav--active {
    background: ${p => p.theme.color.primary};
    color: white;
    &:hover {
      background: #333;
    }
    svg {
      fill: white;
      stroke: white;
    }
  }
`
const LinkText = styled.span`
  display: block;
`

const IconWrapper = styled.div`
  svg {
    height: 21px;
    width: 21px;
    stroke: ${p => p.theme.color.primary};
    fill: ${p => p.theme.color.primary};
  }
  margin-bottom: 0.25rem;
  ${p => p.theme.layout.web} {
    display: none;
  }
`
//#endregion

const links = [
  {
    route: '/',
    name: 'Dashboard',
    icon: DashIcon,
    match: loc => loc === '/',
  },
  {
    route: '/projects',
    name: 'Your Projects',
    icon: ProjectsIcon,
    match: loc => loc.includes('/projects') && !loc.includes('admin'),
  },
  // {
  //   route: '/faqs',
  //   name: 'Faqs',
  //   icon: FaqsIcon,
  //   match: loc => loc.includes('/faqs'),
  // },
]

const NavContainer = props => {
  const loc = useLocation()
  const { user } = React.useContext(AuthContext)

  return (
    <Nav {...props}>
      <StyledLink to="/">
        <Logo />
      </StyledLink>
      {user ? (
        <>
          {links.map(({ route, name, icon: Icon, match }) => (
            <SLink
              key={name}
              to={route}
              title={name}
              aria-label={name}
              className={match(loc.pathname) ? 'nav--active' : ''}
            >
              <IconWrapper>
                <Icon />
              </IconWrapper>
              <LinkText>{name}</LinkText>
            </SLink>
          ))}
          {user.isAdmin ? (
            <SLink
              to="/admin"
              title="Admin"
              aria-label="Admin"
              className={loc.pathname.includes('/admin') ? 'nav--active' : ''}
            >
              <IconWrapper>
                <AdminIcon />
              </IconWrapper>
              <LinkText>Admin</LinkText>
            </SLink>
          ) : null}
        </>
      ) : null}
    </Nav>
  )
}

NavContainer.propTypes = {}
export default NavContainer
