import React from 'react'
import styled from 'styled-components'
import { Routes, Route, NavLink, useLocation } from 'react-router-dom'

import { View } from '../ui'
import Projects from './projects'
import Users from './users'
import Companies from './companies'

const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  ${p => p.theme.layout.web} {
    flex-direction: row;
  }
`

const TabLink = styled(NavLink)`
  min-height: 68px;
  display: flex;
  align-items: center;
  width: fit-content;
  text-decoration: none;
  text-transform: uppercase;
  color: ${p => p.theme.color.text};
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin: 0 1rem;
  padding-top: 5px;
  border-bottom: 2px solid transparent;
  z-index: 1;
  transition: border-bottom-color 200ms, color 200ms;

  &:hover {
    border-bottom-color: #eee;
  }

  &.tab--active {
    color: ${p => p.theme.color.primary};
    border-bottom-color: ${p => p.theme.color.primary};
    &:hover {
      border-bottom-color: #555;
    }
  }

  ${p => p.theme.layout.web} {
    margin: 0 1rem 0 2rem;
  }
`

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding-right: 1rem;
  ${p => p.theme.layout.web} {
    padding-right: 2rem;
  }
`

const Admin = () => {
  const loc = useLocation()
  return (
    <>
      <FlexRow>
        <Nav>
          <TabLink
            to=""
            className={loc.pathname === '/admin' ? 'tab--active' : ''}
          >
            Projects
          </TabLink>
          <TabLink
            to="users"
            activeClassName="tab--active"
            className={loc.pathname.includes('users') ? 'tab--active' : ''}
          >
            Users
          </TabLink>
          <TabLink
            to="companies"
            activeClassName="tab--active"
            className={loc.pathname.includes('companies') ? 'tab--active' : ''}
          >
            Companies
          </TabLink>
        </Nav>
      </FlexRow>
      <View>
        <Routes>
          <Route path="/" element={<Projects />} />
          <Route path="/users" element={<Users />} />
          <Route path="/companies" element={<Companies />} />
        </Routes>
      </View>
    </>
  )
}

export default Admin
