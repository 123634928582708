import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Indicator = styled.span`
  margin: 0 10px 0 0;
  display: inline-block;
  width: 12px;
  height: 12px;
  background: ${p => p.color};
`
const StatusText = styled.span`
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
`

const getStatus = status => {
  const statuses = {
    draft: {
      label: 'Draft',
      color: '#CBCCCB',
    },
    pending: {
      label: 'Pending',
      color: '#EFDF10',
    },
    approved: {
      label: 'Approved',
      color: '#20CA14',
    },
    formalised: {
      label: 'Approved',
      color: '#20CA14',
    },
    rejected: {
      label: 'Rejected',
      color: '#FF073A',
    },
  }

  return statuses[status] || { label: 'Unknown', color: 'black' }
}

const Status = ({ status, ...props }) => {
  const s = getStatus(status)
  return status ? (
    <StatusWrapper {...props}>
      <Indicator color={s.color} />
      <StatusText>{s.label}</StatusText>
    </StatusWrapper>
  ) : null
}

Status.defaultProps = {
  status: undefined,
}
Status.propTypes = {
  status: PropTypes.string,
}

export default Status
