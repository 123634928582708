import React, { useState } from 'react'
import axios from 'axios'
import { useQuery, useQueryCache, useMutation } from 'react-query'
import { useToasts } from 'react-toast-notifications'

import { ReactComponent as Close } from '../../../assets/icons/close.svg'
import { CardWrapper, Table, Button } from '../../ui'
import AddEditCompanyModal from './addEditCompanyModal'
import DeleteCompanyModal from './deleteCompanyModal'
import { ButtonRow } from '../ui'

const HEADERS = ['Ref', 'Name', 'Address', 'Members', '']

const getAdminCompaniesData = async () =>
  axios.get('/dashboard/admin?type=companies')
const APIdeleteCompany = company => axios.post(`/company/${company.id}/delete`)
const APIupdateCompany = values => {
  const formData = new FormData()
  Object.entries(values).forEach(([k, v]) => formData.append(k, v ?? ''))
  return axios.post(`/company/${values.id}`, formData)
}
const APIcreateCompany = values => {
  const formData = new FormData()
  Object.entries(values).forEach(([k, v]) => formData.append(k, v ?? ''))
  return axios.post('/company', formData)
}

const AdminCompanies = () => {
  const { addToast } = useToasts()
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [openModal, setOpenModal] = useState(null)

  const queryCache = useQueryCache()
  const queryKey = 'admin-companies'
  const { data } = useQuery(queryKey, getAdminCompaniesData)

  const [deleteCompany] = useMutation(APIdeleteCompany, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey)
      const notification = 'Company deleted'
      addToast(notification)
      closeModal()
    },
    onError: res => {
      const notification =
        res?.response?.data?.error || 'Error deleting company'
      addToast(notification, { appearance: 'error' })
    },
  })

  const [updateCompany] = useMutation(APIupdateCompany, {
    onSuccess: () => {
      addToast(`Company updated`)
      queryCache.invalidateQueries(queryKey)
      closeModal()
    },
    onError: res =>
      res.errors.forEach(err => addToast(err, { appearance: 'error' })),
  })

  const [createCompany] = useMutation(APIcreateCompany, {
    onSuccess: () => {
      addToast(`Company created`)
      queryCache.invalidateQueries(queryKey)
      queryCache.invalidateQueries('companies')
      closeModal()
    },
    onError: res =>
      res.errors.forEach(err => addToast(err, { appearance: 'error' })),
  })

  const companies = (data?.data?.companies || [])
    .map(company => ({
      id: company.id,
      name: company.name,
      ref: company.reference_prefix,
      address: company.address,
      members: company.user_count,
    }))
    .sort((a, b) => a.name.localeCompare(b.name))

  const closeModal = () => {
    setOpenModal(null)
    setTimeout(() => setSelectedCompany(null), 200)
  }

  return (
    <CardWrapper>
      <AddEditCompanyModal
        isActive={openModal === 'add/create-company'}
        onSubmit={createCompany}
        onClose={closeModal}
      />
      <AddEditCompanyModal
        isActive={openModal === 'add/edit-company'}
        company={selectedCompany}
        onSubmit={updateCompany}
        onClose={closeModal}
      />
      <DeleteCompanyModal
        isActive={openModal === 'delete-company'}
        company={selectedCompany}
        onSubmit={() => deleteCompany(selectedCompany)}
        onClose={closeModal}
      />

      <Table
        heading={'Companies'}
        headers={HEADERS}
        buttonLabel={'Add new company'}
        buttonOnClick={() => setOpenModal('add/edit-company')}
        inCard
      >
        {companies.map(company => (
          <tr key={company.id}>
            <td data-header={HEADERS[0]}>{company.ref}</td>
            <td data-header={HEADERS[1]}>{company.name}</td>
            <td data-header={HEADERS[2]}>{company.address}</td>
            <td data-header={HEADERS[3]}>{company.members}</td>
            <td data-header={HEADERS[4]}>
              <ButtonRow>
                <Button
                  small
                  onClick={() => {
                    setSelectedCompany(company)
                    setOpenModal('add/edit-company')
                  }}
                >
                  Edit
                </Button>
                <Button
                  small
                  onClick={() => {
                    setSelectedCompany(company)
                    setOpenModal('delete-company')
                  }}
                  icon
                >
                  <Close height="24px" aria-label={`Delete ${company.name}`} />
                </Button>
              </ButtonRow>
            </td>
          </tr>
        ))}
      </Table>
    </CardWrapper>
  )
}

export default AdminCompanies
