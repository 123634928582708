import React from 'react'
import axios from 'axios'
import { useQuery, useQueryCache, useMutation } from 'react-query'
import { useToasts } from 'react-toast-notifications'

const getCompanies = () => axios.get('/company')

const APIaddCompany = async company => {
  return await axios.post('/company', company)
}
const useCompanies = () => {
  const queryCache = useQueryCache()
  const { addToast } = useToasts()

  const queryKey = 'companies'
  const { data, isError } = useQuery(queryKey, getCompanies)

  const [addCompany] = useMutation(APIaddCompany, {
    onSuccess: res => {
      queryCache.setQueryData(queryKey, current => ({
        ...current,
        data: [...current.data, res.data],
      }))
    },
    onError: () =>
      addToast('There was an error creating company', { appearance: 'error' }),
  })

  React.useEffect(() => {
    if (isError)
      addToast('There was an error fetching companies', { appearance: 'error' })
  }, [isError, addToast])

  const companies = data?.data || []

  return {
    companies,
    addCompany,
  }
}

export default useCompanies
