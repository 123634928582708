import { usePaginatedFetch } from '../../hooks'

const getCurrency = currencyString =>
  currencyString === 'gbp' ? '£' : currencyString === 'eur' ? '€' : ''

const useGetProjects = () => {
  const { data, isFetching, fetchMore } = usePaginatedFetch(
    '/projects',
    'Unable to fetch projects',
  )

  const formattedData =
    data &&
    data.map(project => ({
      id: project.id,
      currency: getCurrency(project.currency),
      name: project.project_name,
      total_cost: project.total_cost,
      changes: project.changes,
      changes_percent: project.changes_percentage,
      risk: project.remaining_risk,
      risk_percent: project.remaining_risk_percentage,
    }))

  return { projects: formattedData, isFetching, fetchMore }
}

export default useGetProjects
