import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FastField } from 'formik'

import { Label, Error, InputWrapper } from '../../../form'

const Input = styled(FastField)`
  font-size: 14px;
  height: 32px;
  width: 100%;
  padding: 0;
  border: none;
  border-bottom: 1px solid ${p => p.theme.color.border};
  appearance: none;
`

const FastInput = ({ className, label, errorText, name, ...props }) => {
  return (
    <div className={className}>
      <InputWrapper hasError={!!errorText} className={'isFilled'}>
        <Label htmlFor={name}>{label}</Label>
        <Input id={name} name={name} spellCheck {...props} />
      </InputWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

FastInput.defaultProps = {
  type: 'text',
  errorText: null,
  className: '',
}

FastInput.propTypes = {
  type: PropTypes.oneOf(['text', 'date', 'number', 'password', 'email', 'tel']),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  className: PropTypes.string,
}

export default FastInput
