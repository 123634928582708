import React from 'react'
import PropTypes from 'prop-types'
import { PDFDownloadLink } from '@react-pdf/renderer'
import styled, { css } from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import {
  TotalCostPDF,
  CostSummaryPDF,
  ChangeRiskAllowPDF,
  ChangeStatusPDF,
  ConstChangeCostPDF,
} from '../pdfs'
import { ReactComponent as Document } from '../../assets/icons/document.svg'

const style = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  font-size: 15px;
  padding: 11px 8px;
  color: black;
  max-width: 90px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  &:hover,
  &:focus {
    background: #eee;
  }
  svg {
    margin-right: 5px;
  }
`
const GeneratePDFButton = styled.div`
  ${style}
`
const StyledDownloadLink = styled(PDFDownloadLink)`
  ${style}
  text-decoration: none;
  max-width: 110px;
  &:hover,
  &:focus {
    background: #eee;
  }
`
const PDFButton = ({ pdfRaw, pdfFile, project }) => {
  const [isFetching, setIsFetching] = React.useState(false)
  const [pdfData, setPDFData] = React.useState(null)
  const [isReady, setIsReady] = React.useState(false)
  const { addToast } = useToasts()

  async function generatePDF() {
    if (!isFetching && !isReady) {
      setIsFetching(true)
      try {
        setPDFData(pdfRaw)
      } catch (e) {
        addToast(e.message || 'Error', { appearance: 'error' })
      }
    }
  }

  return pdfData ? (
    <StyledDownloadLink
      document={
        pdfFile === 'total_cost' ? (
          <TotalCostPDF data={pdfData} project={project} />
        ) : pdfFile === 'cost_summary' ? (
          <CostSummaryPDF data={pdfData} project={project} />
        ) : pdfFile === 'change_status' ? (
          <ChangeStatusPDF data={pdfData} project={project} />
        ) : pdfFile === 'changes_and_risk_allowance' ? (
          <ChangeRiskAllowPDF data={pdfData} project={project} />
        ) : pdfFile === 'construction_change_cost_status' ? (
          <ConstChangeCostPDF data={pdfData} project={project} />
        ) : (
          ''
        )
      }
      fileName={
        project?.title
          ? `${project.title.replace(/\s/g, '')}_${pdfFile}.pdf`
          : `${pdfFile}.pdf`
      }
      isReady={isReady}
    >
      {({ loading }) => {
        setIsReady(!loading)
        return loading ? (
          'Generating...'
        ) : (
          <>
            <Document />
            Download
          </>
        )
      }}
    </StyledDownloadLink>
  ) : (
    <GeneratePDFButton
      onClick={generatePDF}
      className="generate-pdf"
      disabled={isFetching}
    >
      {isFetching ? (
        'Fetching...'
      ) : (
        <>
          <Document />
          PDF
        </>
      )}
    </GeneratePDFButton>
  )
}

PDFButton.defaultProps = {
  project: null,
}

PDFButton.propTypes = {
  pdfRaw: PropTypes.array.isRequired,
  pdfFile: PropTypes.string.isRequired,
  project: PropTypes.object,
}
export default PDFButton
