const fraction = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
})

const noFraction = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 0,
})

const getSign = number => (number > 0 ? '+' : number < 0 ? '-' : '')

export const formatCurrency = (value, config = {}) => {
  try {
    const options = {
      showDecimals: false,
      showSign: true,
      symbol: '',
      ...config,
    }
    const parsed = parseFloat(String(value).replace(/,/g, ''))

    const isValid = parseFloat(parsed) % 1
    if (Number.isNaN(isValid))
      throw Error(`formatCurrency error: ${parsed} is not valid`)

    const val = Math.abs(parsed)

    const sign = options.showSign ? getSign(parsed) : ''
    const symbol = options.symbol ? ' ' + options.symbol : ''
    const output = options.showDecimals
      ? fraction.format(val)
      : noFraction.format(Math.round(val))

    return `${sign}${symbol}${output}`.trim()
  } catch (e) {
    return value
  }
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
