import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { COUNTRY_OPTIONS } from '../../../constants'
import { FormikInput, FormikSelect } from '../../form'

const AddressFields = styled.div`
  display: grid;
  grid-gap: 0 2rem;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  margin: 0 0 2rem 0;
`

const AddressHeader = styled.h3`
  grid-column: 1 / -1;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 9px;
`

export const Address = ({ title, namespace }) => {
  return (
    <AddressFields className={`address--${namespace}`}>
      <AddressHeader>{title}</AddressHeader>

      <FormikInput
        type="text"
        name={`${namespace}.address_1`}
        label="Address Line 1"
      />
      <FormikInput
        type="text"
        name={`${namespace}.address_2`}
        label="Address Line 2"
      />
      <FormikInput
        type="text"
        name={`${namespace}.address_3`}
        label="Address Line 3"
      />
      <FormikInput
        type="text"
        name={`${namespace}.address_towncity`}
        label="Town / City"
      />
      <FormikInput
        type="text"
        name={`${namespace}.address_region`}
        label="Region/County"
      />
      <FormikInput
        type="text"
        name={`${namespace}.address_postcode`}
        label="Postcode"
        capitalise={true}
      />
      <FormikSelect
        name={`${namespace}.address_country`}
        label="Country"
        options={COUNTRY_OPTIONS}
      />
    </AddressFields>
  )
}

Address.propTypes = {
  title: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired,
}
