import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { FormikInput, InputWithLabel } from '../../form'
import { Accordion } from '../../ui'
import OtherCosts from './other-costs'

//#region styles
const FormGroup = styled.div`
  background: #fff;
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: ${p => p.theme.shadow};

  button h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    color: #000000;

    span {
      font-weight: 400;
      color: #757575;
    }
  }

  ${p => p.theme.layout.web} {
    padding: 2rem;
  }
`

const Header = styled.h2`
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  margin-bottom: 2rem;
`

const Description = styled.p`
  color: #757575;
  font-size: 14px;
  line-height: 24px;
  max-width: 420px;

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`

const Grid = styled.div`
  display: grid;
  align-items: end;
  gap: 1rem 2rem;
  margin: 1rem 0;
  ${p => p.theme.layout.web} {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
`

const Gridheader = styled.h4`
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;
`

const Row = styled.div`
  display: grid;
  gap: 0 1rem;

  ${p => p.theme.layout.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`
//#endregion

//#region fields
// Static fields in 4A
const constructionCostFields = [
  { field: 'facilitating', label: 'Facilitating Works' },
  { field: 'substructure', label: 'Substructure' },
  { field: 'frame', label: 'Frame' },
  { field: 'floor', label: 'Upper Floors' },
  { field: 'roof', label: 'Roof' },
  { field: 'ramps', label: 'Stairs & Ramps' },
  { field: 'ex_walls', label: 'External Walls' },
  { field: 'windows', label: 'Windows & External Doors' },
  { field: 'internal', label: 'Internal Walls & Partitions' },
  { field: 'door', label: 'Internal Doors' },
  { field: 'wall', label: 'Wall Finishes' },
  { field: 'floor_finish', label: 'Floor Finishes' },
  { field: 'ceiling', label: 'Ceiling Finishes' },
  { field: 'fittings', label: 'Fittings, Furnishings & Equipment' },
  { field: 'health', label: 'Services - Public Health' },
  { field: 'mechanical', label: 'Services - Mechanical' },
  { field: 'electrical', label: 'Services - Electrical' },
  { field: 'other', label: 'Services - Other' },
  { field: 'ext_works', label: 'External Works' },
  { field: 'contractor', label: 'Main Contractor Preliminaries' },
  { field: 'main_cont', label: 'Main Contractor Oh&p' },
  { field: 'construct', label: 'Construction Contract Contingency' },
]

// Static fields in 4B
const projectCostFields = [
  { field: 'manager', label: 'Cost Manager' },
  { field: 'pmanager', label: 'Project Manager' },
  { field: 'contract_admin', label: 'Contract Administrator' },
  { field: 'employer', label: "Employer's Agent" },
  { field: 'architect', label: 'Architect' },
  { field: 'building', label: 'Building Services Engineer' },
  { field: 'structural', label: 'Structural Engineer' },
  { field: 'cdm', label: 'CDM Co-ordinator' },
  { field: 'interior', label: 'Interior Designer' },
  { field: 'landscape', label: 'Landscape Architect' },
  { field: 'infra', label: 'Infrastructure Engineer' },
  { field: 'drainage', label: 'Drainage Engineer' },
  { field: 'advisor', label: 'Construction / Logistics / Sequencing Advisor' },
]

// Static fields in 4C
const otherCostFields = [
  { field: 'acquisition', label: 'Land Acquisition Costs' },
  { field: 'finance', label: 'Employer Finance Costs' },
  { field: 'fees', label: 'Fees' },
  { field: 'charges', label: 'Charges' },
  { field: 'planning', label: 'Planning Contributions' },
  { field: 'insurance', label: 'Insurances' },
  { field: 'archaeological', label: 'Archaeological Fieldwork' },
  { field: 'specialist', label: 'Other Specialist Fieldwork' },
  { field: 'decanting', label: 'Decanting & Relocation Costs' },
  { field: 'equipment', label: 'Fittings, Furnishings & Equipment' },
  { field: 'tenant', label: "Tenant's Costs / Contributions" },
  { field: 'marketing', label: 'Marketing Costs' },
]

// Static fields in 4D
const riskCosts = [
  { field: 'design_dev', label: 'Design Development Risks' },
  { field: 'construction', label: 'Construction Risks' },
  { field: 'employer_cr', label: 'Employer Change Risks' },
  { field: 'employer_other', label: 'Employer Other Risks' },
]
//#endregion

const CostInformation = ({ area, costs, otherCosts }) => {
  const getCostRate = value => {
    if (!value) return '-'
    const cost = parseFloat(value.replace(/,/g, ''))
    const rate = cost / parseFloat(area)

    return rate && !Number.isNaN(rate) && rate !== Infinity
      ? rate.toFixed(0)
      : '-'
  }

  const getSectionTotal = (fields, others) => {
    const fieldCosts = fields.reduce((total, { field }) => {
      const parsedCost = parseFloat(costs[field].total.replace(/,/g, ''))
      if (Number.isNaN(parsedCost)) return total
      return total + parsedCost
    }, 0)

    const otherCosts = others.reduce((total, otherCost) => {
      const parsedCost = parseFloat(otherCost.total.replace(/,/g, ''))
      if (Number.isNaN(parsedCost)) return total
      return total + parsedCost
    }, 0)

    return fieldCosts + otherCosts
  }

  const getRisk = (fields, costs) => {
    return fields.reduce((total, { field }) => {
      const t = costs[field].total

      const cost = parseFloat(t.replace(/,/g, ''))
      if (Number.isNaN(cost)) return total
      return total + cost
    }, 0)
  }

  const getTotalRate = (cost, area) => {
    const rate = cost / area
    const formattedRate =
      Number.isNaN(rate) || Math.abs(rate) === Infinity ? '-' : rate.toFixed(0)
    return formattedRate
  }

  // get total costs for each section
  // 4a construction
  const totalConstructionCosts = getSectionTotal(
    constructionCostFields,
    otherCosts.construction,
  )
  const totalConstructionRate = getTotalRate(totalConstructionCosts, area)

  // 4b project
  const totalProjectFeesCosts = getSectionTotal(
    projectCostFields,
    otherCosts.project,
  )
  const totalProjectFeesRate = getTotalRate(totalProjectFeesCosts, area)

  // 4c other
  const totalOtherCosts = getSectionTotal(otherCostFields, otherCosts.other)
  const totalOtherCostsRate = getTotalRate(totalOtherCosts, area)

  // 4d risk
  const totalRisk = getRisk(riskCosts, costs)
  const totalRiskRate = getTotalRate(totalRisk, area)

  const totalCost =
    totalConstructionCosts + totalProjectFeesCosts + totalOtherCosts + totalRisk
  const totalCostRate = getTotalRate(totalCost, area)

  return (
    <FormGroup>
      <Header>4 - Starting Point Costs</Header>
      <Description>
        Starting Point Costs show the fixed cost position against all cost
        constituents at the time the main contract works are started.
      </Description>

      <div>
        <Accordion
          title="4A - Starting Costs: Construction"
          optional
          noMaxHeight
          noPadding
        >
          <div>
            Total Construction Costs:{' '}
            {totalConstructionCosts.toLocaleString('en-UK', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || ''}
          </div>
          <div>Construction Costs Rate: {totalConstructionRate} m2</div>
          <Grid>
            {constructionCostFields.map(cost => (
              <div key={cost.field}>
                <Gridheader>{cost.label}</Gridheader>
                <Row>
                  <FormikInput
                    name={`cost.${cost.field}.total`}
                    label="Total"
                    currency
                  />
                  <InputWithLabel
                    name={`${cost.field} rate`}
                    value={getCostRate(costs[cost.field]?.total)}
                    label="(m2 Rate)"
                    readonly
                  />
                </Row>
              </div>
            ))}
          </Grid>

          <OtherCosts field="construction" getRate={getCostRate} />
        </Accordion>

        <Accordion
          title="4B - Starting Costs: Project Fees"
          noPadding
          noMaxHeight
          optional
        >
          <div>
            Total Project Fees:{' '}
            {totalProjectFeesCosts.toLocaleString('en-UK', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || ''}
          </div>
          <div>Project Fees Rate: {totalProjectFeesRate} m2</div>
          <Grid>
            {projectCostFields.map(cost => (
              <div key={cost.field}>
                <Gridheader>{cost.label}</Gridheader>
                <Row>
                  <FormikInput
                    name={`cost.${cost.field}.total`}
                    label="Total"
                    currency
                  />
                  <FormikInput
                    name={`${cost.field} rate`}
                    value={getCostRate(costs[cost.field]?.total)}
                    label="(m2 Rate)"
                    readonly
                  />
                </Row>
              </div>
            ))}
          </Grid>

          <OtherCosts field="project" getRate={getCostRate} />
        </Accordion>

        <Accordion
          title="4C - Starting Costs: Other Development/Project Costs"
          noPadding
          noMaxHeight
          optional
        >
          <div>
            Total Other Costs:{' '}
            {totalOtherCosts.toLocaleString('en-UK', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || ''}
          </div>
          <div>Other Costs Rate: {totalOtherCostsRate} m2</div>
          <Grid>
            {otherCostFields.map(cost => (
              <div key={cost.field}>
                <Gridheader>{cost.label}</Gridheader>
                <Row>
                  <FormikInput
                    name={`cost.${cost.field}.total`}
                    label="Total"
                    currency
                  />
                  <FormikInput
                    name={`${cost.field} rate`}
                    value={getCostRate(costs[cost.field]?.total)}
                    label="(m2 Rate)"
                    readonly
                  />
                </Row>
              </div>
            ))}
          </Grid>

          <OtherCosts field="other" getRate={getCostRate} />
        </Accordion>

        <Accordion
          title="4D - Starting Costs: Risk Allowance"
          noPadding
          noMaxHeight
        >
          <Description>
            Your Risk Allowance suggests how much money can be spent on Change
            Requests before a client has is in overspend.
          </Description>
          <Description>
            Your Risk Allowance cannot be larger than the remaining budget after
            starting costs have been deducted.
          </Description>

          <div style={{ display: 'flex' }}>
            <FormikInput
              name="risk allowance total"
              label="Total Risk Allowance"
              value={
                totalRisk.toLocaleString('en-UK', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) || ''
              }
              readonly
            />
            <FormikInput
              name="risk allowance rate"
              label="(m2 Rate)"
              value={totalRiskRate}
              readonly
            />
          </div>

          <Grid>
            {riskCosts.map(risk => (
              <div key={risk.field}>
                <Gridheader>{risk.label}</Gridheader>
                <Row>
                  <FormikInput
                    name={`cost.${risk.field}.total`}
                    label="Total"
                    currency
                  />
                  <FormikInput
                    name={`${risk.field} rate`}
                    value={getCostRate(costs[risk.field]?.total)}
                    label="(m2 Rate)"
                    readonly
                  />
                </Row>
              </div>
            ))}
          </Grid>
        </Accordion>
      </div>

      <Grid style={{ marginBottom: '0' }}>
        <InputWithLabel
          name="total cost"
          label="Total Starting Cost"
          value={
            totalCost.toLocaleString('en-UK', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || ''
          }
          readonly
        />
        <InputWithLabel
          name="total cost rate"
          value={totalCostRate}
          label="m2"
          readonly
        />
      </Grid>
    </FormGroup>
  )
}

CostInformation.defaultProps = {
  area: undefined,
}
CostInformation.propTypes = {
  costs: PropTypes.shape({
    total: PropTypes.string,
  }).isRequired,
  otherCosts: PropTypes.shape({
    construction: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        total: PropTypes.string,
      }),
    ),
    project: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        total: PropTypes.string,
      }),
    ),
    other: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        total: PropTypes.string,
      }),
    ),
  }).isRequired,
  area: PropTypes.any,
}
export default CostInformation
