import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  View,
  // Loader, Button, ViewHeader
} from '../ui'
// import { contractType, measurementUnits } from '../dashboard/new/details'
import { COUNTRY_OPTIONS } from '../../constants'
import { formatCurrency } from '../../utilities'

const measurementUnits = {
  'area.gross_internal': 'Gross Internal Area (m2)',
  'area.net_internal': 'Net Internal Area (m2)',
}

const currencySymbol = {
  gbp: '£',
  eur: '€',
}

const contractType = {
  traditional: 'Traditional',
  design_and_build: 'Design and Build',
  gmp: 'GMP',
  management_contract: 'Management Contract',
  prime_cost: 'Prime Cost',
}

const costType = {
  // construction
  facilitating: 'Facilitating Works',
  substructure: 'Substructure',
  frame: 'Frame',
  floor: 'Upper Floors',
  roof: 'Roof',
  ramps: 'Stairs & Ramps',
  ex_walls: 'Exterior Walls',
  windows: 'Windows & External Doors',
  internal: 'Internal Walls & Partitions',
  door: 'Internal Doors',
  wall: 'Wall Finishes',
  floor_finish: 'Floor Finishes',
  ceiling: 'Ceiling Finishes',
  fittings: 'Fittings, Furnishings & Equipment',
  health: 'Services - Public Health',
  mechanical: 'Services - Mechanical',
  electrical: 'Services - Electrical',
  other: 'Services - Other',
  ext_works: 'External Works',
  contractor: 'Main Contractor Preliminaries',
  main_cont: 'Main Contractor Oh&p',
  construct: 'Construction Contract Contingency',
  // project
  manager: 'Cost Manager',
  pmanager: 'Project Manager',
  contract_admin: 'Contract Administrator',
  employer: "Employer's Agent",
  architect: 'Architect',
  building: 'Building Services Engineer',
  structural: 'Structural Engineer',
  cdm: 'CDM Co-ordinator',
  interior: 'Interior Designer',
  landscape: 'Landscape Architect',
  infra: 'Infrastructure Engineer',
  drainage: 'Drainage Engineer',
  advisor: 'Construction / Logistics / Sequencing Advisor',
  // other
  // project: 'Project',
  acquisition: 'Land Acquisition Costs',
  finance: 'Employer Finance Costs',
  fees: 'Fees',
  charges: 'Charges',
  planning: 'Planning Contributions',
  insurance: 'Insurances',
  archaeological: 'Archaeological Fieldwork',
  specialist: 'Other Specialist Fieldwork',
  decanting: 'Decanting & Relocation Costs',
  equipment: 'Fittings, Furnishings & Equipment',
  tenant: "Tenant's Costs / Contributions",
  marketing: 'Marketing Costs',
  // other_costs: 'Other Costs',
  // starting: 'Starting',
  //risks
  design_dev: 'Design Development Risks',
  construction: 'Construction Risks',
  employer_cr: 'Employer Change Risks',
  employer_other: 'Employer Other Risks',
}

//#region styles
const Card = styled.section`
  background: white;
  margin-top: 1rem;
  padding: 1rem;
  box-shadow: ${p => p.theme.shadow};
`
const Addresses = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
`

const H3 = styled.h3`
  margin-bottom: 1rem;
`
const H4 = styled.h4`
  font-weight: 600;
  margin-bottom: 0.5rem;
`
//#endregion

const getCountry = code =>
  (COUNTRY_OPTIONS.find(country => country.value === code) || { label: '-' })
    .label

const SettingsView = ({ settings: data }) => {
  if (!data) return <View />

  const {
    site,
    client,
    measurement,
    area,
    budget,
    currency,
    contract,
    change_request,
    risk,
    cost,
  } = data

  return (
    <View>
      <Addresses>
        <Card>
          <H3>Site Address</H3>
          {site.address_1 && <p>{site.address_1}</p>}
          {site.address_2 && <p>{site.address_2}</p>}
          {site.address_3 && <p>{site.address_3}</p>}
          {site.town_city && <p>{site.town_city}</p>}
          {site.address_postcode && <p>{site.address_postcode}</p>}
          {site.address_region && <p>{site.address_region}</p>}
          {site.address_country && <p>{getCountry(site.address_country)}</p>}
        </Card>
        <Card>
          <H3>Client Address</H3>
          {client.address_1 && <p>{client.address_1}</p>}
          {client.address_2 && <p>{client.address_2}</p>}
          {client.address_3 && <p>{client.address_3}</p>}
          {client.town_city && <p>{client.town_city}</p>}
          {client.address_postcode && <p>{client.address_postcode}</p>}
          {client.address_region && <p>{client.address_region}</p>}
          {client.address_country && (
            <p>{getCountry(client.address_country)}</p>
          )}
        </Card>
      </Addresses>

      <Card>
        <H3>Area </H3>
        <p style={{ marginBottom: '1rem' }}>
          Measurement: {measurementUnits[measurement.unit]}
        </p>

        <Grid>
          {area.gross_internal && <p>Gross Internal: {area.gross_internal}</p>}
          {area.net_internal && <p>Net Internal: {area.net_internal}</p>}
          {area.gross_external && <p>Gross External: {area.gross_external}</p>}
          {area.perimeters && <p>Perimeters: {area.perimeters}</p>}
          {area.typical_floor && (
            <p>Typical Floor to Ceiling Height (m): {area.typical_floor}</p>
          )}
        </Grid>
      </Card>

      <Card>
        <H3>Budget</H3>
        <Grid>
          <p>Total: {formatCurrency(budget.total || 0, { showSign: false })}</p>
          <p>Rate: {formatCurrency(budget.rate || 0, { showSign: false })}</p>
        </Grid>
      </Card>

      <Card>
        <H3>Currency</H3>
        <Grid>
          <p>Currency: {currencySymbol[currency.selected]}</p>
          {currency.exchange_rate && (
            <p>Exchange Rate: {currency.exchange_rate}</p>
          )}
        </Grid>
      </Card>

      <Card>
        <H3>Contract</H3>
        <Grid>
          <p>{contractType[contract.type]}</p>
        </Grid>
      </Card>

      <Card>
        <H3>Change Request</H3>
        <Grid>
          <p>Budget: {change_request.budget.time}</p>
          <p>Assessment: {change_request.assessment.time}</p>
          <p>Claimed: {change_request.claimed.time}</p>
        </Grid>
      </Card>

      <Card>
        <H3>Risk</H3>
        <Grid>
          <p>
            Total:{' '}
            {formatCurrency(risk.allowance.total || 0, { showSign: false })}
          </p>
        </Grid>
      </Card>

      <Card>
        <H3>Cost</H3>
        <Grid>
          {Object.entries(cost).map(([field, value]) => (
            <div key={field} style={{ marginBottom: '1rem' }}>
              <H4>{costType[field]}</H4>
              <p>
                Total: {formatCurrency(value.total || 0, { showSign: false })}
              </p>
            </div>
          ))}
        </Grid>
      </Card>
    </View>
  )
}

SettingsView.defaultProps = {
  settings: {},
}

SettingsView.propTypes = {
  settings: PropTypes.any,
}

export default SettingsView
