import React from 'react'
import PropTypes from 'prop-types'
import ProjectSettingsNoEdit from './settingsView'
import useGetProject from '../projectSettings/edit/useGetProject'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ErrorBoundary, Loader } from '../ui'
import ProjectSettings from '../projectSettings'

const View = styled.div`
  margin: 1rem -1rem;

  ${p => p.theme.layout.web} {
    margin: 2rem -2rem;
  }
`

const Settings = ({ canEdit }) => {
  const { id } = useParams()

  const { data, isFetching } = useGetProject(id)

  return (
    <View>
      <Loader isFetching={isFetching}>
        <ErrorBoundary fallback={<div>Unable to show settings</div>}>
          {data ? (
            canEdit ? (
              <ProjectSettings
                initialValues={{
                  ...data,
                  clients: [],
                  team: [],
                }}
              />
            ) : (
              <ProjectSettingsNoEdit settings={data} />
            )
          ) : null}
        </ErrorBoundary>
      </Loader>
    </View>
  )
}

Settings.defaultProps = {
  canEdit: false,
}
Settings.propTypes = {
  canEdit: PropTypes.bool,
}
export default Settings
