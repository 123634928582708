import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSelect from 'react-select'

import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg'
import { Button } from '../../../../ui'
import { EditIcon, Cell } from './ui'
import { formatCurrency } from '../../../../../utilities'

const Select = styled(ReactSelect)`
  flex: 1;
  margin-right: 1rem;
  height: 36px;
`

const getReportedOptions = (cr, symbol) => {
  const options = [
    {
      label: `Budget (${symbol}${formatCurrency(cr.budget_cost)})`,
      value: 'budget_cost',
    },
  ]
  if (cr.gc_claimed_cost)
    options.push({
      label: `Claimed (${symbol}${formatCurrency(cr.gc_claimed_cost)})`,
      value: 'gc_claimed_cost',
    })
  if (cr.assessed_cost)
    options.push({
      label: `Assessed (${symbol}${formatCurrency(cr.assessed_cost)})`,
      value: 'assessed_cost',
    })
  return options
}

const getCurrentReportedCost = ({
  reported_cost_values,
  budget_cost,
  gc_claimed_cost,
  assessed_cost,
  symbol,
}) => {
  if (reported_cost_values === 'budget_cost' && budget_cost)
    return `Budget Cost (${symbol}${formatCurrency(budget_cost)})`
  if (reported_cost_values === 'gc_claimed_cost' && gc_claimed_cost)
    return `Claimed Cost (${symbol}${formatCurrency(gc_claimed_cost)})`
  if (reported_cost_values === 'assessed_cost' && assessed_cost)
    return `Assessed Cost (${symbol}${formatCurrency(assessed_cost)})`
  return null
}

const ReportedCost = ({ cr, symbol, update, isSubmitting, canEdit }) => {
  const inputRef = React.useRef()
  const [edit, setEdit] = React.useState(false)
  const [selected, setSelected] = React.useState('')

  React.useEffect(() => {
    if (edit && inputRef.current) inputRef.current.focus()
  }, [edit])

  const startEdit = () => setEdit(true)

  const cancelEdit = () => {
    setEdit(false)
    // setSelected(cost || '')
  }

  const saveClaimedCost = async () => {
    const success = await update({ reported_cost_values: selected })
    if (success) setEdit(false)
  }

  const {
    reported_cost_values,
    budget_cost,
    gc_claimed_cost,
    assessed_cost,
  } = cr

  const cost = getCurrentReportedCost({
    reported_cost_values,
    budget_cost,
    gc_claimed_cost,
    assessed_cost,
    symbol,
  })

  const options = getReportedOptions(cr, symbol)

  return edit ? (
    <Cell>
      <Select
        ref={inputRef}
        name="reported_cost_values"
        label="Reported Values"
        onChange={opt => setSelected(opt.value)}
        defaultValue={options.find(opt => opt.value === reported_cost_values)}
        options={options}
        isSearchable={false}
      />
      <div style={{ display: 'flex' }}>
        <Button small onClick={saveClaimedCost} disabled={isSubmitting}>
          Save
        </Button>
        <Button small icon onClick={cancelEdit}>
          <CloseIcon style={{ width: '24px' }} disabled={isSubmitting} />
        </Button>
      </div>
    </Cell>
  ) : canEdit ? (
    <Cell>
      <div>{cost}</div>
      <Button small icon onClick={startEdit} disabled={isSubmitting}>
        <EditIcon />
      </Button>
    </Cell>
  ) : null
}

ReportedCost.defaultProps = {
  canEdit: false,
}

ReportedCost.propTypes = {
  cr: PropTypes.any.isRequired,
  symbol: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool,
}

export default ReportedCost
