import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, Modal } from '../../ui'

const Text = styled.p`
  margin-bottom: 0.5rem;
`
const Heading = styled.span`
  display: inline-block;
  width: 80px;
`

const ResendInviteModal = ({ isActive, onSubmit, onClose, user }) => {
  return (
    <Modal isActive={isActive} onClose={onClose} title={`Resend Invite`}>
      <Text>Resend invite to:</Text>
      <p>
        <Heading>Name:</Heading> {user?.name}
      </p>
      <p>
        <Heading>Email:</Heading> {user?.email}
      </p>
      <p>
        <Heading>Company:</Heading> {user?.company}
      </p>
      <Button small onClick={onSubmit} style={{ marginTop: '2rem' }}>
        Resend Invite
      </Button>
    </Modal>
  )
}

ResendInviteModal.defaultProps = {
  user: null,
}
ResendInviteModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    user_type: PropTypes.string,
    role_title: PropTypes.string,
    company: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
}

export default ResendInviteModal
