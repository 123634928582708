import React from 'react'
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'

import useGetProjects from './useGetProjects'
import {
  View,
  ViewHeader,
  Loader,
  CardWrapper,
  Table,
  Button,
  ChangeCheck,
} from '../ui'
import { formatCurrency } from '../../utilities'
import { useAuth } from '../../hooks'

const HEADERS = [
  'Project Name',
  'Total Cost',
  'Changes',
  '%',
  'Remaining Risk',
  '%',
  '',
]

const ProjectList = () => {
  const { projects, fetchMore, isFetching } = useGetProjects()
  const { user } = useAuth()
  const navigate = useNavigate()

  return (
    <View>
      <ViewHeader>
        <h1>Projects</h1>
        {user.isCostManager ? (
          <Button onClick={() => navigate(`/dashboard/new`)}>
            Create New Project
          </Button>
        ) : null}
      </ViewHeader>

      <Loader isFetching={isFetching}>
        <CardWrapper
          as={InfiniteScroll}
          dataLength={projects?.length || 0}
          next={fetchMore}
          hasMore={!!fetchMore}
        >
          <Table
            heading={projects ? `Active Projects: ${projects.length}` : ''}
            headers={HEADERS}
            inCard
          >
            {projects &&
              projects.map(project => (
                <tr key={project.id}>
                  <td data-header={HEADERS[0]}>{project.name}</td>
                  <td data-header={HEADERS[1]}>
                    {project.currency}
                    {formatCurrency(project.total_cost, { showSign: false })}
                  </td>
                  <td data-header={HEADERS[2]}>
                    <ChangeCheck number={project.changes}>
                      {formatCurrency(project.changes)}
                    </ChangeCheck>
                  </td>
                  <td data-header={HEADERS[3]}>
                    <ChangeCheck number={project.changes_percent}>
                      {project.changes_percent}%
                    </ChangeCheck>
                  </td>
                  <td data-header={HEADERS[4]} style={{ whiteSpace: 'nowrap' }}>
                    {/* +ve remaining risk should be green so swap */}
                    <ChangeCheck swap number={project.risk}>
                      {formatCurrency(project.risk)}
                    </ChangeCheck>
                  </td>
                  <td data-header={HEADERS[5]}>
                    <ChangeCheck swap number={project.risk_percent}>
                      {project.risk_percent}%
                    </ChangeCheck>
                  </td>
                  <td data-header={HEADERS[6]}>
                    <Button
                      style={{ marginLeft: 'auto' }}
                      small
                      fullwidth
                      onClick={() =>
                        navigate(`/projects/${project.id}/overview`)
                      }
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
          </Table>
        </CardWrapper>
      </Loader>
    </View>
  )
}

export default ProjectList
