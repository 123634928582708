import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { Persist } from 'formik-persist'
import { useToasts } from 'react-toast-notifications'
import { useNavigate } from 'react-router'

import { ErrorBoundary, Loader, View, ViewHeader } from '../ui'
import validation from './validation'
import { AuthContext } from '../../context/auth'
import ProjectForm from './projectForm'
import api from '../../services'

const TOAST_ERROR = { appearance: 'error', autoDismiss: true }

const ProjectSettings = ({ initialValues, ...props }) => {
  const { user } = useContext(AuthContext)
  const [isSubmitting, setSubmitting] = React.useState(false)
  const navigate = useNavigate()
  const { addToast } = useToasts()

  const edit = !!initialValues.id

  const submitProject = async values => {
    setSubmitting(true)
    const res = await api.submitProject(values, edit)

    if (!res.error) {
      localStorage.removeItem('create-project')
      localStorage.setItem('create-project-clear', true)

      addToast(edit ? 'Project updated' : 'Project created')
      // Delaying slightly as executes before storage is cleared
      setTimeout(() => {
        navigate('/')
        navigate(`/projects/${res.data.id}/overview`, { replace: true })
      }, 500)
    } else {
      ;(res.errors || []).forEach(error => {
        addToast(error, TOAST_ERROR)
      })
    }

    setSubmitting(false)
  }

  return (
    <View>
      {!edit ?? user.isCostManager ? (
        <ViewHeader>
          <h1> Create New Project</h1>
        </ViewHeader>
      ) : null}
      <Loader>
        <Formik
          validationSchema={validation}
          onSubmit={submitProject}
          initialValues={edit ? initialValues : validation.default()}
        >
          {formikProps => (
            <ErrorBoundary fallback="Unable to load project settings form.">
              <ProjectForm
                {...props}
                {...formikProps}
                isSubmitting={isSubmitting}
                canArchive={user.isAdmin && edit}
              >
                {edit ? null : (
                  <Persist isSessionStorage name="create-project" />
                )}
              </ProjectForm>
            </ErrorBoundary>
          )}
        </Formik>
      </Loader>
    </View>
  )
}

ProjectSettings.defaultProps = {
  initialValues: '',
  children: null,
}

ProjectSettings.propTypes = {
  initialValues: PropTypes.any,
  children: PropTypes.node,
}

export default ProjectSettings
