import React from 'react'
import PropTypes from 'prop-types'
import { Form, useFormikContext } from 'formik'
import { Prompt } from 'react-router-dom'
import isEqual from 'lodash/isEqual'

const FormHelper = ({ block, ...props }) => {
  const [submitCount, setSubmitCount] = React.useState(0)
  const {
    isValid,
    isValidating,
    setValues,
    values,
    initialValues,
    submitCount: nextCount,
  } = useFormikContext()

  // scroll to errors on formik validation
  React.useEffect(() => {
    const invalid = !isValid && !isValidating
    const newSubmission = nextCount > submitCount
    if (invalid && newSubmission) {
      setSubmitCount(nextCount)
      const element = document.querySelector('.has-error')
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        })
      }
    }
  }, [submitCount, nextCount, isValid, isValidating])

  try {
    if (window && window.Cypress) {
      // only when in Cypress testing env
      window.cy_setFormikData = setValues
    }
  } catch (e) {
    return null
  }

  return (
    <>
      {block && (
        <Prompt
          message=" You will lose any unsaved data. Are you sure you want to navigate away
  from this page?"
          when={!isEqual(values, initialValues)}
        />
      )}
      <Form {...props} />
    </>
  )
}

FormHelper.defaultProps = {
  block: false,
}
FormHelper.propTypes = {
  block: PropTypes.bool,
}

export default FormHelper
