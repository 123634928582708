import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../../utilities'

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  td,
  th {
    text-align: start;
    padding: 8px 6px;
  }
  td:not(:last-of-type) {
    padding-right: 1rem;
  }
  tbody tr:nth-child(odd) {
    background: #eee;
  }
`

const ChangesByValue = ({ data, currency }) => {
  const params = useParams()

  return (
    <Table>
      <thead>
        <tr>
          <th>CR</th>
          <th>Description</th>
          <th style={{ textAlign: 'end' }}>Value {currency}</th>
        </tr>
      </thead>
      <tbody>
        {data.map(d => (
          <tr key={d.cr}>
            <td>
              <Link to={`/projects/${params.id}/changes/${d.id}`}>{d.cr}</Link>
            </td>
            <td>{d.desc}</td>
            <td style={{ textAlign: 'end' }}>
              {formatCurrency(d.value, { showSign: false })}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

ChangesByValue.propTypes = {
  currency: PropTypes.oneOf(['gbp', 'eur']).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      cr: PropTypes.string.isRequired,
      desc: PropTypes.string,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
export default ChangesByValue
