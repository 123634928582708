import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

import FileInput from './FileInput'

export const FormikFile = ({ uploadedFiles, handleDelete, ...props }) => {
  const [field, , form] = useField(props)

  const addFile = file => {
    const uploadedFiles = field.value
    form.setValue([...uploadedFiles, file])
  }

  const deleteFile = async file => {
    if (file.id) {
      handleDelete(file.id)
    } else {
      const filteredFiles = field.value.filter(f => f !== file)
      form.setValue(filteredFiles)
    }
  }

  return (
    <FileInput
      {...props}
      addFile={addFile}
      deleteFile={deleteFile}
      value={[...field.value, ...uploadedFiles]}
    />
  )
}

FormikFile.defaultProps = {
  handleDelete: null,
  uploadedFiles: [],
}
FormikFile.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  uploadedFiles: PropTypes.array,
  handleDelete: PropTypes.func,
}
