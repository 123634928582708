import React from 'react'
import PropTypes from 'prop-types'
import { ToastProvider, DefaultToast } from 'react-toast-notifications'
import styled from 'styled-components'

import { ReactComponent as Check } from '../../assets/icons/check.svg'
import { ReactComponent as Cross } from '../../assets/icons/cross.svg'

const Toast = styled(({ wide, ...props }) => <DefaultToast {...props} />)`
  position: relative;

  width: ${p => (p.wide ? '600px !important' : '360px')};
  .react-toast-notifications__toast__content {
    display: flex;
    padding: 0;
  }
  .react-toast-notifications__toast__icon-wrapper {
    display: none;
  }
  .react-toast-notifications__toast__dismiss-button {
    position: absolute;
    padding: 0;
    top: 4px;
    right: 4px;
    color: black;
  }
`

const ToastBox = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  padding: 1rem 2rem;
  min-height: 70px;
  background: white;
  color: black;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background: ${p => p.theme.color[p.color]};
  width: 1.5rem;
  svg {
    stroke: white;
    fill: white;
    width: 100%;
  }
`

export const MyCustomToast = ({ ...props }) => {
  const icons = {
    success: { icon: <Check />, color: 'success' },
    error: { icon: <Cross style={{ padding: '1px' }} />, color: 'error' },
    default: { icon: <Check />, color: 'success' },
  }

  const type = icons[props.appearance] || icons.default

  return (
    <Toast {...props}>
      <IconContainer color={type.color}>{type.icon}</IconContainer>
      <ToastBox>{props.children}</ToastBox>
    </Toast>
  )
}

MyCustomToast.defaultProps = {
  appearance: 'success',
  autoDismiss: true,
  placement: 'bottom-center',
  wide: false,
}
MyCustomToast.propTypes = {
  appearance: PropTypes.string,
  autoDismiss: PropTypes.bool,
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
  wide: PropTypes.bool,
}

export default props => (
  <ToastProvider
    placement="bottom-center"
    components={{ Toast: MyCustomToast }}
    autoDismiss
    autoDismissTimeout={3000}
    {...props}
  />
)
