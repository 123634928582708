import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, Modal } from '../../ui'

const Text = styled.p`
  margin-bottom: 0.5rem;
`
const Heading = styled.span`
  display: inline-block;
  width: 80px;
`
const Warning = styled.p`
  text-transform: uppercase;
  color: darkred;
  margin: 1rem 0;
`

const DeleteCompanyModal = ({ isActive, onSubmit, onClose, company }) => {
  return (
    <Modal isActive={isActive} onClose={onClose} title="Delete Company">
      <Text>Are you sure you want to delete this company?</Text>
      <p>
        <Heading>Name:</Heading> {company?.name}
      </p>
      <p>
        <Heading>Ref:</Heading> {company?.ref}
      </p>

      <Warning>This is permanent and cannot be reversed.</Warning>
      <Button small onClick={onSubmit} style={{ marginTop: '2rem' }}>
        Confirm
      </Button>
    </Modal>
  )
}

DeleteCompanyModal.defaultProps = {
  company: null,
}
DeleteCompanyModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number,
    ref: PropTypes.string,
    name: PropTypes.string,
    address: PropTypes.string,
  }),
}

export default DeleteCompanyModal
