import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import { Button, CardHeading } from '../ui'
import { ReactComponent as Close } from '../../assets/icons/cross.svg'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 900;
  pointer-events: all;
`

const ContentOverlay = styled(Overlay)`
  top: calc(${p => p.theme.layout.header_height});
  bottom: ${p => p.theme.layout.nav_height_mobile};
  background: rgba(250, 250, 250, 0.8);
  overflow: scroll;
  pointer-events: none;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem 1rem 1rem;

  ${p => p.theme.layout.web} {
    padding: 2rem;
    bottom: 0;
    left: ${p => p.theme.layout.nav_width_web};
  }
`

const ModalContainer = styled.div`
  background: white;
  height: fit-content;
  box-shadow: -5px 5px 30px 0 rgba(0, 0, 0, 0.14);
  pointer-events: all;
  z-index: 901;
  position: relative;
`

const ModalHeader = styled.div`
  padding: 2rem;
  box-shadow: 0px 2px 4px 0px rgba(219, 219, 219, 0.5);
  position: relative;
`

const Heading = styled(CardHeading)`
  margin: 0;
  font-size: 18px;
  line-height: 24px;

  ${p => p.theme.layout.web} {
    margin: 0;
  }
`

const ModalContent = styled.div`
  padding: 1rem;

  ${p => p.theme.layout.web} {
    padding: 2rem;
  }
`

const ModalClose = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  width: 28px;
`

const MODAL_ROOT = document.getElementById('modal-root')
const BODY = document.querySelector('body')

const Modal = ({ isActive, onClose, children, width, title }) => {
  React.useEffect(() => {
    if (isActive) {
      BODY.classList.add('scroll-lock')
    } else {
      BODY.classList.remove('scroll-lock')
    }
  }, [isActive])

  return ReactDOM.createPortal(
    <CSSTransition in={isActive} timeout={200} classNames="fade" unmountOnExit>
      <>
        <Overlay onClick={onClose} />
        <ContentOverlay>
          <ModalContainer style={{ width }}>
            <ModalHeader>
              <Heading>{title}</Heading>
              <ModalClose small onClick={onClose} icon>
                <Close height="12px" />
              </ModalClose>
            </ModalHeader>
            <ModalContent>{children}</ModalContent>
          </ModalContainer>
        </ContentOverlay>
      </>
    </CSSTransition>,
    MODAL_ROOT,
  )
}

Modal.defaultProps = {
  isActive: false,
  width: '600px',
}

Modal.propTypes = {
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
}

export default Modal
