import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

import { TextareaWithLabel } from '.'

export const FormikText = props => {
  const [field, meta] = useField(props)
  return (
    <TextareaWithLabel
      {...field}
      {...props}
      errorText={meta.touched ? meta.error : ''}
    />
  )
}

FormikText.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
