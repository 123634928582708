import React, { useCallback } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import axios from 'axios'
import { useQuery } from 'react-query'

import { Card as DefaultCard, TableHeading } from '../../ui'
import useAnalyticsPdfHook from '../../analytics/useAnalyticsPdfHook'
import { ChangesByValueLoader } from './changesByValue'
import { ChangesByMonthLoader } from './changesByMonth'
import { CostIncreasesLoader } from './costIncreases'
import { CategoryCostsLoader } from './categoryCosts'
import { RiskAllowanceLoader } from './riskAllowance'
import { ChangeOriginLoader } from './changeOrigin'
import { AuthContext } from '../../../context/auth'

export const CardGrid = styled.div`
  display: grid;
  ${p => p.theme.layout.web} {
    grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  }
`

export const Card = styled(DefaultCard)`
  position: relative;
  padding: 1.75rem 1rem;
`

export default function Analytics() {
  const { id } = useParams()
  const { user } = React.useContext(AuthContext)

  const { data } = useQuery(
    ['project', id],
    async () => axios.get(`/project/${id}`),
    { enabled: !!id },
  )

  const { setContent, downloadButton, setChangesByValue } = useAnalyticsPdfHook(
    data?.data?.title,
  )

  const handleSaveChartImage = useCallback(
    key => chart => {
      setContent(key, chart)
    },
    [setContent],
  )

  const project = data?.data

  if (!id || !project) return null
  const projectCurrency = project?.currency

  const showChangeOriginInfo =
    project.change_origin_privacy === false
      ? true
      : user.isProjectManager || user.isCostManager

  return (
    <>
      <Card style={{ width: 'fit-content', padding: 0, marginLeft: 'auto' }}>
        {downloadButton}
      </Card>
      <CardGrid>
        <Card pos="relative">
          <TableHeading large>Main Changes By Value</TableHeading>
          <ChangesByValueLoader
            id={id}
            save={setChangesByValue}
            currency={projectCurrency}
          />
        </Card>
        <Card>
          <TableHeading large>Changes By Month</TableHeading>
          <ChangesByMonthLoader id={id} save={handleSaveChartImage} />
        </Card>
        <Card>
          <TableHeading large>
            % Cost Increases Per Cost Constituent
          </TableHeading>
          <CostIncreasesLoader id={id} save={handleSaveChartImage} />
        </Card>
        <Card>
          <TableHeading large>Elemental Change Analysis</TableHeading>
          <CategoryCostsLoader id={id} save={handleSaveChartImage} />
        </Card>
        <Card>
          <TableHeading large>Risk Allowance vs Actual Change</TableHeading>
          <RiskAllowanceLoader id={id} save={handleSaveChartImage} />
        </Card>
        {showChangeOriginInfo ? (
          <Card>
            <TableHeading large>Change Origin</TableHeading>
            <ChangeOriginLoader id={id} save={handleSaveChartImage} />
          </Card>
        ) : null}
      </CardGrid>
    </>
  )
}
