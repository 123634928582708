import * as Yup from 'yup'

const changeRequestSchema = Yup.object().shape({
  cost_constituent: Yup.string().default('').min(1).required(),
  change: Yup.string().default('').min(1).required(),
  reason: Yup.string().default('').min(1).required(),
  delay: Yup.string().default(''),
  anticipated_matter: Yup.string().default(''),
  files: Yup.array().default([]),

  reported_cost_values: Yup.string().required(),
  gc_claimed_cost: Yup.number().default(null).nullable(),
  date_gc_claimed_cost: Yup.date().default(null).nullable(),
  rfi: Yup.string().default(''),
  instruction_number: Yup.string().default(''),
  instruction_date: Yup.date().default(null).nullable(),

  // additional_comments: Yup.string().default('').min(1).required(),
  // cm_comments: Yup.string().default('').min(1).required(),
})

export default changeRequestSchema
