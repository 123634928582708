import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg'

const AccordionBtn = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  padding: ${p => (p.noPadding ? '1rem 1rem 1rem 0' : '1rem')};
  background-color: white;
  transition: 0.5s padding, 0.5s background;

  &:hover {
    background-color: #eee;
    padding: 1rem;
  }
`

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c5c5c5;

  &:first-of-type {
    border-top: 1px solid #c5c5c5;
  }

  &:focus ${AccordionBtn}, &:focus-within ${AccordionBtn} {
    background-color: #eee;
    padding: 1rem;
  }
`

const Title = styled.h2`
  color: ${p => p.theme.color.text};
  transition: background-color 0.6s ease;
  font-weight: 600;
  font-size: 14px;
  text-align: start;
`

const ContentWrapper = styled.div`
  background-color: white;
  overflow: hidden;
  max-height: ${p => p.height};
  transition: max-height 0.3s ease;
`

const Content = styled.div`
  padding: ${p => (p.noPadding ? '1rem 0' : '1rem')};
`

const AccordionIcon = styled(({ isActive, ...props }) => <Arrow {...props} />)`
  width: 10px;
  margin-left: auto;
  transform: rotate(${p => (p.isActive ? '180deg' : 0)});
  transition: transform 150ms;
`

const Accordion = ({ title, children, noPadding, noMaxHeight, optional }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [height, setHeight] = React.useState(0)
  const contentRef = React.useRef()

  const toggleAccordion = () => {
    setIsOpen(state => {
      const newState = !state
      const contentHeight = contentRef.current.scrollHeight
      setHeight(newState ? (noMaxHeight ? 'unset' : `${contentHeight}px`) : 0)

      return newState
    })
  }

  return (
    <AccordionSection key={title} optional={optional} noPadding={noPadding}>
      <AccordionBtn
        type="button"
        onClick={toggleAccordion}
        optional={optional}
        noPadding={noPadding}
      >
        <Title>
          {title} {optional ? <span>(Optional)</span> : null}
        </Title>
        <AccordionIcon isActive={isOpen} />
      </AccordionBtn>
      <ContentWrapper ref={contentRef} height={height}>
        <Content noPadding={noPadding}>{children}</Content>
      </ContentWrapper>
    </AccordionSection>
  )
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
  noMaxHeight: PropTypes.bool,
  optional: PropTypes.bool,
}

Accordion.defaultProps = {
  optional: false,
  noPadding: false,
  noMaxHeight: false,
}
export default Accordion
