import React from 'react'
import PropTypes from 'prop-types'
import { Doughnut } from 'react-chartjs-2'
import styled from 'styled-components'

import { CardWrapper, Card, Table, Td, TableHeading, ChangeCheck } from '../ui'
import { formatCurrency } from '../../utilities'
import { useFetch } from '../../hooks'
import PDFButton from '../ui/PDFButton'

const CHANGES_HEADERS = [
  '',
  'Changes',
  'Approved',
  'Rejected',
  'Pending',
  'Formalised',
]

const CONSTRUCTION_HEADERS = [
  '',
  'Budgets Costs by Cost Manager',
  'Claim Submitted by Contractors',
  'Cost Manager Assessments',
]

//#region styles
const ChartWrapper = styled.div`
  position: relative;
  height: 350px;
  max-width: 100%;
  margin: auto;

  ${p => p.theme.layout.web} {
    max-width: 40%;
    margin-right: 0;
  }
`

const TableContainer = styled.div`
  flex: 1 1 50%;
  ${p => p.theme.layout.web} {
    margin-right: 2rem;
  }
`

const FlexRow = styled(CardWrapper)`
  margin: 0;
  flex: 1;
`

const TableHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  button {
    margin-bottom: 2.5rem;
  }
  ${p => p.theme.layout.web} {
    padding: 0;
  }
`
//#endregion

const Overview = ({ id, project }) => {
  const url = id ? `project/${id}/overview` : null
  const { data } = useFetch(url, 'Unable to fetch project overview')

  const isEuro = project?.currency === 'eur'
  const SUMMARY_HEADERS = ['', isEuro ? '€ (EUR)' : '£ (GBP)', '%']
  const BREAKDOWN_HEADERS = [
    '',
    `Starting Cost ${isEuro ? '€(EUR)' : '£(GBP)'}`,
    `Changes ${isEuro ? '€(EUR)' : '£(GBP)'}`,
    '%',
    `Estimated Final Cost ${isEuro ? '€(EUR)' : '£(GBP)'}`,
    `Cost / m2 ${isEuro ? '€(EUR)' : '£(GBP)'}`,
  ]

  const changes =
    data && data.changes_status
      ? [
          { name: 'Construction', key: 'construction' },
          { name: 'Prof Fees', key: 'fees' },
          { name: 'Other Costs', key: 'other' },
          { name: 'Total', key: 'total' },
        ].map(({ name, key }) => ({
          name,
          count: data.changes_status[key]?.changes,
          approved: data.changes_status[key]?.approved,
          rejected: data.changes_status[key]?.rejected,
          pending: data.changes_status[key]?.pending,
          formalised: data.changes_status[key]?.formalised,
        }))
      : []

  const summary =
    data && data.cost_summary
      ? [
          { name: 'Approved Budget', key: 'approved_budget' },
          { name: 'Starting Point Costs', key: 'starting_point_costs' },
          { name: 'Changes', key: 'changes' },
          { name: 'Remaining Risk Allowance', key: 'remaining_risk_allowance' },
          { name: 'Anticipated Final Cost', key: 'anticipated_final_cost' },
        ].map(({ name, key }) => ({
          name,
          value: data.cost_summary[key]?.value,
          percent: data.cost_summary[key]?.percentage,
        }))
      : []

  const summaryChart = data && {
    committed:
      (data.cost_summary.starting_point_costs.percentage || 0) +
      (data.cost_summary.changes.percentage || 0),
    remaining: data.cost_summary.remaining_risk_allowance.percentage || 0,
  }

  const breakdown =
    data && data.changes_breakdown
      ? [
          { name: 'Construction Costs', key: 'construction' },
          { name: 'Professional Fees & Surveys', key: 'fees' },
          { name: 'Other Project/Development Costs', key: 'other' },
          { name: 'Sub-Totals', key: 'subtotals' },
          { name: 'Risk Allowance', key: 'risk_allowance' },
          { name: 'Totals', key: 'totals' },
        ].map(({ name, key }) => ({
          name,
          starting_cost: data.changes_breakdown[key]?.starting_cost,
          changes: data.changes_breakdown[key]?.changes,
          percentage: data.changes_breakdown[key]?.percentage,
          estimated_final_cost:
            data.changes_breakdown[key]?.estimated_final_cost,
          cost_m2: data.changes_breakdown[key]?.cost_m2,
        }))
      : []

  const cost_status =
    data && data.construction_change_cost_status
      ? [
          { ...data.construction_change_cost_status.issued, name: 'Issued' },
          {
            ...data.construction_change_cost_status.outstanding,
            name: 'Outstanding',
          },
          { ...data.construction_change_cost_status.overdue, name: 'Overdue' },
          {
            ...Object.entries(
              data.construction_change_cost_status.performance,
            ).reduce(
              (acc, [key, val]) => ({
                ...acc,
                [key]: val ? `${parseFloat(val).toFixed(0)}%` : '-',
              }),
              {},
            ),
            name: 'Performance',
          },
        ]
      : []

  return (
    <CardWrapper>
      <FlexRow>
        <Card>
          <TableHeadWrapper style={{ marginBottom: '1.5rem' }}>
            <TableHeading style={{ marginBottom: '0' }}>
              Cost Summary
            </TableHeading>
            <PDFButton
              pdfRaw={summary}
              project={project}
              pdfFile="cost_summary"
            />
          </TableHeadWrapper>
          <FlexRow nowrap>
            <TableContainer>
              <Table headers={SUMMARY_HEADERS}>
                {summary.map(({ name, value, percent }) => {
                  const isBold = [
                    'Approved Budget',
                    'Anticipated Final Cost',
                  ].includes(name)
                  return (
                    <tr key={name}>
                      <Td $isBold={isBold} data-header={SUMMARY_HEADERS[0]}>
                        {name}
                      </Td>
                      <Td $isBold={isBold} data-header={SUMMARY_HEADERS[1]}>
                        {formatCurrency(value)}
                      </Td>
                      <Td $isBold={isBold} data-header={SUMMARY_HEADERS[2]}>
                        {percent}
                      </Td>
                    </tr>
                  )
                })}
              </Table>
            </TableContainer>
            {summaryChart && (
              <ChartWrapper>
                <Doughnut
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          const label = data.labels[tooltipItem.index]
                          const dataset =
                            data.datasets[tooltipItem.datasetIndex]
                          const value = dataset.data[tooltipItem.index]

                          return ` ${label}: ${value}%`
                        },
                      },
                    },
                  }}
                  data={{
                    labels: ['Committed', 'Remaining'],
                    datasets: [
                      {
                        data: [summaryChart.committed, summaryChart.remaining],
                        backgroundColor: ['#0086CB', '#20CA14'],
                      },
                    ],
                  }}
                />
              </ChartWrapper>
            )}
          </FlexRow>
        </Card>
        <Card>
          <Table
            heading="Changes Status"
            headers={CHANGES_HEADERS}
            pdfData={changes}
            pdfFile="change_status"
            project={project}
          >
            {changes.map(change => {
              const isBold = ['Total'].includes(change.name)
              return (
                <tr key={change.name}>
                  <Td $isBold={isBold} data-header={CHANGES_HEADERS[0]}>
                    {change.name}
                  </Td>
                  <Td $isBold={isBold} data-header={CHANGES_HEADERS[1]}>
                    {change.count}
                  </Td>
                  <Td $isBold={isBold} data-header={CHANGES_HEADERS[2]}>
                    {change.approved}
                  </Td>
                  <Td $isBold={isBold} data-header={CHANGES_HEADERS[3]}>
                    {change.rejected}
                  </Td>
                  <Td $isBold={isBold} data-header={CHANGES_HEADERS[4]}>
                    {change.pending}
                  </Td>
                  <Td $isBold={isBold} data-header={CHANGES_HEADERS[5]}>
                    {change.formalised}
                  </Td>
                </tr>
              )
            })}
          </Table>
        </Card>
      </FlexRow>

      <Card style={{ flexBasis: '100%' }}>
        <Table
          heading="Changes / Risk Allowance Breakdown"
          headers={BREAKDOWN_HEADERS}
          pdfData={breakdown}
          pdfFile="changes_and_risk_allowance"
          project={project}
        >
          {breakdown.map(bd => {
            const isBold = ['Sub-Totals', 'Totals'].includes(bd.name)
            return (
              <tr key={bd.name}>
                <Td $isBold={isBold} data-header={BREAKDOWN_HEADERS[0]}>
                  {bd.name}
                </Td>
                <Td $isBold={isBold} data-header={BREAKDOWN_HEADERS[1]}>
                  {formatCurrency(bd.starting_cost, { showSign: false })}
                </Td>
                <Td $isBold={isBold} data-header={BREAKDOWN_HEADERS[2]}>
                  <ChangeCheck number={bd.changes}>
                    {formatCurrency(bd.changes)}
                  </ChangeCheck>
                </Td>
                <Td $isBold={isBold} data-header={BREAKDOWN_HEADERS[3]}>
                  <ChangeCheck number={bd.percentage}>
                    {bd.percentage}%
                  </ChangeCheck>
                </Td>
                <Td $isBold={isBold} data-header={BREAKDOWN_HEADERS[4]}>
                  {formatCurrency(bd.estimated_final_cost, { showSign: false })}
                </Td>
                <Td $isBold={isBold} data-header={BREAKDOWN_HEADERS[5]}>
                  {formatCurrency(bd.cost_m2, { showSign: false })}
                </Td>
              </tr>
            )
          })}
        </Table>
      </Card>
      <Card style={{ flexBasis: '100%' }}>
        <Table
          heading="Construction Change Cost Status"
          headers={CONSTRUCTION_HEADERS}
          pdfData={cost_status}
          pdfFile="construction_change_cost_status"
          project={project}
        >
          {cost_status.map(cost => {
            return (
              <tr key={cost.name}>
                <td data-header={CONSTRUCTION_HEADERS[0]}>{cost.name}</td>
                <td data-header={CONSTRUCTION_HEADERS[1]}>{cost.budget}</td>
                <td data-header={CONSTRUCTION_HEADERS[2]}>{cost.claimed}</td>
                <td data-header={CONSTRUCTION_HEADERS[3]}>{cost.assessed}</td>
              </tr>
            )
          })}
        </Table>
      </Card>
    </CardWrapper>
  )
}

Overview.defaultProps = {
  id: null,
  project: null,
}

Overview.propTypes = {
  id: PropTypes.number,
  project: PropTypes.object,
}

export default Overview
