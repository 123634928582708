import React from 'react'
import { useFetch, useBreadcrumb } from '../../hooks'

const useGetProject = id => {
  const { data, isFetching } = useFetch(
    `project/${id}`,
    'Unable to fetch project',
  )

  const { setProject } = useBreadcrumb()
  // work around if project does not exist.
  // server returns {} so check if it has any properties
  const error = data && Object.keys(data).length === 0

  React.useEffect(() => {
    if (data && !error) setProject(data.title)
  }, [data, setProject, error])

  return {
    error,
    project: data && {
      id: data.id,
      org_id: data.organisation_id,
      currency: data.currency,
      title: data.title,
    },
    isFetching,
  }
}

export default useGetProject
