import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'

import { LoadTransition } from '../../ui'
import useFetch from '../../../hooks/useFetch'
import ChangesByMonth from '../../analytics/changesByMonth'

export const ChangesByMonthLoader = ({ id, save }) => {
  const changesByMonth = useFetch(
    `/project/${id}/changesByMonth`,
    'Unable to fetch changes by month data',
  )

  return (
    <LoadTransition isFetching={changesByMonth.isFetching}>
      {changesByMonth.data?.data && changesByMonth.data?.data?.length ? (
        <ChangesByMonth
          onChartIsReady={save('changesByMonth')}
          data={changesByMonth.data.data.map(d => ({
            label: format(new Date(d.month), 'MM/yyyy'),
            changes: d.total,
            risk: d.riskRemaining,
          }))}
          forecast={(changesByMonth.data.predict || []).map(d => ({
            month: format(new Date(d.month), 'MM/yyyy'),
            tracker: d.tracker,
          }))}
        />
      ) : (
        <div>No data to display</div>
      )}
    </LoadTransition>
  )
}
ChangesByMonthLoader.propTypes = {
  id: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
}
