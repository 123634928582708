import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import RSelect from 'react-select/creatable'
import { useField } from 'formik'

import { Label, Error, InputWrapper, Row } from '..'

export const SCombo = styled(RSelect)`
  width: 100%;
  border: none;
  border-bottom: 1px solid black;

  .react-select {
    &__control {
      border: none;
      box-shadow: none;
      padding-right: 0.25rem;
    }
    &__placeholder {
      visibility: hidden;
    }
    &__value-container {
      height: 1.5rem;
    }
    &__single-value {
      font-size: 14px;
      transform: translateY(-25%);
    }
    &__indicator-separator {
      display: none;
    }
    &__indicator svg {
      fill: black;
    }
    &__menu {
      margin-top: 5px;
      z-index: 3;
    }
    &__option {
      padding: 0.5rem 1rem;
      cursor: pointer;
    }
  }
  &.react-select--is-disabled {
    border-bottom-color: transparent;
    .react-select__control {
      background: none;
    }
    .react-select__indicator svg {
      fill: #575756;
    }
  }
`
const Note = styled.span`
  text-transform: uppercase;
  font-style: italic;
  font-size: 13px;
  letter-spacing: 1.5px;
  color: ${p => p.theme.color.grey};
  line-height: 1.54;
`

const FormikCombo = props => {
  const [field, meta, form] = useField(props)
  const [hasUserInput, setHasUserInput] = React.useState(false)

  const value = !field.value ? null : field.value?.value

  const handleChange = value => {
    form.setValue(value)
  }

  const handleInputChange = newValue => {
    setHasUserInput(!!newValue)
  }

  const errorText = meta.touched ? meta.error : ''
  const isFilled = value || hasUserInput

  return (
    <div className={props.className}>
      {props.note ? (
        <Row>
          <Note>{props.note}</Note>
        </Row>
      ) : null}
      <InputWrapper
        hasError={!!errorText}
        className={isFilled ? 'isFilled' : ''}
      >
        <Label htmlFor={props.name} style={{ maxWidth: 'calc(100% - 2rem)' }}>
          {props.label}
        </Label>
        <SCombo
          {...props}
          inputId={props.name}
          onChange={handleChange}
          onInputChange={handleInputChange}
          classNamePrefix="react-select"
          isDisabled={props.readonly}
          value={props.options.find(opt => opt.value === field.value?.value)}
          theme={selectTheme => ({
            ...selectTheme,
            spacing: {
              controlHeight: '32px',
            },
            colors: {
              ...selectTheme.colors,
              primary: 'black',
              primary25: 'lightgrey',
              primary50: 'lightgrey',
            },
          })}
        />
      </InputWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

FormikCombo.defaultProps = {
  className: '',
  note: '',
  readonly: false,
}
FormikCombo.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  note: PropTypes.string,
  readonly: PropTypes.bool,
}

export default FormikCombo
