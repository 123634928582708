import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { useAuth } from '../../hooks'
import { Button } from '../ui'
import { Form, FormikInput } from '../form'
import { Container, BoxWithLogo, Text, StyledLink } from './login-ui'

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Must be an email').required('Required'),
  password: Yup.string().min(7, 'Too short').required('Required'),
})

export default function Login() {
  const { login } = useAuth()

  const submitLogin = async ({ email, password }) => {
    await login({ email, password })
  }

  return (
    <Container>
      <BoxWithLogo>
        <Text>Log in to your account</Text>
        <Formik
          validationSchema={loginSchema}
          onSubmit={submitLogin}
          initialValues={{
            email: '',
            password: '',
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormikInput
                type="email"
                name="email"
                label="Email Address"
                placeholder="Enter email"
                autoComplete="email"
              />

              <FormikInput
                type="password"
                name="password"
                label="Password"
                placeholder="Enter password"
                autoComplete="current-password"
              />

              <Button fullwidth tall type="submit" disabled={isSubmitting}>
                Log In
              </Button>
            </Form>
          )}
        </Formik>

        <StyledLink to="/forgot-password">Forgot Password?</StyledLink>
      </BoxWithLogo>
    </Container>
  )
}
