import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Error } from '../'
import { Button } from '../../ui'
import { ReactComponent as Cross } from '../../../assets/icons/cross.svg'

const Input = styled.input`
  display: none;
`
const Label = styled.label`
  color: ${p => p.theme.color.text};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
`
const Info = styled.p`
  margin-top: 0.5rem;
  color: ${p => p.theme.color.text};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`

const Files = styled.ul`
  margin-bottom: 1.5rem;
`
const File = styled.li`
  display: flex;
  margin-top: 10px;
  min-height: 48px;
  padding: 12px 22px;
  background-color: #f0f0f0;
`

const Filename = styled.p`
  display: flex;
  align-items: center;
  flex: 1;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  max-width: calc(100% - 2rem);
  text-decoration: none;
`

const Delete = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  border: none;
  background: none;
  height: fit-content;
  width: fit-content;
  padding: 7px;
  margin-left: 1rem;
  cursor: pointer;
  :hover {
    stroke: red;
    fill: red;
  }
`

const DeleteIcon = styled(Cross)`
  width: 10px;
  height: 10px;
`

const FileInput = ({
  className,
  name,
  label,
  addFile,
  deleteFile,
  maxSize,
  types,
  value,
  info,
  children,
  readonly,
}) => {
  const [error, setError] = React.useState('')
  const inputRef = React.useRef()

  const selectFile = e => {
    const file = e.target?.files[0]
    inputRef.current.value = null

    if (!file) return setError('Please select a file')

    const max_in_bytes = maxSize * 1024 * 1024
    const tooLarge = file.size > max_in_bytes
    if (tooLarge) return setError(`File must be less than ${maxSize} MB`)

    const isDuplicate = value.find(f => f.name === file.name)
    if (isDuplicate) return setError(`File has already been added`)

    addFile(file)
  }

  const onClick = () => {
    setError('')
    inputRef.current.click()
  }
  return (
    <div className={className}>
      <Label htmlFor={name}>{label}</Label>
      <Info>{info}</Info>

      <Input
        ref={inputRef}
        type="file"
        id={name}
        name={name}
        onChange={selectFile}
        accept={types.join(', ')}
        disabled={readonly}
      />
      <Error>{error}</Error>

      <Files>
        {value.map(f => {
          const link_props = f.url
            ? {
                as: 'a',
                href: f.url,
                rel: 'noreferrer noopener',
                target: '_blank',
              }
            : { as: 'p' }
          return (
            <File key={f.id || f.name}>
              <Filename {...link_props}>{f.name}</Filename>
              {!readonly && (
                <Delete type="button" onClick={() => deleteFile(f)}>
                  <DeleteIcon />
                </Delete>
              )}
            </File>
          )
        })}
      </Files>

      <div style={{ display: 'flex' }}>
        <Button type="button" onClick={onClick} disabled={readonly}>
          {children}
        </Button>
      </div>
    </div>
  )
}

FileInput.defaultProps = {
  className: '',
  maxSize: 10,
  types: [],
  info: '',
  readonly: false,
}

FileInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  maxSize: PropTypes.number,
  types: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.array.isRequired,
  addFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  info: PropTypes.string,
  readonly: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default FileInput
