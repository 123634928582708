import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFormikContext } from 'formik'

import { ReactComponent as Close } from '../../../../assets/icons/cross.svg'
import { Error, FormikInput } from '../../../form'
import { Button } from '../../../ui'
import FastInput from './fastInput'

const OtherCostRow = styled.div`
  grid-column: 1 / -1;

  display: grid;
  gap: 0 1rem;

  ${p => p.theme.layout.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${p => p.theme.layout.web} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const Grid = styled.div`
  display: grid;
  align-items: end;
  gap: 1rem 2rem;
  margin: 1rem 0 0 0;
  ${p => p.theme.layout.web} {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
`

const Gridheader = styled.h4`
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 6px;
`

const ButtonRemove = styled(Button)`
  background: #f0f0f0;
  border-radius: 100%;
  flex: unset !important;
  height: 36px;
  min-width: 36px;
`

const OtherCostBtn = styled(Button)`
  height: 36px;
`

const formatCurrency = str => {
  const value = parseFloat(str.replace(/,/g, ''))
  const formattedValue = value.toLocaleString('en-UK', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return formattedValue && !Number.isNaN(value) ? formattedValue : ''
}

const OtherCosts = ({ field, getRate }) => {
  const { values, errors, touched, setFieldValue } = useFormikContext()

  const costs = values.other_costs[field]

  const fieldInputError = errors?.other_costs?.[field]
  const fieldInputTouched = touched?.other_costs?.[field]
  const fieldError =
    typeof fieldInputError === 'string' ? fieldInputError : null

  const addNewCost = () => {
    setFieldValue(`other_costs[${field}]`, [...costs, { type: '', total: '' }])
  }

  const deleteCost = index => {
    const array = [...costs.slice(0, index), ...costs.slice(index + 1)]
    setFieldValue(`other_costs[${field}]`, array)
  }

  return (
    <>
      <Gridheader>Other</Gridheader>
      <Grid style={{ marginTop: 0 }}>
        {costs.map((cost, i) => (
          <OtherCostRow key={i}>
            <FastInput
              name={`other_costs.${field}.${i}.type`}
              label="Type"
              errorText={
                fieldInputTouched?.[i]?.type && fieldInputError?.[i]?.type
              }
            />
            <FastInput
              name={`other_costs.${field}.${i}.total`}
              label="Total"
              errorText={
                fieldInputTouched?.[i]?.total && fieldInputError?.[i]?.total
              }
              onBlur={e => {
                e.persist()
                setFieldValue(
                  `other_costs.${field}.${i}.total`,
                  formatCurrency(e.target.value),
                )
              }}
            />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormikInput
                name={`${field} ${i} rate`}
                value={getRate(cost.total)}
                label="(m2 Rate)"
                readonly
              />
              <ButtonRemove small icon onClick={() => deleteCost(i)}>
                <Close height="10px" />
              </ButtonRemove>
            </div>
          </OtherCostRow>
        ))}
      </Grid>
      <div style={{ marginTop: '0.5rem' }}>
        {fieldError && (
          <Error className="has-error" style={{ marginBottom: '0.5rem' }}>
            {fieldError}
          </Error>
        )}

        <OtherCostBtn
          onClick={addNewCost}
          disabled={errors?.other_costs?.[field]}
        >
          Add Cost
        </OtherCostBtn>
      </div>
    </>
  )
}

OtherCosts.propTypes = {
  field: PropTypes.string.isRequired,
  getRate: PropTypes.func.isRequired,
}
export default OtherCosts
