import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  ArcElement,
  Legend,
  Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { formatCurrency } from '../../utilities'
import useGetDash from './useGetDash'
import { useAuth } from '../../hooks'
import {
  Loader,
  View,
  ViewHeader,
  Button,
  CardWrapper,
  Card,
  Table,
  TableHeading,
} from '../ui'
import RiskPieChart, { PIE_COLORS, DONUT_COLORS } from './riskPieChart'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
)

const CardChartContent = styled.div`
  padding: 0 1rem;
  ${p => p.theme.layout.web} {
    padding: 0;
  }
`
const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(100vw - 4rem);
  margin-bottom: 2rem;
  min-height: 300px;
  ${p => p.theme.layout.web} {
    max-width: calc(100% - 1px);
    min-height: 475px;
  }
`

const PiesContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 1fr);
  grid-auto-rows: 300px;
  gap: 2rem;
  ${p => p.theme.layout.web} {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: 250px;
  }
`

const ChartLegend = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 2rem;
`

const LegendItem = styled.li`
  position: relative;
  margin-left: 2.5rem;
  margin-right: 1rem;
  color: #000000;
  font-family: Arial;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 13px;

  &:before {
    content: '';
    position: absolute;
    left: -32px;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 8px;
    background: ${p => p.color};
  }
`

const HEADERS = [
  'Name',
  'Area m2',
  'Total Cost',
  'Total Cost/m2',
  'Construction Cost/m2',
  'Fees as % of Construction',
]

const Dashboard = () => {
  const { isFetching, data } = useGetDash()
  const { user } = useAuth()
  const navigate = useNavigate()

  return (
    <View>
      <ViewHeader>
        <h1>Dashboard</h1>
        {user.isCostManager ? (
          <Button onClick={() => navigate(`/dashboard/new`)}>
            Create New Project
          </Button>
        ) : null}
      </ViewHeader>
      <Loader isFetching={isFetching}>
        <CardWrapper>
          <Card style={{ overflow: 'scroll' }}>
            <CardChartContent>
              <TableHeading large>1. Project Total costs</TableHeading>
              <ChartWrapper>
                {data ? (
                  <Bar
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        x: { stacked: true },
                        y: { stacked: true },
                      },
                    }}
                    data={{
                      labels: data.projects.map(project => project.name),
                      datasets: [
                        {
                          type: 'bar',
                          label: 'Construction',
                          backgroundColor: '#0086CB',
                          data: data.projects.map(p => p.construction),
                          maxBarThickness: 125,
                        },
                        {
                          type: 'bar',
                          label: 'Fees',
                          backgroundColor: '#20CA14',
                          data: data.projects.map(p => p.fees),
                          maxBarThickness: 125,
                        },
                        {
                          type: 'bar',
                          label: 'Other Project Costs',
                          backgroundColor: '#FF073A',
                          data: data.projects.map(p => p.other),
                          maxBarThickness: 125,
                        },
                        {
                          type: 'bar',
                          label: 'Risk',
                          backgroundColor: '#6EC2DA',
                          data: data.projects.map(p => p.risk),
                          maxBarThickness: 125,
                        },
                      ],
                    }}
                  />
                ) : null}
              </ChartWrapper>
            </CardChartContent>

            <div style={{ overflow: 'scroll' }}>
              <Table
                headers={HEADERS}
                pdfData={data?.projects}
                pdfFile="total_cost"
              >
                {data?.projects.map(project => (
                  <tr key={project.id}>
                    <td data-header={HEADERS[0]}>{project.name}</td>
                    <td data-header={HEADERS[2]}>
                      {formatCurrency(project.area ? project.area : 0, {
                        showSign: false,
                      })}
                    </td>
                    <td data-header={HEADERS[3]}>
                      {formatCurrency(project.total_cost, {
                        symbol: project.currency,
                        showSign: false,
                      })}
                    </td>
                    <td data-header={HEADERS[4]} style={{ fontWeight: 600 }}>
                      {formatCurrency(project.total_per_m2, {
                        showSign: false,
                      })}
                    </td>
                    <td data-header={HEADERS[5]}>
                      {formatCurrency(project.construction_m2, {
                        showSign: false,
                      })}
                    </td>
                    <td data-header={HEADERS[6]}>{project.fee_percent}%</td>
                  </tr>
                ))}
              </Table>
            </div>
          </Card>
          <Card style={{ flexBasis: '100%', paddingBottom: '3rem' }}>
            <TableHeading large>
              2. changes vs remaining risk / budget
            </TableHeading>
            <ChartLegend>
              <LegendItem color={PIE_COLORS[0]}>Changes</LegendItem>
              <LegendItem color={PIE_COLORS[1]}>
                Remaining Risk Allowance
              </LegendItem>
              <LegendItem color={PIE_COLORS[2]}>Remaining Budget</LegendItem>
            </ChartLegend>
            <ChartLegend>
              <LegendItem color={DONUT_COLORS[0]}>
                Days Since Start of Project
              </LegendItem>
              <LegendItem color={DONUT_COLORS[1]}>Days Remaining</LegendItem>
            </ChartLegend>
            <PiesContainer>
              {data?.changes_risk_remaining.map(project => (
                <RiskPieChart key={project.id} project={project} />
              ))}
            </PiesContainer>
          </Card>
        </CardWrapper>
      </Loader>
    </View>
  )
}

export default Dashboard
