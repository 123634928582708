import styled from 'styled-components'

export const View = styled.div`
  margin: 1rem;
  ${p => p.theme.layout.web} {
    margin: 2rem;
  }
`

export const ViewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  margin-bottom: 1rem;

  h1 {
    color: #000000;
    letter-spacing: 0;
    text-transform: uppercase;
    padding: 0;
    margin: 0 1rem 0 0;
    font-size: 20px;
  }

  ${p => p.theme.layout.web} {
    margin-bottom: 1.5rem;
    h1 {
      font-size: 26px;
    }
  }
`

export const ChangeCheck = styled.span`
  white-space: nowrap;
  font-weight: inherit;
  color: ${p =>
    (p.number > 0 && !p.swap) || (p.number < 0 && p.swap)
      ? p.theme.color.error
      : (p.number < 0 && !p.swap) || (p.number > 0 && p.swap)
      ? p.theme.color.success
      : p.theme.color.text};
`
