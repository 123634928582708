import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

import { InputWithLabel } from '.'

const formatCurrency = str => {
  const value = parseFloat(str.replace(/,/g, ''))
  const formattedValue = value.toLocaleString('en-UK', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return formattedValue && !Number.isNaN(value) ? formattedValue : ''
}
export const FormikInput = props => {
  const [field, meta, form] = useField(props)

  return (
    <InputWithLabel
      {...field}
      {...props}
      onBlur={() => {
        if (props.currency) form.setValue(formatCurrency(field.value))
        form.setTouched(true)
      }}
      errorText={meta.touched ? meta.error : ''}
    />
  )
}

FormikInput.defaultProps = {
  type: 'text',
  currency: false,
}

FormikInput.propTypes = {
  currency: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'date', 'number', 'password', 'email', 'tel']),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}
