import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

export const LoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  padding: 50px;
  background: #f8f9f9;
  svg {
    width: 100%;
    stroke-dasharray: 40;
    stroke-dashoffset: 0;
    animation: dash 1s ease-in-out infinite;
    @keyframes dash {
      to {
        stroke-dashoffset: 160;
      }
    }
  }
`

export const LoadingIndicator = () => (
  <svg viewBox="0 0 50 50">
    <rect
      x="5"
      y="5"
      width="40"
      height="40"
      fill="none"
      strokeWidth="2"
      stroke="black"
    />
  </svg>
)

export const LoadTransition = ({ children, isFetching }) => (
  <SwitchTransition mode={'out-in'}>
    <CSSTransition
      key={isFetching}
      in={isFetching}
      timeout={100}
      classNames="fade"
      unmountOnExit
    >
      {isFetching ? (
        <LoadingOverlay>
          <LoadingWrapper>
            <LoadingIndicator />
          </LoadingWrapper>
        </LoadingOverlay>
      ) : (
        <>{children}</>
      )}
    </CSSTransition>
  </SwitchTransition>
)

LoadTransition.defaultProps = {
  children: null,
  isFetching: false,
}
LoadTransition.propTypes = {
  isFetching: PropTypes.bool,
  children: PropTypes.node,
}
