import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import axios from 'axios'
import AsyncSelect from 'react-select/async'
import debounce from 'debounce-promise'

import { ReactComponent as searchIcon } from '../../../assets/icons/search.svg'
import { SSelect } from '../select/Select'

const Container = styled.div`
  position: relative;
`

const SearchIcon = styled(searchIcon)`
  position: absolute;
  height: calc(100% - 1.5rem);
  left: 0.5rem;
  bottom: 0.5rem;
`

const Select = styled(SSelect)`
  .react-select {
    &__value-container {
      margin-left: 1.5rem;
      margin-top: 4px;
    }
    &__input {
    }
    &__placeholder {
      margin-top: 4px;
      visibility: visible;
    }
  }
`

export const Search = ({ selectedResult, placeholder }) => {
  const handleResult = opt => selectedResult(opt.value)

  const loadOptions = async userInput => {
    const res = await axios.get(`user/search/${userInput}`)
    return res.data.data.map(user => ({ label: user.name, value: user }))
  }
  const debouncedLoadOptions = debounce(loadOptions, 300)

  return (
    <Container>
      <Select
        as={AsyncSelect}
        name="search"
        placeholder={placeholder}
        controlShouldRenderValue={false}
        onChange={handleResult}
        options={[]}
        loadOptions={debouncedLoadOptions}
        classNamePrefix="react-select"
      />
      <SearchIcon />
    </Container>
  )
}

Search.propTypes = {
  placeholder: PropTypes.string.isRequired,
  selectedResult: PropTypes.func.isRequired,
}
