import React from 'react'
import { Routes, Route } from 'react-router-dom'
import styled from 'styled-components'

import { AuthContext } from './context/auth'
import BreadcrumbProvider from './context/breadcrumb'
import Header from './components/header'
import Nav from './components/nav'
import Dashboard from './components/dashboard'
import ProjectSettings from './components/projectSettings'
import ProjectList from './components/projects'
import Project from './components/project'
// import Faqs from './components/faqs'
import Admin from './components/admin'
import Account from './components/account'

//#region styles
const Layout = styled.div`
  min-height: 100vh;
  width: 100%;
`

const StyledHeader = styled(Header)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${p => p.theme.layout.header_height};
  z-index: 100;
  border-bottom: 1px solid #f0f0f0;
  ${p => p.theme.layout.web} {
    left: ${p => p.theme.layout.nav_width_web};
  }
`

const StyledNav = styled(Nav)`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: ${p => p.theme.layout.nav_height_mobile};
  border-top: 1px solid #f0f0f0;
  ${p => p.theme.layout.web} {
    top: 0;
    width: ${p => p.theme.layout.nav_width_web};
    height: 100%;
    border-bottom: none;
    border-right: 1px solid #f0f0f0;
  }
  z-index: 200;
`

const StyledMain = styled.main`
  --header-height: ${p => p.theme.layout.header_height};
  --nav-width-web: ${p => p.theme.layout.nav_width_web};
  --nav-height-mob: ${p => p.theme.layout.nav_height_mobile};

  background: #f8f9f9;
  padding-top: var(--header-height);
  padding-left: 0;
  padding-bottom: var(--nav-height-mob);
  min-width: 100vw;
  min-height: 100vh;
  width: fit-content;

  ${p => p.theme.layout.web} {
    padding-left: var(--nav-width-web);
    padding-bottom: 2rem;
  }
`
//#endregion

const ProtectedApp = () => {
  const { user } = React.useContext(AuthContext)

  return (
    <Layout>
      <BreadcrumbProvider>
        <StyledHeader />
        <StyledNav />
        <StyledMain>
          <Routes>
            {[
              'admin',
              'project_manager',
              'cost_manager',
              'project_member',
            ].includes(user?.user_group) ? (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="dashboard/new" element={<ProjectSettings />} />
                <Route path="/projects" element={<ProjectList />} />
                <Route path="/projects/:id/*" element={<Project />} />
                {/* <Route path="faqs/*" element={<Faqs />} /> */}
                <Route path="account" element={<Account />} />
                {user.isAdmin ? (
                  <Route path="admin/*" element={<Admin />} />
                ) : null}
              </>
            ) : null}
            {user?.user_group === 'client' ? (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/projects" element={<ProjectList />} />
                <Route path="/projects/:id/*" element={<Project />} />
                {/* <Route path="faqs/*" element={<Faqs />} /> */}
                <Route path="account" element={<Account />} />
              </>
            ) : null}
          </Routes>
        </StyledMain>
      </BreadcrumbProvider>
    </Layout>
  )
}

export default ProtectedApp
