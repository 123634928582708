export const USER_TYPE_OPTIONS = [
  {
    value: 'contract_manager',
    label: 'Contract Manager',
  },
  {
    value: 'cost_manager',
    label: 'Cost Manager',
  },
  {
    value: 'designer',
    label: 'Designer',
  },
  {
    value: 'client',
    label: 'Client',
  },
]

export const COST_CONSTITUENT_OPTIONS = [
  { value: 'construction', label: 'Construction' },
  { value: 'fees', label: 'Fees & Surveys' },
  { value: 'other', label: 'Other Project Costs' },
]

export const CONFIRM_OPTIONS = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'TBC', label: 'TBC' },
]

export const COST_CATEGORIES = {
  facilitating_works: 'Facilitating works',
  substructure: 'Substructure',
  frame: 'Frame',
  upper_floors: 'Upper floors',
  roof: 'Roof',
  stairs_and_ramps: 'Stairs and ramps',
  external_walls: 'External walls',
  windows_and_external_doors: 'Windows and external doors',
  internal_walls_and_partitions: 'Internal walls and partitions',
  internal_doors: 'Internal doors',
  wall_finishes: 'Wall finishes',
  floor_finishes: 'Floor finishes',
  ceiling_finishes: 'Ceiling finishes',
  fittings_furnishings_and_equipment: 'Fittings, furnishings and equipment',
  services_public_health: 'Services - Public Health',
  services_mechanical: 'Services - Mechanical',
  services_electrical: 'Services - Electrical',
  services_other: 'Services - Other',
  external_works: 'External works',
  main_contractor_preliminaries: 'Main contractor preliminaries',
  main_contractor_ohp: 'Main contractor oh&p',
  construction_contract_contingency: 'Construction contract contingency',
  construction_other: 'Construction – other',
  project_fees: 'Project fees',
  other_project_costs: 'Other project costs',
  none: 'None',
}

export const COST_CAT_OPTIONS = [
  {
    value: COST_CATEGORIES.facilitating_works,
    label: COST_CATEGORIES.facilitating_works,
  },
  { value: COST_CATEGORIES.substructure, label: COST_CATEGORIES.substructure },
  { value: COST_CATEGORIES.frame, label: COST_CATEGORIES.frame },
  { value: COST_CATEGORIES.upper_floors, label: COST_CATEGORIES.upper_floors },
  { value: COST_CATEGORIES.roof, label: COST_CATEGORIES.roof },
  {
    value: COST_CATEGORIES.stairs_and_ramps,
    label: COST_CATEGORIES.stairs_and_ramps,
  },
  {
    value: COST_CATEGORIES.external_walls,
    label: COST_CATEGORIES.external_walls,
  },
  {
    value: COST_CATEGORIES.windows_and_external_doors,
    label: COST_CATEGORIES.windows_and_external_doors,
  },
  {
    value: COST_CATEGORIES.internal_walls_and_partitions,
    label: COST_CATEGORIES.internal_walls_and_partitions,
  },
  {
    value: COST_CATEGORIES.internal_doors,
    label: COST_CATEGORIES.internal_doors,
  },
  {
    value: COST_CATEGORIES.wall_finishes,
    label: COST_CATEGORIES.wall_finishes,
  },
  {
    value: COST_CATEGORIES.floor_finishes,
    label: COST_CATEGORIES.floor_finishes,
  },
  {
    value: COST_CATEGORIES.ceiling_finishes,
    label: COST_CATEGORIES.ceiling_finishes,
  },
  {
    value: COST_CATEGORIES.fittings_furnishings_and_equipment,
    label: COST_CATEGORIES.fittings_furnishings_and_equipment,
  },
  {
    value: COST_CATEGORIES.services_public_health,
    label: COST_CATEGORIES.services_public_health,
  },
  {
    value: COST_CATEGORIES.services_mechanical,
    label: COST_CATEGORIES.services_mechanical,
  },
  {
    value: COST_CATEGORIES.services_electrical,
    label: COST_CATEGORIES.services_electrical,
  },
  {
    value: COST_CATEGORIES.services_other,
    label: COST_CATEGORIES.services_other,
  },
  {
    value: COST_CATEGORIES.external_works,
    label: COST_CATEGORIES.external_works,
  },
  {
    value: COST_CATEGORIES.main_contractor_preliminaries,
    label: COST_CATEGORIES.main_contractor_preliminaries,
  },
  {
    value: COST_CATEGORIES.main_contractor_ohp,
    label: COST_CATEGORIES.main_contractor_ohp,
  },
  {
    value: COST_CATEGORIES.construction_contract_contingency,
    label: COST_CATEGORIES.construction_contract_contingency,
  },
  {
    value: COST_CATEGORIES.construction_other,
    label: COST_CATEGORIES.construction_other,
  },
  { value: COST_CATEGORIES.project_fees, label: COST_CATEGORIES.project_fees },
  {
    value: COST_CATEGORIES.other_project_costs,
    label: COST_CATEGORIES.other_project_costs,
  },
  { value: COST_CATEGORIES.none, label: COST_CATEGORIES.none },
]

export const TIME_OPTIONS = () => {
  const days = new Array(100).fill(null).map((day, index) => {
    return {
      value: `${index + 1 === 1 ? index + 1 + ' day' : index + 1 + ' days'}`,
      label: `${index + 1 === 1 ? index + 1 + ' day' : index + 1 + ' days'}`,
    }
  })
  const months = new Array(24).fill(null).map((month, index) => {
    return {
      value: `${
        index + 1 === 1 ? index + 1 + ' month' : index + 1 + ' months'
      }`,
      label: `${
        index + 1 === 1 ? index + 1 + ' month' : index + 1 + ' months'
      }`,
    }
  })
  return [...days, ...months]
}

export const CHANGE_ORIGIN_OPTIONS = [
  {
    label: 'Employer Change',
    value: 'EMPLOYER CHANGE',
  },
  {
    label: 'Employer Other',
    value: 'EMPLOYER OTHER',
  },
  {
    label: 'Architect Design Change',
    value: 'ARCHITECT DESIGN CHANGE',
  },
  {
    label: 'Structural Engineer Design Change',
    value: 'STRUCTURAL ENGINEER DESIGN CHANGE',
  },
  {
    label: 'MEP Services Consultant Design Change',
    value: 'MEP SERVICES CONSULTANT DESIGN CHANGE',
  },
  {
    label: 'Unforeseen Existing Building Change',
    value: 'UNFORESEEN EXISTING BUILDING CHANGE',
  },
  {
    label: 'Provisional Sum Adjustment',
    value: 'PROVISIONAL SUM ADJUSTMENT',
  },
  {
    label: 'Adjustment For CDP Approval',
    value: 'ADJUSTMENTS FOR CDP APPROVAL',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
]
