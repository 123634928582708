import React from 'react'
import { useLocation } from 'react-router-dom'

export const BreadcrumbContext = React.createContext()

const BreadcrumbProvider = props => {
  const [path, updatePath] = React.useState()
  const [project, setProject] = React.useState()
  const [crNo, setCrNo] = React.useState('')
  const loc = useLocation()

  const { pathname } = loc

  React.useEffect(() => {
    setPath()

    if (!pathname.includes(`/projects/`)) {
      // clear existing project name
      setProject()
    }

    if (!pathname.includes(`/changes/`)) {
      // clear existing cr number
      setCrNo('')
    }

    if (pathname.includes(`/dashboard/new`)) {
      // new project breadcrumb
      setPath([
        {
          to: '/',
          text: 'Dashboard',
        },
        {
          to: 'dashboard/new',
          text: 'create new project',
        },
      ])
    }

    // Breadcrumb for when viewing a project
    if (pathname.includes(`/projects/`) && project) {
      const [, , projectId] = pathname.split('/')

      // default breadcrumb
      const projectPaths = [
        {
          to: `projects`,
          text: 'your projects',
        },
        {
          to: `projects/${projectId}/overview`,
          text: project,
        },
      ]

      if (pathname.includes(`/changes/`)) {
        if (pathname.includes(`/new`)) {
          // just show default
          setPath(projectPaths)
        } else {
          // show CR breadcrumb
          const [, , , , corId] = pathname.split('/')

          setPath([
            ...projectPaths,
            {
              to: `projects/${projectId}/changes/${corId}`,
              text: `change request #${crNo}`,
            },
          ])
        }
      } else {
        // show default breadcrumb
        setPath(projectPaths)
      }
    }
  }, [pathname, project, crNo])

  const setPath = newPath => {
    if (!newPath) updatePath([])
    else updatePath(newPath)
  }

  const setCR = React.useCallback(cr_no => {
    setCrNo(cr_no)
  }, [])

  return (
    <BreadcrumbContext.Provider
      value={{
        path,
        setPath,
        setProject,
        setCR,
        loc,
      }}
      {...props}
    />
  )
}

export default BreadcrumbProvider
