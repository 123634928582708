import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { useAuth } from './hooks'
import Login from './components/login'
import Signup from './components//login/signup'
import UpdatePassword from './components/login/update'
import ForgotPassword from './components/login/forgot'
import ProtectedApp from './protected-app'

function App() {
  const { isAuthenticated } = useAuth()

  return (
    <div className="App">
      <Routes>
        {isAuthenticated ? (
          <Route path="/*" element={<ProtectedApp />} />
        ) : (
          <>
            <Route path="/*" element={<Login />} />
            <Route path="/signup/:hash" element={<Signup />} />
            <Route path="/reset-password/:hash" element={<UpdatePassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </>
        )}
      </Routes>
    </div>
  )
}

export default App
