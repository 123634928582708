import React from 'react'

import { BreadcrumbContext } from '../context/breadcrumb'

const useBreadcrumb = () => {
  const breadcrumb = React.useContext(BreadcrumbContext)

  return breadcrumb
}

export default useBreadcrumb
