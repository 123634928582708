import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useQuery, useQueryCache, useMutation } from 'react-query'
import { useToasts } from 'react-toast-notifications'

import { CardWrapper, Table, Button, ChangeCheck } from '../../ui'
import { formatCurrency } from '../../../utilities'
import DeleteProjectModal from './deleteProjectModal'
import { ButtonRow } from '../ui'

const getCurrency = currencyString =>
  currencyString === 'gbp' ? '£' : currencyString === 'eur' ? '€' : ''

const getAdminProjectData = () => axios.get('/dashboard/admin?type=projects')
const APIdeleteProject = ({ project, hard = false }) => {
  const query = hard ? '?hard_delete=1' : ''
  return axios.post(`/project/${project.id}/archive${query}`)
}
const APIactivateProject = project =>
  axios.post(`/project/${project.id}/restore`)

const HEADERS = [
  'Project Name',
  'Total Cost',
  'Changes',
  '%',
  'Remaining Risk',
  '%',
  '',
]

const AdminProjects = () => {
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const [selectedProject, setSelectedProject] = useState(null)
  const [openModal, setOpenModal] = useState(null)

  const queryCache = useQueryCache()
  const queryKey = 'admin-projects'
  const { data } = useQuery(queryKey, getAdminProjectData)

  const [deleteProject] = useMutation(APIdeleteProject, {
    onSuccess: () => {
      queryCache.invalidateQueries(queryKey)
    },
    onError: res => {
      const error = typeof res.error === 'string' ? res.error : 'Delete error'
      addToast(error, { appearance: 'error' })
    },
  })

  const [activateProject] = useMutation(APIactivateProject, {
    onSuccess: () => {
      addToast('Project activated')
      queryCache.invalidateQueries(queryKey)
    },
    onError: res => {
      const error = typeof res.error === 'string' ? res.error : 'Archive error'
      addToast(error, { appearance: 'error' })
    },
  })

  const closeModal = () => {
    setOpenModal(null)
    setSelectedProject(null)
  }

  const active = (data?.data?.active_projects || [])
    .map(project => ({
      id: project.id,
      currency: getCurrency(project.currency),
      project_name: project.project_name,
      total_cost: project.total_cost,
      changes: project.changes,
      changes_percentage: project.changes_percentage,
      remaining_risk: project.remaining_risk,
      remaining_risk_percentage: project.remaining_risk_percentage,
    }))
    .sort((a, b) => a.project_name.localeCompare(b.project_name))

  const archived = (data?.data?.archived_projects || [])
    .map(project => ({
      id: project.id,
      currency: getCurrency(project.currency),
      project_name: project.project_name,
      total_cost: project.total_cost,
      changes: project.changes,
      changes_percentage: project.changes_percentage,
      remaining_risk: project.remaining_risk,
      remaining_risk_percentage: project.remaining_risk_percentage,
    }))
    .sort((a, b) => a.project_name.localeCompare(b.project_name))

  return (
    <CardWrapper>
      <DeleteProjectModal
        isActive={openModal === 'delete-project'}
        project={selectedProject}
        onSubmit={async () => {
          const res = await deleteProject({
            project: selectedProject,
            hard: true,
          })
          if (res) closeModal()
        }}
        onClose={closeModal}
      />
      <Table
        heading={active ? `Active Projects: ${active.length}` : ''}
        headers={HEADERS}
        inCard
      >
        {active.map(project => (
          <tr key={project.id}>
            <td data-header={HEADERS[0]}>{project.project_name}</td>
            <td data-header={HEADERS[1]}>
              {formatCurrency(project.total_cost, {
                showSign: false,
                symbol: project.currency,
              })}
            </td>
            <td data-header={HEADERS[2]}>
              <ChangeCheck number={project.changes}>
                {formatCurrency(project.changes)}
              </ChangeCheck>
            </td>
            <td data-header={HEADERS[3]}>
              <ChangeCheck number={project.changes_percentage}>
                {project.changes_percentage}%
              </ChangeCheck>
            </td>
            <td data-header={HEADERS[4]}>
              <ChangeCheck number={project.remaining_risk}>
                {formatCurrency(project.remaining_risk)}
              </ChangeCheck>
            </td>
            <td data-header={HEADERS[5]}>
              <ChangeCheck number={project.remaining_risk_percentage}>
                {project.remaining_risk_percentage}%
              </ChangeCheck>
            </td>
            <td data-header={HEADERS[6]}>
              <ButtonRow>
                <Button
                  small
                  onClick={() => navigate(`/projects/${project.id}/overview`)}
                >
                  View
                </Button>
                <Button small onClick={() => deleteProject({ project })}>
                  Archive
                </Button>
              </ButtonRow>
            </td>
          </tr>
        ))}
      </Table>

      <Table
        heading={archived ? `Archived Projects: ${archived.length}` : ''}
        headers={HEADERS}
        inCard
      >
        {archived.map(project => (
          <tr key={project.id}>
            <td data-header={HEADERS[0]}>{project.project_name}</td>
            <td data-header={HEADERS[1]}>
              {project.currency}
              {formatCurrency(project.total_cost, { showSign: false })}
            </td>
            <td data-header={HEADERS[2]}>
              <ChangeCheck number={project.changes}>
                {formatCurrency(project.changes)}
              </ChangeCheck>
            </td>
            <td data-header={HEADERS[3]}>
              <ChangeCheck number={project.changes_percentage}>
                {project.changes_percentage}%
              </ChangeCheck>
            </td>
            <td data-header={HEADERS[4]}>
              <ChangeCheck number={project.remaining_risk}>
                {formatCurrency(project.remaining_risk)}
              </ChangeCheck>
            </td>
            <td data-header={HEADERS[5]}>
              <ChangeCheck number={project.remaining_risk_percentage}>
                {project.remaining_risk_percentage}%
              </ChangeCheck>
            </td>
            <td data-header={HEADERS[6]} style={{ textAlign: 'right' }}>
              <ButtonRow>
                <Button small onClick={() => activateProject(project)}>
                  Activate
                </Button>
                <Button
                  small
                  onClick={() => {
                    setSelectedProject(project)
                    setOpenModal('delete-project')
                  }}
                  style={{ background: 'darkred' }}
                >
                  Delete
                </Button>
              </ButtonRow>
            </td>
          </tr>
        ))}
      </Table>
    </CardWrapper>
  )
}

export default AdminProjects
