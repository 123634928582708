import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg'
import { Button } from '../../../../ui'
import { Input, EditIcon, Cell } from './ui'
import { formatCurrency } from '../../../../../utilities'

const ClaimedCostCell = ({ cost, update, isSubmitting, canEdit }) => {
  const inputRef = React.useRef()
  const [edit, setEdit] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  React.useEffect(() => {
    if (cost) setInputValue(cost)
  }, [cost])

  React.useEffect(() => {
    if (edit && inputRef.current) inputRef.current.focus()
  }, [edit])

  const startEdit = () => setEdit(true)

  const cancelEdit = () => {
    setEdit(false)
    setInputValue(cost || '')
  }

  const saveClaimedCost = async () => {
    const success = await update({ gc_claimed_cost: inputValue })
    if (success) setEdit(false)
  }

  return edit ? (
    <Cell>
      <Input
        ref={inputRef}
        type="number"
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
      />
      <Button small onClick={saveClaimedCost} disabled={isSubmitting}>
        Save
      </Button>
      <Button small icon onClick={cancelEdit}>
        <CloseIcon style={{ width: '24px' }} disabled={isSubmitting} />
      </Button>
    </Cell>
  ) : cost ? (
    <Cell>
      {formatCurrency(cost)}
      {canEdit && (
        <Button small icon onClick={startEdit} disabled={isSubmitting}>
          <EditIcon />
        </Button>
      )}
    </Cell>
  ) : canEdit ? (
    <Button small onClick={startEdit} disabled={isSubmitting}>
      Add Claimed Cost
    </Button>
  ) : null
}

ClaimedCostCell.defaultProps = {
  cost: '',
  canEdit: false,
}

ClaimedCostCell.propTypes = {
  cost: PropTypes.string,
  update: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool,
}

export default ClaimedCostCell
