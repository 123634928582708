import axios from 'axios'

import { formatError, createFormData } from './utils'

export const saveCR = async (rawData, id) => {
  const { cost_breakdown, files, ...data } = rawData
  const formData = createFormData(data)

  if (cost_breakdown)
    cost_breakdown.forEach((cost, i) =>
      Object.entries(cost).forEach(([k, v]) =>
        formData.append(`cost_breakdown[${i}][${k}]`, v),
      ),
    )

  files.forEach((file, i) => formData.append(`files[${i}]`, file))

  try {
    const res = await axios({
      method: 'post',
      url: id ? `change-request/${id}` : 'change-request',
      data: formData,
    })
    if (res.status === 200 || res.status === 201)
      return { error: false, data: res.data }
    else return { error: true, errors: res.data }
  } catch (e) {
    if (axios.isCancel(e)) return { cancelled: true }
    else return { errors: e?.response?.data?.errors }
  }
}

export const clientCR = async (data, id, type) => {
  const url = `/change-request/${type}/${id}`
  const formData = createFormData(data)

  try {
    const res = await axios({
      method: 'post',
      url: url,
      data: formData,
    })
    if (res.status === 200 || res.status === 201)
      return { error: false, data: res.data }
    else throw Error
  } catch (e) {
    if (axios.isCancel(e)) return { cancelled: true }
    else return formatError(e, 'Change request could not be saved')
  }
}

export const crNoteCreate = async (crId, title, description) => {
  try {
    const res = await axios({
      method: 'post',
      url: '/cr-note',
      data: {
        change_request_id: crId,
        title,
        description,
      },
    })
    if (res.status === 200 || res.status === 201)
      return { error: false, data: res.data }
    else throw Error
  } catch (e) {
    return formatError(e, 'Note could not be saved')
  }
}

export const updateCosts = async (id, costs) => {
  const formData = new FormData()

  formData.append('reported_cost_values', 'assessed_cost')
  costs.forEach((cost, i) =>
    Object.entries(cost).forEach(([k, v]) =>
      formData.append(`assessed_costs[${i}][${k}]`, v),
    ),
  )

  try {
    const res = await axios({
      method: 'post',
      url: `change-request/${id}/published`,
      data: formData,
    })
    if (res.status === 200 || res.status === 201)
      return { error: false, data: res.data }
    else throw Error
  } catch (e) {
    return formatError(e, 'Costs could not be updated')
  }
}

export const updatePublishedCR = async (id, data) => {
  const formData = createFormData(data)

  try {
    const res = await axios({
      method: 'post',
      url: `change-request/${id}/published`,
      data: formData,
    })
    if (res.status === 200 || res.status === 201)
      return { error: false, data: res.data }
    else throw Error
  } catch (e) {
    return formatError(e, 'Could not update change request')
  }
}

export const deleteCR = async id => {
  try {
    const res = await axios({
      method: 'post',
      url: `/change-request/${id}/archive`,
    })
    if (res.status === 200 || res.status === 201)
      return { error: false, data: res.data }
    else throw Error
  } catch (e) {
    return formatError(e, 'Could not delete change request')
  }
}

export const uploadFile = async (id, file) => {
  const formData = new FormData()
  formData.append('file', file)

  try {
    const res = await axios({
      method: 'post',
      url: `file/${id}`,
      data: formData,
    })
    if (res.status === 200 || res.status === 201)
      return { error: false, data: res.data }
    else throw Error
  } catch (e) {
    if (axios.isCancel(e)) return { cancelled: true }
    else return formatError(e, `${file.name} could not be saved`)
  }
}

export const resendCrToClient = async id => {
  const url = `/change-request/${id}/re-send`
  try {
    const res = await axios.post(url)
    if (res.status === 200 || res.status === 201)
      return { error: false, data: res.data }
    else throw Error
  } catch (e) {
    if (axios.isCancel(e)) return { cancelled: true }
    else return formatError(e, 'Unable to resend emails')
  }
}
