import React from 'react'
import PropTypes from 'prop-types'
import {
  Chart,
  LinearScale,
  CategoryScale,
  BarElement,
  LineElement,
  PointElement,
  Legend,
  Tooltip,
} from 'chart.js'
import { Chart as ReactChart } from 'react-chartjs-2'
import styled from 'styled-components'

Chart.register(
  LinearScale,
  CategoryScale,
  BarElement,
  LineElement,
  PointElement,
  Legend,
  Tooltip,
)

const ChartWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(100vw - 4rem);
  margin-bottom: 2rem;
  min-height: 300px;

  ${p => p.theme.layout.web} {
    max-width: calc(100% - 1px);
    min-height: 475px;
  }
`

const ChangesByMonth = props => {
  const labels = [
    ...props.data.map(d => d.label),
    ...props.forecast.map(d => d.month),
  ]

  const smoothedRisk = props.data.map(d => d.risk).reduce(smoothRisk, [])
  const forecast = props.forecast.map(d => d.tracker)
  const data = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Tracker/Forecast',
        borderColor: 'rgb(255, 0,0)',
        borderWidth: 2,
        pointRadius: 1,
        cubicInterpolationMode: 'monotone',
        data: [...smoothedRisk, ...forecast],
        segment: {
          borderDash: ctx =>
            ctx.p0DataIndex >= smoothedRisk.length - 1 ? [6, 6] : undefined,
        },
      },
      {
        type: 'bar',
        label: 'Changes',
        backgroundColor: 'rgb(68, 114, 196)',
        data: props.data.map(d => d.changes),
      },
      {
        type: 'bar',
        label: 'Remaining Risk Allowance',
        backgroundColor: 'rgb(112, 173, 71)',
        data: props.data.map(d => d.risk),
      },
    ],
  }

  return (
    <>
      <ChartWrapper>
        <ReactChart
          type="bar"
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            animations: false,
            animation: {
              onComplete: ({ chart }) => props.onChartIsReady(chart),
            },
            plugins: {
              legend: {
                onClick: () => {},
              },
            },
          }}
        />
      </ChartWrapper>
    </>
  )
}

ChangesByMonth.propTypes = {
  onChartIsReady: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      changes: PropTypes.number.isRequired,
      risk: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  forecast: PropTypes.arrayOf(
    PropTypes.shape({ month: PropTypes.string.isRequired }),
  ).isRequired,
}

export default ChangesByMonth

// predict calcs
function smoothRisk(acc, risk, i, arr) {
  if (i === 0) {
    return [...acc, risk]
  } else if (i === arr.length - 1) {
    const prevRisk = arr[i - 1]
    const average = (risk + prevRisk) / 2
    return [...acc, average]
  }
  const prevRisk = arr?.[i - 1] || risk
  const nextRisk = arr?.[i + 1] || risk
  const average = (risk + prevRisk + nextRisk) / 3
  return [...acc, average]
}
