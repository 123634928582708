import { createGlobalStyle } from 'styled-components'

import AkkRgWoff from '../assets/fonts/AkkRg_Pro_1.woff'
import AkkRgOtf from '../assets/fonts/AkkRg_Pro_1.otf'

import AkkBdWoff from '../assets/fonts/AkkBd_Pro_1.woff'
import AkkBdOtf from '../assets/fonts/AkkBd_Pro_1.otf'

import AkkItWoff from '../assets/fonts/AkkIt_Pro_1.woff'
import AkkItOtf from '../assets/fonts/AkkIt_Pro_1.otf'

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "Akkurat";
  src: url(${AkkRgWoff}) format("woff"),
    url(${AkkRgOtf}) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Akkurat";
  src: url(${AkkBdWoff}) format("woff"),
    url(${AkkBdOtf}) format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Akkurat";
  src: url(${AkkItWoff}) format("woff"),
    url(${AkkItOtf}) format("truetype");
  font-weight: normal;
  font-style: italic;
}`

const primary = `black`
const accent = '#FF073A'
const success = '#20CA14'
const error = accent
const focus = 'lightblue'
const text = '#575756'

export default {
  color: {
    primary,
    accent,
    error,
    success,
    focus,
    text,
  },
  shadow: '1px 1px 4px 0 rgba(219, 219, 219, 0.85)',
  layout: {
    web: `@media (min-width: 900px)`,
    tablet: `@media (min-width: 700px)`,
    header_height: '70px',
    nav_width_web: '240px',
    nav_height_mobile: '64px',
    container_padding: '2rem 2rem 1rem 2rem',
  },
}
