import React from 'react'
import PropTypes from 'prop-types'

import { LoadTransition } from '../../ui'
import useFetch from '../../../hooks/useFetch'
import RiskAllowance from '../../analytics/riskAllowance'

export const RiskAllowanceLoader = ({ id, save }) => {
  const riskAllowance = useFetch(
    `/project/${id}/riskAllocations`,
    'Unable to fetch changes by risk allocations data',
  )

  return (
    <LoadTransition isFetching={riskAllowance.isFetching}>
      {riskAllowance.data && riskAllowance.data.length ? (
        <RiskAllowance
          onChartIsReady={save('riskAllowance')}
          data={riskAllowance.data.map(d => ({
            risk: d.total,
            change: d.used,
            label: d.label,
          }))}
        />
      ) : (
        <div>No data to display</div>
      )}
    </LoadTransition>
  )
}
RiskAllowanceLoader.propTypes = {
  id: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
}
